import React, { useState } from "react";
import classes from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useLocation, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Card, Alert, FormControl, Form } from "react-bootstrap";
import { Request } from "../../store/ui";
import { Action as AuthAction } from "../../store/auth";
import { toast } from "react-toastify";

const ResetPassword = ({ initial }) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const token = new URLSearchParams(location.search).get("token");

  const [data, setData] = useState({ password: "", confirmPassword: "" });
  const [validated, setValidated] = useState(false);

  const loading = useSelector((state) => state.ui.loading[Request.AUTH_RESET_PASSWORD]);
  const error = useSelector((state) => state.ui.error[Request.AUTH_RESET_PASSWORD]);

  const changeHandler = ({ target }) => {
    const { name, value } = target;
    setData({ ...data, [name]: value });
  };

  const submit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      const success = await dispatch(AuthAction.resetPassword({ ...data, token }));
      if (success) {
        history.push("/login");
        toast.success(`Password ${initial ? "set" : "updated"}. Please login with new password!`);
      }
    }
  };

  if (!token) {
    return <Alert variant="danger">Missing token</Alert>;
  }

  return (
    <div className={classes.ResetPassword}>
      <div className={classes.FormContainer}>
        <Card>
          <Card.Header>
            <FontAwesomeIcon icon="key" />
            <span className="ml-3">{initial ? "Set initial password" : "Reset password"}</span>
          </Card.Header>
          <Card.Body>
            <Form noValidate onSubmit={submit} validated={validated}>
              <Form.Group>
                <FormControl type="password" placeholder="New password" name="password" required value={data.password} onChange={changeHandler} />
                <Form.Control.Feedback type="invalid">Please enter password</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <FormControl
                  required
                  type="password"
                  placeholder="Confirm new password"
                  name="confirmPassword"
                  value={data.confirmPassword}
                  onChange={changeHandler}
                />
                <Form.Control.Feedback type="invalid">Please confirm password</Form.Control.Feedback>
              </Form.Group>
              <button type="submit" className="btn btn-outline-primary btn-block" disabled={loading}>
                {initial ? "Set password" : "Reset password"}
              </button>
              {error && <Alert variant="danger" className="mt-3">{error}</Alert>}
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
