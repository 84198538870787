import React, { useState } from "react";
import { Col, Row, Form, Modal, Button as RbButton } from "react-bootstrap";
import { Action as MqnSearchAction } from "../../store/mqnSearch";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import { toast } from "react-toastify";

const ExportMqnSmilesModal = ({ variant, size, className, label }) => {
  const [open, setOpen] = useState(false);
  const [headers, setHeaders] = useState(false);
  const [mqnDistance, setMqnDistance] = useState(false);
  const [mhfp6Distance, setMhfp6Distance] = useState(false);
  const dispatch = useDispatch();

  const items = useSelector((state) => state.mqnSearch.items);

  const openModal = () => {
    if(!items || !items.length) {
      toast.warning("No smiles to export");
      return;
    }
    setOpen(true);
  };

  const closeModal = () => setOpen(false);
 
  const toggleHeaders = () => setHeaders(!headers);
  const toggleMqnDistance = () => setMqnDistance(!mqnDistance);
  const toggleMhfp6Distance = () => setMhfp6Distance(!mhfp6Distance);

  const exportSmiles = async () => {
    await dispatch(MqnSearchAction.exportToFile(headers, mqnDistance, mhfp6Distance));
    closeModal();
  }

  return (
    <>
      <Button variant={variant} size={size} onClick={openModal} className={className}>
        {label}
      </Button>

        <Modal show={open} onHide={() => setOpen(false)} size="md">
          <Modal.Header closeButton>
            <Modal.Title>Export smiles</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <legend className="mt-1">Headers</legend>
            <Form.Group>
              <Row>
                <Col sm={4}>
                  <label className="my-2">
                    <input type="checkbox" checked={headers} onChange={toggleHeaders} />
                    <span className="ml-2">Include headers</span>
                  </label>
                </Col>
              </Row>
            </Form.Group>

            <legend className="mt-1">Distances</legend>
            <Form.Group>
              <Row>
                <Col sm={6}>
                  <label className="my-2">
                    <input type="checkbox" checked={mqnDistance} onChange={toggleMqnDistance} />
                    <span className="ml-2">MQN distance</span>
                  </label>
                </Col>
                <Col sm={6}>
                  <label className="my-2">
                    <input type="checkbox" checked={mhfp6Distance} onChange={toggleMhfp6Distance} />
                    <span className="ml-2">MHFP6 distance</span>
                  </label>
                </Col>
              </Row>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
          <RbButton variant="warning" type="button" onClick={closeModal}>
            Cancel
          </RbButton>
          <RbButton variant="primary" type="button" onClick={exportSmiles}>
            Export
          </RbButton>
        </Modal.Footer>
        </Modal>
    </>
  );
};

export default ExportMqnSmilesModal;
