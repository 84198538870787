import React, { useState } from "react";
import classes from "./index.module.css";
import classnames from "classnames";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FilterSearch from "../FilterSearch";
import TakeSnapshot from "./take-snapshot";
import TMAPInfo from "../TmapInfo";

const Controls = ({ isPublic }) => {
  const [showControls, setShowControls] = useState(true);
  const [filter, setFilter] = useState({ search: false, info: true });

  const toggleControls = () => setShowControls(!showControls);

  const toggleFilter = () => setFilter({ search: !filter.search, info: false });

  const toggleInfo = () => setFilter({ info: !filter.info, search: false });

  return (
    <>
      <div className={classnames(classes.Controls, classes.Right)}>
        {showControls && (
          <div id={classes.MoreControls}>
            <Button variant="link" onClick={toggleFilter}>
              <FontAwesomeIcon icon="filter" />
            </Button>
            <TakeSnapshot />
            <Button variant="link" onClick={toggleInfo}>
              <FontAwesomeIcon icon="info-circle" />
            </Button>
          </div>
        )}
        <Button variant="link" onClick={toggleControls}>
          <FontAwesomeIcon icon="bars" />
        </Button>
      </div>
      {filter.search && <FilterSearch isPublic={isPublic} />}
      {filter.info && <TMAPInfo />}
    </>
  );
};

export default Controls;
