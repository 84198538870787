import { toast } from "react-toastify";
import { Request, Action as UIAction } from "../ui";
import { Action as DatasetAction } from "../datasets";
import { Action as CartAction } from "../cart";
import * as Api from "./api"; 
import pako from "pako";
import { saveBinaryFile } from "../../utils/file";

const responseType = process.env.REACT_APP_TMAP_RESPONSE;

export const Type = {
  SET_TMAPS: "TMAPS_SET_TMAPS",
  ADD_TMAP: "TMAPS_ADD_TMAP",
  UPDATE_TMAP: "TMAPS_UPDATE_TMAP",
  REMOVE_TMAP: "TMAPS_REMOVE_TMAP",
  UPDATE_PROGRESS: "TMAPS_UPDATE_PROGRESS"
};

export const filterTMAPs = () => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.TMAPS_FETCH));
  dispatch(UIAction.clearError(Request.TMAPS_FETCH));

  try {
    const response = await Api.filter();
    dispatch(set(response));
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAPS_FETCH, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAPS_FETCH));
  }
};

export const fetchTMAP = id => async (dispatch, getState) => {
 
  dispatch(UIAction.clearError(Request.TMAP_FETCH));

  const { tmaps } = getState();
  id = parseInt(id);
  if(tmaps.find(tmap => tmap.id === id)) {
    return;
  }
  dispatch(UIAction.setLoading(Request.TMAP_FETCH));

  try {
    const response = await Api.get(id);
    dispatch(add(response));
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_FETCH, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_FETCH));
  }
};

export const fetchTMAPInfo = (id, isPublic) => async (dispatch, getState) => {
 
  dispatch(UIAction.clearError(Request.TMAP_FETCH_INFO));

  try {
    const response = await (isPublic ? Api.infoPublic : Api.get)(id);
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_FETCH_INFO, error.message));
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_FETCH_INFO));
  }
};

export const updateTMAP = tmap => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_SAVE));
    dispatch(UIAction.clearError(Request.TMAP_SAVE));

    let response = await Api.update(tmap);
    dispatch(update(response));
    toast.success("TMAP updated");
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_SAVE, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_SAVE));
  }
}

export const createTMAP = data => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_SAVE));
    dispatch(UIAction.clearError(Request.TMAP_SAVE));

    let response = await Api.create(data);
    dispatch(add(response));
    toast.success("TMAP saved");

    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_SAVE, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_SAVE));
  }
}

export const removeTMAP = tmap => async dispatch => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_REMOVE));
    dispatch(UIAction.clearError(Request.TMAP_REMOVE));
    await Api.remove(tmap.id);
    dispatch(remove(tmap));
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_REMOVE, error.message));
    toast.error(error.message);
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_REMOVE));
  }
}

export const getPermissions = (id) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.TMAP_PERMISSIONS));
  dispatch(UIAction.clearError(Request.TMAP_PERMISSIONS));

  try {
    const response = await Api.permissions(id);
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_PERMISSIONS, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_PERMISSIONS));
  }
};

export const togglePermission = (tmap_id, user_id) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.TMAP_PERMISSION));
  dispatch(UIAction.clearError(Request.TMAP_PERMISSION));

  try {
    await Api.togglePermission(tmap_id, user_id);
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_PERMISSION, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_PERMISSION));
  }
};

export const generateToken = (tmap_id) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.TMAP_TOKEN));
  dispatch(UIAction.clearError(Request.TMAP_TOKEN));

  try {
    const response = await Api.generateToken(tmap_id);
    dispatch(update(response));
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_TOKEN, error.message));
    toast.error(error.message);
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_TOKEN));
  }
};

export const deleteToken = (tmap_id) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.TMAP_TOKEN));
  dispatch(UIAction.clearError(Request.TMAP_TOKEN));

  try {
    const response = await Api.deleteToken(tmap_id);
    dispatch(update(response));
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_TOKEN, error.message));
    toast.error(error.message);
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_TOKEN));
  }
};

export const stopProcessing = id => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_PROCESSING));
    dispatch(UIAction.clearError(Request.TMAP_PROCESSING));

    let response = await Api.stopProcessing(id);
    dispatch(update(response));
    toast.success("Processing cancelled");
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_PROCESSING, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_PROCESSING));
  }
};

export const startProcessing = id => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_PROCESSING));
    dispatch(UIAction.clearError(Request.TMAP_PROCESSING));

    let response = await Api.startProcessing(id);
    dispatch(update(response));
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_PROCESSING, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_PROCESSING));
  }
};

export const fetchDescriptorData = (tmapId, descriptorId, isPublic) => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_DESCRIPTOR));
    dispatch(UIAction.clearError(Request.TMAP_DESCRIPTOR));

    let response = await (isPublic ? Api.getDescriptorDataPublic(tmapId, descriptorId, responseType) : Api.getDescriptorData(tmapId, descriptorId, responseType));
    if(responseType === "gz") {
      const arrayBuffer = await response.arrayBuffer();
      const inflated = JSON.parse(pako.inflate(arrayBuffer, { to: 'string'}));
      return inflated;
    }
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_DESCRIPTOR, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_DESCRIPTOR));
  }
};

export const fetchSmiles = (tmapId, nums, headers, descriptors, isPublic) => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_SMILES));
    dispatch(UIAction.clearError(Request.TMAP_SMILES));

    let response = await (isPublic ? Api.getSmilesPublic(tmapId, nums, headers, descriptors) : Api.getSmiles(tmapId, nums, headers, descriptors));
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_SMILES, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_SMILES));
  }
};

export const fetchCopySubsetData = hasCustomDescriptors => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_COPY_SUBSET));
    dispatch(UIAction.clearError(Request.TMAP_COPY_SUBSET));

    let response = await Api.getCopySubsetData(getState().faerun.tmapId, hasCustomDescriptors);
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_COPY_SUBSET, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_COPY_SUBSET));
  }
};

export const copySubsetDataToTmap = (data, dataset, tmap) => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_COPY_SUBSET));
    dispatch(UIAction.clearError(Request.TMAP_COPY_SUBSET));
    let response = await Api.copySubsetDataToTmap(getState().faerun.tmapId, data, dataset, tmap);
    dispatch(add(response.tmap));
    dispatch(DatasetAction.add(response.dataset));
    toast.success("Dataset and TMAP successfully created");
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_COPY_SUBSET, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_COPY_SUBSET));
  }
};

export const fetchDescriptorHistogram = (descriptorId) => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_DESCRIPTOR_HISTOGRAM));
    dispatch(UIAction.clearError(Request.TMAP_DESCRIPTOR_HISTOGRAM));
    const { tmapId, isPublic } = getState().faerun;
    let response = await (isPublic ? Api.getDescriptorHistogramPublic(tmapId, descriptorId) : Api.getDescriptorHistogram(tmapId, descriptorId));
    const img = await response.blob();
    return URL.createObjectURL(img);
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_DESCRIPTOR_HISTOGRAM, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_DESCRIPTOR_HISTOGRAM));
  }
};

export const fetchReportData = () => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_REPORT));
    dispatch(UIAction.clearError(Request.TMAP_REPORT));
    const { tmapId, isPublic } = getState().faerun;
    const nums = getState().cart.items.map(i => i.e.index);
    let response =  await (isPublic ? Api.fetchReportDataPublic(tmapId, nums) : Api.fetchReportData(tmapId, nums));
    if(isPublic) {
      dispatch(CartAction.setReport(response));
    }
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_REPORT, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_REPORT));
  }
};

export const fetchProcessingLog = (tmap_id) => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_PROCESSING_LOG));
    dispatch(UIAction.clearError(Request.TMAP_PROCESSING_LOG));
    let response =  await Api.fetchProcessingLog(tmap_id);
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_PROCESSING_LOG, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_PROCESSING_LOG));
  }
};

export const downloadTmap = (tmap) => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.TMAP_DOWNLOAD));
    dispatch(UIAction.clearError(Request.TMAP_DOWNLOAD));

    let response = await Api.download(tmap.id);
    const arrayBuffer = await response.arrayBuffer();
    saveBinaryFile([arrayBuffer], `${tmap.name}.zip`);
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_DOWNLOAD, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_DOWNLOAD));
  }
};

const remove = data => ({ type: Type.REMOVE_TMAP, data });

const set = data => ({ type: Type.SET_TMAPS, data });

const add = data => ({ type: Type.ADD_TMAP, data });

export const update = data => ({ type: Type.UPDATE_TMAP, data });

export const updateProgress = data => ({ type: Type.UPDATE_PROGRESS, data });
