import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import DatasetForm from "../Datasets/form";
import TMAPForm from "../TMaps/form";
import classes from "./index.module.css";
import { useDispatch, useSelector } from "react-redux";
import { Action as TMAPAction } from "../../store/tmaps";
import { Request } from "../../store/ui/actions";
import Button from "../Button";

const STEPS = { 1: "Dataset", 2: "TMAP" };

const TmapFromData = ({ data, label, className, hasCustomDescriptors }) => {

  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  const [dataset, setDataset] = useState({});
  const [tmap, setTmap] = useState({});
  const loading = useSelector(state => state.ui.loading[Request.TMAP_COPY_SUBSET]);
  const dispatch = useDispatch();

  const close = () => setOpen(false);

  const create = async () => {
    const selectedDescriptors = dataset.descriptors.filter(d => d.selected).map((d) => d.id);
    const descriptors = selectedDescriptors.filter(d => !d.startsWith("x_")).map(d => d.substring(2)).join(",");
    const custom_descriptors = selectedDescriptors.filter(d => d.startsWith("x_"));
    const response = await dispatch(TMAPAction.copySubsetDataToTmap(data, { ...dataset, descriptors, custom_descriptors }, tmap));
    if(response) {
      setOpen(false);
    }
  }

  const openModal = async () => {
    const response = await dispatch(TMAPAction.fetchCopySubsetData(hasCustomDescriptors));
    if(response) {
      setDataset(response.dataset);
      setTmap(response.tmap)
      setStep(1);
      setOpen(true);
    }
  }

  const onDatasetChange = (name, value) => setDataset({ ...dataset, [name]: value });

  const onTMAPChange = (name, value) => setTmap({...tmap, [name]: value });

  return (
    <>
      <Button variant="Success" size="Small" className={className} block onClick={openModal} disabled={loading}>
        {label}
      </Button>

      <Modal show={open} onHide={close} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Step {step} - {STEPS[step]}</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.ModalBody}>
          {step === 1 && <DatasetForm dataset={dataset} onChange={onDatasetChange} onCancel={close} onSubmit={() => setStep(2)} />}
          {step === 2 && <TMAPForm  tmap={tmap} onChange={onTMAPChange} onSubmit={create} onCancel={() => setStep(1)} loading={loading} />}
        </Modal.Body>
      </Modal>
    </>
  );
}

export default TmapFromData;
