import React, { useRef, useState, useEffect } from "react";
import classnames from "classnames";
import classes from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { drawSmiles } from "../../../utils/smiles-drawer";

const NearestNeighbors = ({ hits }) => {
  const copySmilesEl = useRef(null);
  const canvasEl = useRef(null);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    setIndex(0);
  }, [hits]);

  useEffect(() => {
    if (hits && hits[index]) {
      drawSmiles(hits[index][0], canvasEl.current);
    }
  }, [hits, index]);

  const copySelectedTipNNSmilesToClipboard = () => {
    copySmilesEl.current.select();
    copySmilesEl.current.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
  };

  const selectedTipNNPrev = () => setIndex(index <= 0 ? hits.length - 1 : index - 1);
  const selectedTipNNNext = () => setIndex(index >= hits.length - 1 ? 0 : index + 1);

  return (
    <div className={classes.NearestNeighbors}>
      {(!hits || !hits[index]) && (
        <div className={classes.EmptyContainer}>
          <label className={classes.EmptyLabel}>No hits</label>
        </div>
      )}
      {hits && hits[index] && (
        <>
          <div className={classes.Controls}>
            <button className={classnames(classes.Navigation, classes.Prev)} onClick={selectedTipNNPrev}>
              <FontAwesomeIcon icon="angle-left" />
            </button>
            <button className={classnames(classes.Navigation, classes.Next)} onClick={selectedTipNNNext}>
              <FontAwesomeIcon icon="angle-right" />
            </button>
          </div>
          <div className={classes.Container}>
            <canvas ref={canvasEl} />
          </div>
          <div className={classes.InfoContainer}>
            <button className={classes.CopySmilesLink} onClick={copySelectedTipNNSmilesToClipboard}>
              Copy smiles
            </button>
            <input className={classes.CopySmilesInput} type="text" readOnly ref={copySmilesEl} value={hits[index][0]} />
            <div className={classes.Info}>
              <div>
                <label>Similarity:</label>
                {hits[index][1].toFixed(4)}
              </div>
              <div>
                <label>NN Hits:</label>
                {index + 1} / {hits.length}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NearestNeighbors;
