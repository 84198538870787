import React from "react";
import classnames from "classnames";
import classes from "./index.module.css";

const TabLinks = ({ active, activateTab }) => {

  const onClickHandler = ({ currentTarget }) => activateTab(currentTarget.dataset.tab)

  return (
    <div className={classes.TabLinks}>
      <button className={classnames(classes.TabLink, { [classes.Active]: active === "smiles" })} data-tab="smiles" onClick={onClickHandler}>Smiles</button>
      <button className={classnames(classes.TabLink, { [classes.Active]: active === "descriptors"})} data-tab="descriptors" onClick={onClickHandler}>Desc.</button>
      <button className={classnames(classes.TabLink, { [classes.Active]: active === "nearest-neighbors"})} data-tab="nearest-neighbors" onClick={onClickHandler}>NN</button>
    </div>
  );
};

export default TabLinks