import { getColor, getArray } from "../../utils/array";

export const setFilterVisibility = (filter_name, visiblePoints) => (dispatch, getState) => {
  const { faerun } = getState();

  let length = faerun.data.data.descriptors.x.length;
  let filterData = faerun.data.data[filter_name];
  let sFilterData = getArray(length, 0);

  let filterScale = faerun.scatterMeta[faerun.phIndexMap[filter_name]].point_scale;
  let descriptorScale = faerun.scatterMeta[faerun.phIndexMap["descriptors"]].point_scale;
  let scale = descriptorScale / filterScale;

  visiblePoints.forEach((point) => {
    sFilterData[point] = faerun.descriptorData.size ? faerun.descriptorData.size[point] * scale + 1 : 1;
  });

  faerun.pointHelpers[faerun.phIndexMap[filter_name]].setXYZRGBS(
    faerun.data.data.descriptors.x,
    faerun.data.data.descriptors.y,
    faerun.data.data.descriptors.z,
    getColor(filterData["colors"][0].r, sFilterData.length),
    getColor(filterData["colors"][0].g, sFilterData.length),
    getColor(filterData["colors"][0].b, sFilterData.length),
    sFilterData
  );
};
