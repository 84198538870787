export const getArray = (length, value) => {
  let arr = new Array(length);
  for (let i=0; i<length; ++i) arr[i] = value;
  return arr;
}

export const getColor = (color, length) => {
  if(Array.isArray(color)) return color;
  return getArray(length, color);
}
