import React from "react";
import { useSelector } from "react-redux";
import classes from "./index.module.css";

const CartIndicators = () => {
  const cart = useSelector((state) => state.cart);

  if (!cart.current) return null;

  const item = cart.items[cart.current.index];
  
  return (
    <div className={classes.CartIndicator} style={item.indicator}>
      <div className={classes.CrosshairX} />
      <div className={classes.CrosshairY} />
    </div>
  );
};

export default CartIndicators;
