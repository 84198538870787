import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Action as DatasetAction } from "../../store/datasets";
import { Request } from "../../store/ui/actions";
import HistogramRangeSelector, { calculateValues } from "./selector";
import { Modal, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const HistogramRangeSelectorModal = ({ onConfirm, descriptor, datasetId }) => {
  const loading = useSelector((state) => state.ui.loading[Request.DATASET_DESCRIPTOR_HISTOGRAM]);
  const [data, setData] = useState({});
  const [histogram, setHistogram] = useState(null);
  const [open, setOpen] = useState(false);
  const [descriptorData, setDescriptorData] = useState(false);
  const dispatch = useDispatch();

  const openModal = async () => {
    const hist = await dispatch(DatasetAction.fetchDescriptorHistogram(datasetId, descriptor.descriptor.id));
    setHistogram(hist);
    const defaultMin = Math.round(descriptor.descriptor.range[0] * 1000) / 1000;
    const defaultMax = Math.round(descriptor.descriptor.range[1] * 1000) / 1000;
    setDescriptorData({
      id: descriptor.descriptor.id,
      min: defaultMin,
      max: defaultMax,
      value: { min: parseFloat(descriptor.min_limit), max: parseFloat(descriptor.max_limit) },
      type: descriptor.descriptor.type,
    });
    setOpen(true);
  };

  const confirm = () => {
    const values = calculateValues(descriptorData, data);
    onConfirm({ target: { name: "limits", value: { min: values.min || descriptorData.min, max: values.max || descriptorData.max } } });
    closeModal();
  };

  const closeModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Button variant="primary" onClick={openModal}>
        <FontAwesomeIcon icon="chart-bar" />
      </Button>

      <Modal show={open} onHide={closeModal}>
        <Modal.Header>
          <Modal.Title> Descriptor histogram</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {open && (
            <>
              {loading && <span className="text-center">Loading histogram...</span>}
              {!loading && histogram && <HistogramRangeSelector histogram={histogram} descriptor={descriptorData} onChange={setData} data={data} />}
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" type="button" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" type="submit" onClick={confirm}>
            OK
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default HistogramRangeSelectorModal;
