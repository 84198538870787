import React, { useState } from "react";
import { useSelector } from "react-redux";
import classes from "./index.module.css";
import { Request } from "../../store/ui/actions";
import LoadingSpinner from "../LoadingSpinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Series from "./Series";

const Legend = () => {
  const scatterMeta = useSelector((state) => state.faerun.scatterMeta);
  const loading = useSelector((state) => state.ui.loading[Request.FAERUN_FETCH]);

  const [visible, setVisible] = useState(true);
  const toggleVisible = () => setVisible(!visible);

  const series = scatterMeta ? scatterMeta.filter((s) => s.has_legend) : [];

  return (
    <div className={classes.Legend}>
      <div className="d-flex align-items-baseline">
        <h2>Legend</h2>
        <button className={classes.Toggle} onClick={toggleVisible}>
          <FontAwesomeIcon icon={visible ? "toggle-on" : "toggle-off"} />
        </button>
      </div>
      {visible && (
        <div className={classes.Container}>
          {loading && <LoadingSpinner />}
          {series.map((s) => (
            <Series data={s} key={s.name} />
          ))}
        </div>
      )}
    </div>
  );
};

export default Legend;
