import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button, Col, Form, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import classes from "./colorDescriptor.module.css";
import TmapDescriptorColormap from "./descriptorColormap";

const TmapColorDescriptor = ({ descriptor, onChange }) => {
  let customValues;
  if (descriptor.colormap === "custom") {
    customValues =
      descriptor.custom ||
      (descriptor.descriptor.category === "numerical" ? ["#FF0000", "#0000FF"] : descriptor.descriptor.labels.map(() => "#FF0000"));
  }
  const colorChangeHandler = (e) => {
    const index = parseInt(e.target.dataset.index);
    let newValues = customValues.map((c, i) => (i === index ? e.target.value : c));
    onChange({ target: { name: "custom", value: newValues } });
  };

  const colorAddHandler = () => {
    onChange({ target: { name: "custom", value: [...customValues, "#FF0000"] } });
  };

  const colorRemoveHandler = (e) => {
    const index = parseInt(e.currentTarget.dataset.index);
    onChange({ target: { name: "custom", value: customValues.filter((c, i) => i !== index) } });
  };

  return (
    <>
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Colormap</Form.Label>
            <Form.Control as="select" name="colormap" required onChange={onChange} value={descriptor.colormap || ""}>
              <option disabled value="">
                Select colormap
              </option>
              {colormaps[descriptor.descriptor.category].map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select colormap</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Label>&nbsp;</Form.Label>
          <TmapDescriptorColormap descriptor={descriptor} customValues={customValues} />
        </Col>
      </Row>
      {descriptor.descriptor.category === "numerical" && descriptor.colormap === "custom" && (
        <Row>
          <Col sm={12} className="d-flex flex-wrap">
            {customValues.map((cv, index) => (
              <div key={index} className={classes.ColorPickerContainer}>
                <Form.Control
                  className={classes.ColorPicker}
                  type="color"
                  name="color"
                  data-index={index}
                  required
                  value={cv}
                  onChange={colorChangeHandler}
                />
                <Button variant="danger" size="sm" data-index={index} onClick={colorRemoveHandler} className={classes.ColorPickerRemoveButton}>
                  <FontAwesomeIcon icon="times" />{" "}
                </Button>
              </div>
            ))}
            <Button variant="success" className="mb-1" onClick={colorAddHandler}>
              <FontAwesomeIcon icon="plus" />{" "}
            </Button>
          </Col>
        </Row>
      )}
      {descriptor.descriptor.category === "categorical" && descriptor.colormap === "custom" && (
        <div className={classes.CategoricalColormapContainer}>
          <Row>
            <Col sm={12} className="d-flex flex-wrap align-items-center mb-2">
              {descriptor.descriptor.labels.map((l, i) => (
                <div key={l[0]} className={classes.ColorPickerContainer}>
                  <OverlayTrigger placement="top" overlay={<Tooltip>{l[1].toString()}</Tooltip>}>
                    <Form.Control
                      className={classes.ColorPicker}
                      type="color"
                      name="color"
                      data-index={l[0]}
                      required
                      onChange={colorChangeHandler}
                      value={
                        descriptor.colormap === "custom"
                          ? customValues[i]
                          : colors[descriptor.colormap][Math.min(colors[descriptor.colormap].length - 1, l[0])]
                      }
                    />
                  </OverlayTrigger>
                </div>
              ))}
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default TmapColorDescriptor;

const colormaps = {
  numerical: ["default", "plasma", "brg", "gist_rainbow", "rainbow", "jet", "viridis", "custom"],
  categorical: ["Pastel1", "Dark2", "Set1", "tab10", "custom"],
};

const colors = {
  Pastel1: ["#fbb4ae", "#b3cde3", "#ccebc5", "#decbe4", "#fed9a6", "#ffffcc", "#e5d8bd", "#fddaec", "#f2f2f2"],
  Dark2: ["#1b9e77", "#d95f02", "#7570b3", "#e7298a", "#66a61e", "#e6ab02", "#a6761d", "#666666"],
  Set1: ["#e41a1c", "#377eb8", "#4daf4a", "#984ea3", "#ff7f00", "#ffff33", "#a65628", "#f781bf", "#999999"],
  tab10: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"],
};
