import React from "react";
import { Switch, Route } from "react-router-dom";
import FilterTMAPs from "./filter";
import EditTMAP from "./edit";
import CreateTMAP from "./create";
import DeleteTMAP from "./delete";
import TMAP from "../TMAP";

const TMAPs = () => (
  <Switch>
    <Route path="/tmaps/new">
      <CreateTMAP />
    </Route>
    <Route path="/tmaps/:id/delete">
      <DeleteTMAP />
    </Route>
    <Route path="/tmaps/:id/edit">
      <EditTMAP />
    </Route>
    <Route path="/tmaps/:id/view">
      <TMAP />
    </Route>
    <Route path="/tmaps">
      <FilterTMAPs />
    </Route>
  </Switch>
);

export default TMAPs;
