import React, { useRef } from "react";
import { Request } from "../../../store/ui/actions";
import { useDispatch, useSelector } from "react-redux";

import { Action as TMAPsAction } from "../../../store/tmaps";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./index.module.css";

const Link = ({ data }) => {
  const loading = useSelector((state) => state.ui.loading[Request.TMAP_TOKEN]);
  const tokenEl = useRef(null);
  const dispatch = useDispatch();

  const createToken = async () => {
    dispatch(TMAPsAction.generateToken(data.id));
  };

  const deleteToken = async () => {
    dispatch(TMAPsAction.deleteToken(data.id));
  };

  const copyLinkToClipboard = () => {
    tokenEl.current.select();
    tokenEl.current.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
  };

  return (
    <div className={classes.Link}>
      {!data.share_url && (
        <span>
          Link not created. Click{" "}
          <button className={classes.LinkButton} disabled={loading} onClick={createToken}>
            here
          </button>{" "}
          to create one.
        </span>
      )}
      {data.share_url && (
        <InputGroup>
          <FormControl className={classes.LinkInput} readOnly value={data.share_url} ref={tokenEl} />
          <InputGroup.Append>
            <Button variant="success" onClick={copyLinkToClipboard} title="Copy link">
              <FontAwesomeIcon icon="copy" />
            </Button>
            <Button variant="warning" onClick={createToken} disabled={loading}>
              <FontAwesomeIcon icon="sync" title="Generate new link" />
            </Button>
            <Button variant="danger" onClick={deleteToken} disabled={loading}>
              <FontAwesomeIcon icon="times" title="Remove link" />
            </Button>
          </InputGroup.Append>
        </InputGroup>
      )}
    </div>
  );
};

export default Link;
