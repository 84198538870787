import * as Storage from "./storage";

export const get = async (url, params = {}, base=process.env.REACT_APP_API_BASE) => {
  const query = Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
  const response = await fetch(`${base}/${url}${query ? `?${query}`: ""}`, {
    headers: getHeaders(),
  });
  return handleResponse(response);
};

export const rawGet = async (url, params = {}) => {
  const query = Object.keys(params).map(key => `${key}=${params[key]}`).join("&");
  const response = await fetch(`${process.env.REACT_APP_API_BASE}/${url}${query ? `?${query}`: ""}`, {
    headers: getHeaders(),
  });
  return response;
};

export const post = async (url, params = {}, base=process.env.REACT_APP_API_BASE) => {
  
  const response = await fetch(`${base}/${url}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: getHeaders(),
  });
  return handleResponse(response);
};

export const rawPost = async (url, params = {}) => {
  
  const response = await fetch(`${process.env.REACT_APP_API_BASE}/${url}`, {
    method: "POST",
    body: JSON.stringify(params),
    headers: getHeaders(),
  });
  return response;
};

export const put = async (url, params = {}) => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE}/${url}`, {
    method: "PUT",
    body: JSON.stringify(params),
    headers: getHeaders(),
  });
  return handleResponse(response);
};

export const del = async url => {
  const response = await fetch(`${process.env.REACT_APP_API_BASE}/${url}`, {
    method: "DELETE",
    headers: getHeaders(),
  });
  return handleResponse(response);
};

export const formPost = async (url, data) => {

  const formData  = new FormData();
  for(const name in data) {
    formData.append(name, data[name]);
  }

  const response = await fetch(`${process.env.REACT_APP_API_BASE}/${url}`, {
    method: 'POST',
    body: formData,
    headers: getFormHeaders()
  });

  return handleResponse(response);
};

const getHeaders = () => {
  const headers = {
    'Content-Type': 'application/json'
  };
  if(Storage.getToken()) {
    headers.Authorization = `Bearer ${Storage.getToken()}`
  }
  return headers;
}

const getFormHeaders = () => {
  const headers = {};
  if(Storage.getToken()) {
    headers.Authorization = `Bearer ${Storage.getToken()}`
  }
  return headers;
}

const handleResponse = async response => {
  if(response.ok) {
    return response.json();
  } else {
    const data = await response.json();
    throw new ApiError((data || {}).message || "Server error", data);
  }
}

export class ApiError extends Error {
  constructor(message, data) {
    super(message);
    this.data = data;
  }
}
