import React from "react";
import classes from "./index.module.css";
import RemoveFilterButton from "../RemoveFilterButton";
import SmilesInput from "../../SmilesInput";

const SimilarityFilter = ({ filter, onChange, value, onRemove}) => {
  
  const onChangeHandler = ({ target }) => {
    const newValue = value ? { ...value } : { type: "similarity", threshold: 0.5, invert: false };
    if (target.name === "invert") {
      newValue.invert = target.checked;
    } else {
      newValue[target.name] = target.value;
    }
    onChange(filter.id, newValue);
  }

  return (
    <div className={classes.SubstructureFilter}>
      <RemoveFilterButton name={filter.id} onClick={onRemove} />
      <label className={classes.Label}>Similarity search</label>
      <SmilesInput placeholder="Smiles string" name="smiles" value={value ? value.smiles : ""} onChange={onChangeHandler} position="Right" />
      <label className={classes.RangeLabel}>Similarity: { value ? value.threshold : "0.5" }</label>
      <input name="threshold" type="range" min="0" max="1" step="0.01" value={value ? value.threshold : 0.5} onChange={onChangeHandler} />
      <label className="small mt-1 mb-0 d-flex align-items-center">
        <input type="checkbox" className="mr-1" value={value?.invert || ""} name="invert" onChange={onChangeHandler} />
        Invert filter
      </label>
    </div>
  );
};

export default SimilarityFilter;
