import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { Request } from "../../store/ui";
import { Action as DatasetsAction } from "../../store/datasets";
import DatasetForm from "./form";


const EditDataset = () => {

  const loading = useSelector(state => state.ui.loading[Request.DATASET_FETCH]);
  const error = useSelector(state => state.ui.error[Request.DATASET_FETCH]);
  const id = parseInt(useParams().id);
  const data = useSelector(state => state.datasets.find(dataset => dataset.id === id));
  const dispatch = useDispatch();
  
  const [dataset, setDataset] = useState(data);
  
  useEffect(() => { dispatch(DatasetsAction.fetchDataset(id)); }, [id, dispatch]);
  useEffect(() => { setDataset(data); }, [data])

  const updateDatasetField = useCallback((name, value) => setDataset({...dataset, [name]: value }), [dataset]);

  const submitDataset = useCallback(async () => dispatch(DatasetsAction.updateDataset(dataset)), [dataset, dispatch]);

  if(error) {
    return error;
  }

  if(loading || !data) {
    return "Loading...";
  }

  return (
    <Container>
      <h1 className="mb-5">Edit dataset '{dataset.name}'</h1>
      <DatasetForm onChange={updateDatasetField} onSubmit={submitDataset} dataset={dataset} />
    </Container>
  );
};

export default EditDataset;
