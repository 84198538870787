import React from "react";
import classes from "./index.module.css";
import RemoveFilterButton from "../RemoveFilterButton";
import SmilesInput from "../../SmilesInput";

const SubstructureFilter = ({ filter, onChange, value, onRemove}) => {

  const onChangeHandler = ({ target }) => {
    const newValue = value ? { ...value } : { type: "substructure", invert: false, smarts: "" };
    if (target.name === "invert") {
      newValue.invert = target.checked;
    } else {
      newValue.smarts = target.value;
    }
    onChange(filter.id, newValue);
  }

  return (
    <div className={classes.SubstructureFilter}>
      <RemoveFilterButton name={filter.id} onClick={onRemove} />
      <label className={classes.Label}>Substructure search</label>
      <SmilesInput placeholder="SMARTS string" value={value ? value.smarts : ""} onChange={onChangeHandler} position="Right" />
      <label className="small mt-1 mb-0 d-flex align-items-center">
        <input type="checkbox" className="mr-1" value={value?.invert || ""} name="invert" onChange={onChangeHandler} />
        Invert filter
      </label>
    </div>
  );
};

export default SubstructureFilter;
