import { Action as MoleculeAction } from "../molecule";
import { Action as TMAPAction } from "../tmaps";
import { saveTextFileFromArray } from "../../utils/file";
import * as Helpers from "./helpers";

export const Type = {
  ADD_TO_CART: "CART_ADD_TO_CART",
  REMOVE_FROM_CART: "CART_REMOVE_FROM_CART",
  CLEAR_CART: "CART_CLEAR_CART",
  UPDATE_CURRENT: "CART_UPDATE_CURRENT",
  SET_ITEMS: "CART_SET_ITEMS",
  UPDATE_LOG: "CART_UPDATE_LOG",
  SET_LOG_FILTER: "CART_SET_LOG_FILTER",
  SET_REPORT: "CART_SET_REPORT",
};


export const updateCartData = (data, source) => (dispatch, getState) => {
  const { items, current, log, logItems } = getState().cart;
  const { faerun } = getState();

  if(!data || data.length === 0) return;

  let toAdd = [];
  let toAddLog = [];

  data.forEach(molecule => {
    // eslint-disable-next-line eqeqeq
    if(!items.some(item => item.e.index == molecule)) {
      toAdd.push({
        e: {
          index: molecule
        },
        source: 2,
        indicator: getIndicatorData(molecule, 2, faerun),
      });
      toAddLog.push(log.length);
    }
  });

  if(toAdd.length > 0)  {
    dispatch(addToCart(toAdd));
    dispatch(updateLog([...log, {...source, addedItemsCount: toAdd.length}], [...logItems, ...toAddLog]));
    if(!current) {
      dispatch(updateCurrent({ index: 0, e: { index: toAdd[0].e.index } }));
      dispatch(MoleculeAction.fetchMoleculeData(toAdd[0].e.index));
    }
  }
};

export const addFilterToCart = () => (dispatch, getState) => {
  const { data } = getState().filterSearch;
  const filter = getState().cart.filterSearch;
  if(data)
    dispatch(updateCartData(data, { type: "filter_search", filter }));
};

export const addNNToCart = () => (dispatch, getState) => {
  const { nnSearch } = getState();
  const filter = getState().cart.nnSearch;

  if(nnSearch)
    dispatch(updateCartData(Object.keys(nnSearch), { type: "nn_search", filter }));
}

export const addScrollSelectionToCart = () => (dispatch, getState) => {
  const { scrollSelection } = getState();
  if(scrollSelection.selection.length) {
    dispatch(updateCartData(scrollSelection.selection, { type: "scroll_selection",  item: scrollSelection.selection[0]  }));
  }
}

const getIndicatorData = (index, ohIndex, data) => {
    let pointSize = data.pointHelpers[data.ohIndexToPhIndex[ohIndex]].getPointSize();
    let screenPosition = data.octreeHelpers[ohIndex].getScreenPosition(index);

    // Make the crosshairs larger than the point
    pointSize = Math.max(pointSize, 10);
    pointSize *= 1.25;
    let halfPointSize = pointSize / 2.0;

    return {
      left: screenPosition[0] - halfPointSize + "px",
      top: screenPosition[1] - halfPointSize + data.lore.canvas.offsetTop + "px",
      width: pointSize + "px",
      height: pointSize + "px"
    };
}


export const addToCart = items => ({ type: Type.ADD_TO_CART, data: items });

export const updateLog = (log, logItems) => ({ type: Type.UPDATE_LOG, data: { log, logItems } });

export const setFiterSearch = filterSearch => ({ type: Type.SET_LOG_FILTER, data: { filterSearch } });

export const setNnSearch = nnSearch => ({ type: Type.SET_LOG_FILTER, data: { nnSearch } });

export const removeCurrent = () =>  (dispatch, getState) => {
  const { items, current, log, logItems } = getState().cart;
  if(current) {
    // eslint-disable-next-line eqeqeq
    const newItems = items.filter((_, index) => index != current.index);
    // eslint-disable-next-line eqeqeq
    const newLogItems = logItems.filter((_, index) => index != current.index);
    const newLog = [...log, { type: "remove", position: (current.index + 1)}];
    const index = current.index >= newItems.length - 1 ? newItems.length - 1 : current.index;
    const newCurrent = index > -1 ? { index, e: items[index].e  } : null;
    if(newCurrent) {
      dispatch(MoleculeAction.fetchMoleculeData(newCurrent.e.index));
    }
    dispatch(removeFromCart(newItems, newCurrent));
    dispatch(updateLog(newLog, newLogItems));
  }
}

const removeFromCart = (items, current) => ({ type: Type.REMOVE_FROM_CART, data: { items, current } });

export const next = () => (dispatch, getState) => {
  const { cart } = getState();

  const current = cart.current || { index: 0 };
  const index = current.index >= cart.items.length - 1 ? 0 : current.index + 1;
  dispatch(MoleculeAction.fetchMoleculeData(cart.items[index].e.index));
  dispatch(updateCurrent({ index, e: cart.items[index].e }));
};

export const prev = () => (dispatch, getState) => {
  const { cart } = getState();

  const current = cart.current || { index: 0};
  const index = current.index <= 0 ? cart.items.length - 1 : current.index - 1;
  dispatch(MoleculeAction.fetchMoleculeData(cart.items[index].e.index));
  dispatch(updateCurrent({ index, e: cart.items[index].e }));
};

export const clearCart = () => ({ type: Type.CLEAR_CART });

export const updateCurrent = data => ({ type: Type.UPDATE_CURRENT, data });

export const addSelectionToCart = () => (dispatch, getState) => {
  const { faerun } = getState();
  const hoveredHelperIndex = faerun.octreeHelpers.findIndex(helper => helper.hovered);

  if(hoveredHelperIndex > -1) {
    const { items, log, logItems } = getState().cart;
    const hoveredHelper = faerun.octreeHelpers[hoveredHelperIndex].hovered;
    if(!items.some(item => item.e.index === hoveredHelper.index)) {
      const toAdd = [{
        e: { index: hoveredHelper.index },
        source: 2, 
        indicator: getIndicatorData(hoveredHelper.index, hoveredHelperIndex,faerun),
      }];
      dispatch(addToCart(toAdd));
      dispatch(updateLog([...log, {type: "dbl_click", item: toAdd[0].e.index }], [...logItems, log.length]));
      dispatch(MoleculeAction.fetchMoleculeData(hoveredHelper.index));
      dispatch(updateCurrent({ index: items.length, e: toAdd[0].e }));
    }
  }
}

export const updateIndicatorPositions = () => (dispatch, getState) => {
  const { faerun } = getState();

  const { items } = getState().cart;
  const updated = items.map(item => ({ ...item, indicator: getIndicatorData(item.e.index, item.source, faerun) }));
  dispatch({type: Type.SET_ITEMS, data: updated });
};

export const exportCartSmilesToFile = (descriptors, options) => async (dispatch, getState) => {
  const { items, log, logItems } = getState().cart;
  const { tmapId, isPublic } = getState().faerun;

  
  if(items && items.length) {
    const nums = items.map(i => i.e.index);
    const response = await dispatch(TMAPAction.fetchSmiles(tmapId, nums, options.headers, descriptors, isPublic));
    if(response) {
      const logData = options.logColumn ? { log: Helpers.getColumnLogData(log), items: logItems } : null;
      const fileData = Helpers.getSmilesArrayForExport(response, logData);
      saveTextFileFromArray(fileData, "smiles_export.csv")
      if(options.logFile) {
        dispatch(exportCartLogToFile());
      }
    }
  }
}

export const exportCartLogToFile = () => async (dispatch, getState) => {
  const { log, items } = getState().cart;
  if(log.length && items.length) {
    const fileData = Helpers.getFileLogData(log, getState().filterSearch.filters, getState().molecule, items.length);
    saveTextFileFromArray(fileData, "cart_log.log");
  }
}

export const setReport = data => ({ type: Type.SET_REPORT, data });