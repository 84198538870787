import Lore from "lore-engine";
import { getArray, getColor } from "../../utils/array";

export const initLore = clearColor => {
  return Lore.init("lore", {
    antialiasing: true,
    clearColor,
    alphaBlending: true,
    preserveDrawingBuffer: true
  });
}

export const addZAxis = data => {
  data.data.data.descriptors.z = getArray(data.data.data.descriptors.x.length, 0);
  const name = data.data.tree_meta[0].name;
  data.data.data[name].z = getArray(data.data.data[name].x.length, 0);
}

export const initTreeHelpers = (lore, data, treeMeta, clearColor) => {
  const treeHelpers = [];
  treeMeta.forEach(t => {
    let th = new Lore.Helpers.TreeHelper(lore, t.name, "tree");
    th.setXYZHexS(data[t.name].x, data[t.name].y, data[t.name].z, t.color);
    th.setFog(
      [
        clearColor.components[0],
        clearColor.components[1],
        clearColor.components[2],
        clearColor.components[3]
      ],
      t.fog_intensity
    );
    treeHelpers.push(th);
  });
  return treeHelpers;
}

export const initView = data => {
  let center = new Lore.Math.Vector3f(
    (data.max[0] + data.min[0]) / 2.0,
    (data.max[1] + data.min[1]) / 2.0,
    (data.max[2] + data.min[2]) / 2.0
  );
  data.lore.controls.setLookAt(center);
  data.lore.controls.setRadius(data.maxRadius + 100);
  data.lore.controls.setView(0.9, -0.5);
  data.lore.controls.setViewByName("front");
}

export const initPointHelpers = (data, descriptor) =>  {

  data.scatterMeta.forEach(s => {

    let ph = new Lore.Helpers.PointHelper(data.lore, s.name, s.shader, {
      maxPointSize: s.max_point_size
    });

    ph.setXYZRGBS(
      data.data.data.descriptors.x,
      data.data.data.descriptors.y,
      data.data.data.descriptors.z,
      getColor(s.name === "descriptors" ? descriptor.r : data.data.data[s.name]["colors"][0].r, data.data.data.descriptors.x.length),
      getColor(s.name === "descriptors" ? descriptor.g : data.data.data[s.name]["colors"][0].g, data.data.data.descriptors.x.length),
      getColor(s.name === "descriptors" ? descriptor.b : data.data.data[s.name]["colors"][0].b, data.data.data.descriptors.x.length),
      s.name === "descriptors" ? 1.0 : getArray(data.data.data.descriptors.x.length, 0)
    );

    if(s.name === "descriptors") {
      ph.geometry.addAttribute("transparency", new Float32Array(getArray(data.data.data.descriptors.x.length, 1.0)), 1)
    }


    ph.setPointScale(s.point_scale);
    ph.setFog(
      [
        data.clearColor.components[0],
        data.clearColor.components[1],
        data.clearColor.components[2],
        data.clearColor.components[3]
      ],
      s.fog_intensity
    );

    data.phIndexMap[s.name] = data.pointHelpers.length;
    data.pointHelpers.push(ph);

    data.min[0] = getMin(data.data.data.descriptors.x, data.min[0]);
    data.min[1] = getMin(data.data.data.descriptors.y, data.min[1]);
    data.min[2] = getMin(data.data.data.descriptors.z, data.min[2]);
    data.max[0] = getMax(data.data.data.descriptors.x, data.max[0]);
    data.max[1] = getMax(data.data.data.descriptors.y, data.max[1]);
    data.max[2] = getMax(data.data.data.descriptors.z, data.max[2]);
    data.maxRadius = ph.getMaxRadius();

    if (s.interactive) {
      data.octreeHelpers.push(new Lore.Helpers.OctreeHelper(data.lore, "Octree_" + s.name, "tree", ph));

      data.ohIndexMap[s.name] = data.octreeHelpers.length - 1;
      data.ohIndexToPhName.push(s.name);
      data.ohIndexToPhIndex.push(data.phIndexMap[s.name]);
    }

  });
}

export const getMin = (arr, other = Number.MAX_VALUE) => {
  let m = Number.MAX_VALUE;
  for (var i = 0; i < arr.length; i++) if (arr[i] < m) m = arr[i];

  if (m < other) return m;
  return other;
}

export const getMax = (arr, other = -Number.MAX_VALUE) => {
  let m = -Number.MAX_VALUE;
  for (var i = 0; i < arr.length; i++) if (arr[i] > m) m = arr[i];

  if (m > other) return m;
  return other;
}

export const getHoverIndicator = (data, helper, source) => {
  let pointSize = data.pointHelpers[source].getPointSize();
  if(data.descriptorData.size) {
    pointSize = pointSize * data.descriptorData.size[helper.index];
  } else {
    pointSize = Math.max(pointSize, 10) * 1.25;
  }
  const x = helper.screenPosition[0];
  const y = helper.screenPosition[1];
  return {
    index: helper.index,
    ohIndex: source,
    phIndex: data.ohIndexToPhIndex[source],
    style: {
      width: pointSize + "px",
      height: pointSize + "px",
      left: x - pointSize / 2.0 - 1 + "px",
      top: y + data.lore.canvas.offsetTop - pointSize / 2.0 - 1 + "px",
    }
  }
}

export const getDescriptorsFromApi = (data, filters) => {
  let descriptors = [];
  if(filters) {
    let mappedData = data.reduce((prev, current) => ({...prev, [current.descriptor_id]: current }), {});
    Object.values(filters).forEach(filter => {
      if(filter.descriptorId && mappedData[filter.descriptorId]) {
        descriptors.push({
          key: filter.id,
          title: filter.title,
          value: formatValue(filter.type, mappedData[filter.descriptorId])
        });
      }
    });
  }
  return descriptors;
}

const formatFloat = (val) => {
  return Number.parseFloat(val.float_val).toFixed(4);
}

const formatInt = (val) => {
  return Number.parseInt(val.int_val).toFixed(0);
}

const formatBool = (val) => {
  return val.bool_val ? "True" : "False";
}

const formatValue = (type, val) => {
  switch (type) {
    case "float":
      return formatFloat(val);
    case "int":
      return formatInt(val);
    case "bool":
      return formatBool(val);
    case "text":
      return val.text_val;
    default:
      return val;
  }
}
