import React from "react";
import classes from "./index.module.css";

const CategorySeriesLegend = ({ data }) => (
  <div className={classes.CategorySeries}>
    {data.map((element) => (
      <div className={classes.LegendElement} key={element[1]}>
        <div
          className={classes.ColorBox}
          style={{
            backgroundColor: `rgba(${element[0][0] * 255}, ${element[0][1] * 255}, ${element[0][2] * 255}, ${element[0][3]})`,
            borderColor: `rgba(${element[0][0] * 255}, ${element[0][1] * 255}, ${element[0][2] * 255}, ${element[0][3]})`,
          }}
        />
        <div className={classes.LegendLabel}>{element[1] + ""}</div>
      </div>
    ))}
  </div>
);

export default CategorySeriesLegend;
