import React from "react";
import { Provider } from "react-redux";
import Store from "../../store";
import Main from "../Main";

const App = () => {
  return (
    <Provider store={Store}>
      <Main />
    </Provider>
  );
}

export default App;
