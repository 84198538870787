const getSmilesArray = (smiles, headers = false) => {
  if (smiles && smiles.length) {
    return [(headers ? "SMILES\n" : "") + smiles.join("\n")];
  }
  return [];
};

export const getMqnSearchFileData = (data, headers, mqnDistance, mhfp6Distance) => {
  if(!mqnDistance && !mhfp6Distance) {
    return getSmilesArray(data.map(i => i.smiles), headers);
  } else {
    if (data && data.length) {
      let data = [];
      if(headers) {
        data.push("SMILES" + (mqnDistance ? ",MQN Distance": "") + (mhfp6Distance ? ",MHFP6 Distance": ""));
      }
      for(let i = 0; i < data.length; i++) {
        let line = data[i].smiles + (mqnDistance ? `,${data[i].mqn}`: "")  + (mhfp6Distance ? `,${data[i].mhfp6}`: "");
        data.push(line);
      }
      return data;
    }
  }
  return [];
};