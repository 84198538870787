import React from "react";
import classes from "./index.module.css";
import classnames from "classnames";

const Button = ({ variant, size, label, block, children, className, ...props }) => {
  return (
    <button className={classnames(classes.Button, { [classes[variant]]: variant, [classes[size]]: size, [classes.Block]: block }, className)} {...props}>
      {children}
    </button>
  );
};

export default Button;
