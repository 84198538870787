import React, { useEffect, useState } from "react";
import { Request } from "../../store/ui";
import { useDispatch, useSelector } from "react-redux";
import { Action as DatasetsAction } from "../../store/datasets";
import { Helpers as UserHelpers } from "../../store/users";
import { Table, Container, ButtonGroup, ProgressBar } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import StateBadge from "../StateBadge";
import Share from "./Share";
import ConfirmationModal from "../ConfirmationModal";
import ProcessingLog from "../ProcessingLog";
import SortableHeader from "../SortableHeader";

const FilterDatasets = () => {
  const loading = useSelector((state) => state.ui.loading[Request.DATASETS_FETCH]);
  const error = useSelector((state) => state.ui.error[Request.DATASETS_FETCH]);
  const processing = useSelector((state) => state.ui.loading[Request.DATASET_PROCESSING]);
  const datasets = useSelector((state) => state.datasets);
  const user = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [sorts, setSorts] = useState([]);
  const disable_user_share = useSelector((state) => state.auth.disable_user_share);

  useEffect(() => {
    dispatch(DatasetsAction.filterDatasets());
  }, [dispatch]);

  const canAdd = UserHelpers.canEditDatasets(user);

  const canEdit = (dataset) => UserHelpers.isAdmin(user) || dataset.created_by.id === user.id;

  const canStop = (dataset) => dataset.state.startsWith("processing") || dataset.state.startsWith("pending");

  const canStart = (dataset) => dataset.state.startsWith("cancelled") || dataset.state.startsWith("error");

  const stopProcessing = (dataset) => dispatch(DatasetsAction.stopProcessing(dataset.id));

  const processDataset = (dataset) => dispatch(DatasetsAction.startProcessing(dataset.id));

  const updateSorts = e => {
    const name = e.currentTarget.dataset.name;
    let sort = sorts.find(s => s[0] === name);
    if(!sort) {
      setSorts([...sorts,[name, "ASC"]]);
    } else if(sort[1] === "ASC") {
      setSorts(sorts.map(s => s[0] === name ? [name, "DESC"] : s));
    } else {
      setSorts(sorts.filter(s => s[0] !== name));
    }
  }

  const sortDatasets = () => {
    if(!sorts || !sorts.length) return datasets;
    const sorted = [...datasets].sort((a, b) => {
      if(sorts[0][1] === "ASC") return a.name > b.name ? 1 : -1;
      return a.name < b.name ? 1 : -1;
    });
    return sorted;
  }

  return (
    <Container>
      <h1 className="mb-3">Datasets</h1>

      {canAdd && (
        <Link to="/datasets/new" type="button" className="btn btn-success mb-3">
          <FontAwesomeIcon icon="plus" /> Create new dataset
        </Link>
      )}

      <Table hover>
        <thead className="thead-light">
          <tr>
            <th>#</th>
            <SortableHeader name="name" label="Name" sorts={sorts} onChange={updateSorts} />
            <th>Created by</th>
            <th>State</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={canAdd ? 5 : 4}>Loading...</td>
            </tr>
          )}
          {!loading && error && (
            <tr>
              <td colSpan={canAdd ? 5 : 4}>{error}</td>
            </tr>
          )}
          {!loading && !error && datasets && !datasets.length && (
            <tr>
              <td colSpan={canAdd ? 5 : 4}>No datasets</td>
            </tr>
          )}
          {!loading &&
            !error &&
            datasets &&
            datasets.length > 0 &&
            sortDatasets().map((dataset) => (
                <tr key={dataset.id}>
                  <td>{dataset.id}</td>
                  <td>{dataset.name}</td>
                  <td>{dataset.created_by.name}</td>
                  <td>
                    <StateBadge state={dataset.state} />
                    {dataset.progress && <ProgressBar now={dataset.progress} label={`${dataset.progress}%`} />}
                  </td>
                  <td>
                    {canEdit(dataset) && (
                      <ButtonGroup aria-label="Dataset actions">
                        <Link to={`/datasets/${dataset.id}`} className="btn btn-primary">
                          <FontAwesomeIcon icon="edit" />
                        </Link>
                        <Link to={`/datasets/${dataset.id}/delete`} className="btn btn-danger">
                          {" "}
                          <FontAwesomeIcon icon="trash" />
                        </Link>
                        {canStop(dataset) && (
                          <ConfirmationModal
                            variant="warning"
                            label={<FontAwesomeIcon icon="stop" />}
                            onConfirm={() => stopProcessing(dataset)}
                            title="Stop processing"
                            content={`Are you sure you want to stop processing ${dataset.name}?`}
                            submitting={processing}
                          />
                        )}
                        {canStart(dataset) && (
                          <ConfirmationModal
                            variant="warning"
                            label={<FontAwesomeIcon icon="play" />}
                            onConfirm={() => processDataset(dataset)}
                            title="Process dataset"
                            content={`Are you sure you want to process ${dataset.name}?`}
                            submitting={processing}
                          />
                        )}
                        {!disable_user_share && <Share variant="info" label={<FontAwesomeIcon icon="share-alt" />} dataset={dataset} />}
                        <ProcessingLog datasetId={dataset.id} />
                      </ButtonGroup>
                    )}
                  </td>
                </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default FilterDatasets;
