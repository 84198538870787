import * as Api from "../../utils/api";

const TMAPS = "tmaps";

const TMAPS_PUBLIC = "tmaps/public";

export const filter = async () => Api.get(TMAPS);

export const get = async (id) => Api.get(`${TMAPS}/${id}`);

export const update = async (tmap) => Api.put(`${TMAPS}/${tmap.id}`, tmap);

export const remove = async (id) => Api.del(`${TMAPS}/${id}`);

export const create = async (data) => Api.post(TMAPS, data);

export const details = async (id, response) => (response === "gz" ? Api.rawGet : Api.get)(`${TMAPS}/${id}/data`, { response });

export const nnSearch = async (id, data, similarity) => Api.post(`${TMAPS}/${id}/nn-search`, { search: data, similarity });

export const filterSearchFilters = async (id) => Api.get(`${TMAPS}/${id}/filter-search`);

export const filterSearch = async (id, data) => Api.post(`${TMAPS}/${id}/filter-search`, data);

export const permissions = async (id) => Api.get(`${TMAPS}/${id}/permissions`);

export const togglePermission = async (id, user_id) => Api.post(`${TMAPS}/${id}/permissions`, { user_id });

export const generateToken = async (id) => Api.post(`${TMAPS}/${id}/token`);

export const deleteToken = async (id) => Api.del(`${TMAPS}/${id}/token`);

export const stopProcessing = async (id) => Api.post(`${TMAPS}/${id}/processing/stop`);

export const startProcessing = async (id) => Api.post(`${TMAPS}/${id}/processing/start`);

export const getDescriptorData = async (tmap_id, descriptor_id, response) =>
  (response === "gz" ? Api.rawGet : Api.get)(`${TMAPS}/${tmap_id}/descriptors/${descriptor_id}`, { response });

export const getMoleculesData = async (tmap_id, num) => Api.post(`${TMAPS}/${tmap_id}/molecules`, { num });

export const getSmiles = async (tmap_id, nums, headers, descriptors) => Api.post(`${TMAPS}/${tmap_id}/smiles`, { nums, headers, descriptors });

export const getCopySubsetData = async (tmap_id, hasCustomDescriptors) => Api.get(`${TMAPS}/${tmap_id}/copy-subset?custom=${hasCustomDescriptors}`);

export const copySubsetDataToTmap = async (tmap_id, data, dataset, tmap) => Api.post(`${TMAPS}/${tmap_id}/copy-subset`, { ...data, dataset, tmap });

export const getDescriptorHistogram = async (tmap_id, descriptor_id) => Api.rawGet(`${TMAPS}/${tmap_id}/descriptor/${descriptor_id}/histogram`);

export const fetchReportData = async (tmap_id, nums) => Api.post(`${TMAPS}/${tmap_id}/report`, { nums });

export const fetchProcessingLog = async (tmap_id) => Api.get(`${TMAPS}/${tmap_id}/processing/log`);

export const download = async (tmap_id) => Api.rawGet(`${TMAPS}/${tmap_id}/download`);

/* PUBLIC / SHARE APIs */

export const infoPublic = async (token) => Api.get(`${TMAPS_PUBLIC}/info`, { token });

export const detailsPublic = async (token, response) => (response === "gz" ? Api.rawGet : Api.get)(`${TMAPS_PUBLIC}/data`, { token, response });

export const filterSearchFiltersPublic = async (token) => Api.get(`${TMAPS_PUBLIC}/filter-search`, { token });

export const filterSearchPublic = async (token, data) => Api.post(`${TMAPS_PUBLIC}/filter-search?token=${token}`, data);

export const nnSearchPublic = async (token, data, similarity) => Api.post(`${TMAPS_PUBLIC}/nn-search?token=${token}`, { search: data, similarity });

export const getDescriptorDataPublic = async (token, descriptor_id, response) =>
  (response === "gz" ? Api.rawGet : Api.get)(`${TMAPS_PUBLIC}/descriptors/${descriptor_id}`, { token, response });

export const getMoleculesDataPublic = async (token, num) => Api.post(`${TMAPS_PUBLIC}/molecules`, { token, num });

export const getSmilesPublic = async (token, nums, headers, descriptors) => Api.post(`${TMAPS_PUBLIC}/smiles`, { token, nums, headers, descriptors });

export const getDescriptorHistogramPublic = async (token, descriptor_id) => Api.rawGet(`${TMAPS_PUBLIC}/descriptor/${descriptor_id}/histogram`, { token });

export const fetchReportDataPublic = async (token, nums) => Api.post(`${TMAPS_PUBLIC}/report`, { token, nums });
