import React, { useEffect, useRef } from "react";
import classnames from "classnames";
import classes from "./index.module.css";
import { drawSmiles } from "../../../utils/smiles-drawer";
import StickyDescriptors from "../Descriptors/sticky";

const Smiles = ({ data, isSelected, enableCopySmiles, type, sticky }) => {

  const copySmilesEl = useRef(null);
  const canvasEl = useRef(null);

  const copySelectedTipSmilesToClipboard = () => {
    copySmilesEl.current.select();
    copySmilesEl.current.setSelectionRange(0, 99999); /*For mobile devices*/
    document.execCommand("copy");
  };

  useEffect(() => {
    if(data) {
      drawSmiles(data.smiles, canvasEl.current, type);
    }
  }, [data, type]);

  return (
    <div className={classnames(classes.Smiles, classes[type])}>
      {!data && (
        <div className={classes.EmptyContainer}>
          <label className={classes.EmptyLabel}>No Selection</label>
        </div>
      )}
      {data && data.loading && (
        <div className={classes.EmptyContainer}>
          <label className={classes.EmptyLabel}>Loading...</label>
        </div>
      )}
      {data && !data.loading && (
      <div className={classnames(classes.Tip, { [classes.SelectedTip]: isSelected })}>
        <canvas ref={canvasEl} />
      </div>
      )}
      {enableCopySmiles && data && (
        <div className={classes.ControlsBottom}>
          <button className={classes.CopySmilesLink} onClick={copySelectedTipSmilesToClipboard}>Copy smiles</button>
          <input className={classes.CopySmilesInput} type="text" readOnly value={data.smiles || ""} ref={copySmilesEl} />
        </div>
      )}
      {sticky && <StickyDescriptors data={sticky} />}
    </div>
  );
};

export default Smiles;
