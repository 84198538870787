import React, { useEffect, useState } from "react";
import { Modal, Button as RbButton, Col, Row, Spinner } from "react-bootstrap";
import { Action as TMAPAction } from "../../store/tmaps";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import { toast } from "react-toastify";

let showNotification = false;

let labels = {
  molwt: "Molecular weight",
  clogp: "cLogP",
  hbd: "HBD",
  hba: "HBA",
  tpsa: "TPSA",
  num_rot_bonds: "Rot. bonds",
};

const CartReport = ({ label, className }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const dispatch = useDispatch();

  const items = useSelector((state) => state.cart.items);
  const descriptors = useSelector((state) => state.faerun.data.data.descriptors);
  const report = useSelector((state) => state.cart.report);

  useEffect(() => {
    setData(report);
    if (report && showNotification) {
      toast.success("Cart report generated");
    }
  }, [report]);

  const fetchReport = () => {
    if (!data) {
      dispatch(TMAPAction.fetchReportData());
    }
  };

  const openModal = () => {
    if (!items || !items.length) {
      toast.warning("Cart is empty");
      return;
    }
    setOpen(true);
    fetchReport();
    showNotification = false;
  };

  const closeModal = () => {
    setOpen(false);
    showNotification = true;
  };

  return (
    <>
      <Button size="Small" className={className} block onClick={openModal}>
        {label}
      </Button>

      <Modal show={open} onHide={() => setOpen(false)} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Cart report</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!data && (
            <>
              <div className="d-flex align-items-center">
                <Spinner animation="border" role="status">
                  <span className="sr-only">Loading...</span>
                </Spinner>
                <h5 className="my-0 ml-2">Generating Report</h5>
              </div>
              <div className="mt-3">
                * If there is many items in the cart, generating report can take a while. You can close this modal, and when generating is complete,
                you will be notified.
              </div>
            </>
          )}
          {data && (
            <>
              <Row>
                <Col xs={12}>
                  <h3 className="text-center">Selection</h3>
                  <p>
                    Cart contains {items.length} out of {descriptors.x.length} molecules from dataset {data.meta.dataset}.
                  </p>
                </Col>
              </Row>
              <Row>
                <Col xs={12} className="text-center">
                  <h3>Druglikeness</h3>
                </Col>
                {Object.keys(data.charts).map((key) => (
                  <Col xs={6} key={key} className="text-center mb-2">
                    <img src={"data:image/png;base64," + data.charts[key]} alt={key} />
                    <span className="my-0">{labels[key]}</span>
                  </Col>
                ))}
              </Row>
              <Row className="mt-3">
                <Col xs={12} className="text-center">
                  <h3>Lipinski rule of five</h3>
                </Col>
                <Col xs={6} className="text-center mb-2">
                  <img src={"data:image/png;base64," + data.lipinski.charts.drug_like} alt="drug like" />
                  <h5 className="my-0">Drug like ({(data.lipinski.percentages.drug_like * 100).toFixed(2)}%)</h5>
                </Col>
                <Col xs={6} className="text-center mb-2">
                  <img src={"data:image/png;base64," + data.lipinski.charts.lead_like} alt="lead like" />
                  <h5 className="my-0">Lead like ({(data.lipinski.percentages.lead_like * 100).toFixed(2)}%)</h5>
                </Col>
              </Row>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <RbButton variant="warning" type="button" onClick={closeModal}>
            Close
          </RbButton>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CartReport;
