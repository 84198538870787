import React, { useEffect, useState } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Action as TMAPAction } from "../../store/tmaps";
import { Action as UIAction } from "../../store/ui";
import Button from "../Button";
import classes from "./index.module.css";
import { Request } from "../../store/ui/actions";
import HistogramRangeSelector, { calculateValues } from "./selector";

const HistogramRangeSelectorPanel = () => {
  const { open, onConfirm, descriptor } = useSelector((state) => state.ui.histogramSelector);
  const loading = useSelector((state) => state.ui.loading[Request.TMAP_DESCRIPTOR_HISTOGRAM]);
  const tmapId = useSelector((state) => state.faerun.tmapId);
  const [data, setData] = useState({});
  const [histogram, setHistogram] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    setHistogram({});
  }, [tmapId]);

  useEffect(() => {
    if (open) {
      const loadHistogram = async () => {
        const hist = await dispatch(TMAPAction.fetchDescriptorHistogram(descriptor.id));
        if (hist) setHistogram({ ...histogram, [descriptor.id]: hist });
      };

      if (!histogram[descriptor.id]) loadHistogram();
    }
  }, [open, descriptor, dispatch, histogram]);

  const confirm = () => {
    const values = calculateValues(descriptor, data);
    onConfirm({ min: values.min || descriptor.min, max: values.max || descriptor.max });
    close();
  };

  const close = () => {
    dispatch(UIAction.closeHistogramSelector());
  };

  if (!open) return null;

  return (
    <div className={classnames(classes.HistogramRangeSelector, { [classes.Selecting]: data.type })}>
      {loading && <div className={classes.LoaderContainer}>Loading histogram...</div>}
      {!loading && histogram[descriptor.id] && (
        <HistogramRangeSelector histogram={histogram[descriptor.id]} descriptor={descriptor} onChange={setData} data={data} />
      )}
      <div className="text-right mt-1">
        <Button variant="Danger" onClick={close}>
          Cancel
        </Button>
        <Button className="ml-2" variant="Success" onClick={confirm}>
          Ok
        </Button>
      </div>
    </div>
  );
};

export default HistogramRangeSelectorPanel;
