import React, { useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import TmapDescriptorForm from "./form";
import { isEmpty } from "../../../utils";

const TMAPDescriptorModal = ({ variant, size, className, label, descriptor, descriptors, edit, onSave, datasetId }) => {
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);
  const [validated, setValidated] = useState(null);

  const openModal = () => {
    setData(descriptor);
    setValidated(false);
    setOpen(true);
  };

  const closeModal = () => {
    setOpen(false);
    setData(null);
  };

  const changeHandler = (e) => {
    let { name, value } = e.target;
    let newData;
    if(name === "limits") {
      newData = { ...data, min_limit: value.min, max_limit: value.max, status: "PENDING" };
    } else {
      newData = { ...data, [name]: value || null, status: "PENDING" };
    }
    if (name === "descriptor_id") {
      let descriptor = descriptors.find((d) => d.id === parseInt(value));
      if (data.descriptor && descriptor.category !== data.descriptor.category) {
        newData = { id: data.id, descriptor_id: value, descriptor };
      } else {
        newData.descriptor = descriptor;
      }
    }

    setData(newData);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (e.target.checkValidity()) {
      let d = { ...data };
      if (data.type === "color" && isEmpty(data.min_limit)) {
        delete d.min_limit;
        delete d.min_value;
      }
      if (data.type === "color" && isEmpty(data.max_limit)) {
        delete d.max_limit;
        delete d.max_value;
      }
      onSave(d);
      closeModal();
    } else {
      setValidated(true);
    }
  };

  return (
    <>
      <Button variant={variant} onClick={openModal} size={size} className={className}>
        {label}
      </Button>
      <Modal show={open} onHide={closeModal}>
        <Form onSubmit={onSubmit} noValidate validated={validated}>
          <Modal.Header closeButton>
            <Modal.Title>Descriptor</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {open && (
              <TmapDescriptorForm descriptor={data} descriptors={descriptors} edit={edit} onChange={changeHandler} datasetId={datasetId} />
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="warning" type="button" onClick={closeModal}>
              Cancel
            </Button>
            <Button variant="primary" type="submit">
              Save
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default TMAPDescriptorModal;
