import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./index.module.css";

const RemoveFilterButton = ({ name, onClick }) => {
   return (
    <button className={classes.RemoveFilterButton} name={name} onClick={onClick}>
        <FontAwesomeIcon icon="times" />
      </button>
  );
};

export default RemoveFilterButton;

