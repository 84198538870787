import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import classes from "./index.module.css";
import Descriptors from "../Descriptors";
import Smiles from "../Smiles";
import NearestNeighbors from "../NearestNeighbors";
import TabLinks from "../TabLinks";

const Selected = () => {

  const data = useSelector(state => {
    if(!state.selection && state.hover.point && !state.molecule[state.hover.point.index])
      return { loading: true };
    return state.molecule[(state.selection || state.hover.point)?.index]
  });
  const isSelected = useSelector(state =>  !!state.selection);
  const nnHits = useSelector(state => data ? (state.nnSearch || {})[data.index] : undefined);

  const [activeTab, setActiveTab] = useState("smiles");
  const activateTab = useCallback(tab => setActiveTab(tab), []);
  const [stickyDescriptors, setStickyDescriptors] = useState({});

  const updateStyckeDescriptors = descriptor => {
    let newStickyDescriptors = {...stickyDescriptors};
    if(newStickyDescriptors[descriptor]) delete newStickyDescriptors[descriptor];
    else newStickyDescriptors[descriptor] = true;
    setStickyDescriptors(newStickyDescriptors);
  }

  const sticky = data && data.descriptors && data.descriptors.filter(d => stickyDescriptors[d.key]);
    
  return (
    <div className={classes.Selected}>
      <TabLinks active={activeTab} activateTab={activateTab} />
      {activeTab === "smiles" && <Smiles data={data} isSelected={isSelected} enableCopySmiles={isSelected} sticky={sticky} />}
      {activeTab === "descriptors" && <Descriptors selected data={data} sticky={stickyDescriptors} onStickyToggle={updateStyckeDescriptors} />}
      {activeTab === "nearest-neighbors" && <NearestNeighbors hits={nnHits} />}
    </div>
  );
};

export default Selected;
