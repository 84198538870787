import React from "react";
import classnames from "classnames";
import classes from "./index.module.css";
import { Action as JsmeAction } from "../../store/jsme";
import { useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SmilesInput = ({ className, onChange, value, name, position, ...props }) => {
  
  const dispatch = useDispatch();

  const onEditorConfirm = smiles => {
    onChange({ target: { name, value: smiles } });
  }
  
  const openJsmeEditor = () => dispatch(JsmeAction.openEditor(value, onEditorConfirm, position));

  return (
    <div className={classnames(classes.SmilesInput, className)}>
      <input className={classes.Input} name={name} type="text" value={value || ""} onChange={onChange} {...props} />
      <button type="button" onClick={openJsmeEditor}>
        <FontAwesomeIcon icon="pen" />
      </button>
    </div>
  );
};

export default SmilesInput;
