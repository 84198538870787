import React from "react";
import classes from "./index.module.css";
import classnames from "classnames";

const Descriptors = ({ data, selected, sticky, onStickyToggle }) => {
  const onStickyChanged = (e) => {
    onStickyToggle(e.target.name);
  };

  const disabled = sticky ? Object.keys(sticky).length > 2 : true;

  return (
    <div className={classnames(classes.Descriptors, { [classes.Selected]: selected })}>
      {!data && (
        <div className={classes.EmptyContainer}>
          <label className={classes.EmptyLabel}>No Selection</label>
        </div>
      )}
      {data && data.loading && (
        <div className={classes.EmptyContainer}>
          <label className={classes.EmptyLabel}>Loading...</label>
        </div>
      )}
      {data &&
        !data.loading &&
        data.descriptors &&
        data.descriptors.map((descriptor) => (
          <div key={descriptor.key} className={classes.LabelContainer}>
            {sticky && (
              <input
                type="checkbox"
                name={descriptor.key}
                checked={!!sticky[descriptor.key]}
                onChange={onStickyChanged}
                disabled={!sticky[descriptor.key] && disabled}
              />
            )}
            <label className={classnames(classes.LabelTitle, "ml-1 mb-0")} title={descriptor.title}>
              {descriptor.title}
            </label>
            <label className="mb-0">:</label>
            <label className={classnames(classes.LabelInfo, "mb-0")}>{descriptor.value}</label>
          </div>
        ))}
    </div>
  );
};

export default Descriptors;
