import SmilesDrawer from "smiles-drawer";

const smilesDrawer = new SmilesDrawer.Drawer({
  width: 250,
  height: 250,
  experimental: true
});

const mqnSmilesDrawer = new SmilesDrawer.Drawer({
  width: 200,
  height: 180,
  experimental: true
});

export const drawSmiles = (smiles, canvas, type) => {
  SmilesDrawer.parse(smiles, tree => { (type === "Mqn" ? mqnSmilesDrawer : smilesDrawer).draw(tree, canvas, "dark", false); });
}
