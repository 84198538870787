import React from "react";
import { useSelector } from "react-redux";
import classes from "./index.module.css";

const TMAPInfo = () => {
  const info = useSelector((state) => state.faerun && state.faerun && state.faerun.tmapInfo);
  if (!info) return null;

  return (
    <div className={classes.TMAPInfo}>
      <h2>TMAP Info</h2>
      <div className={classes.Label}>Name</div>
      <div className={classes.Info}>{info.name}</div>
      <div className={classes.Label}>Dataset</div>
      <div className={classes.Info}>{info.dataset}</div>
    </div>
  );
};

export default TMAPInfo;
