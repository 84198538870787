import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classes from "./index.module.css";
import { Form, Card, InputGroup, FormControl, Alert } from "react-bootstrap";
import { Action as AuthAction } from "../../store/auth";
import { Request } from "../../store/ui";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const Login = () => {
  const [data, setData] = useState({ username: "", password: "" });
  const [validated, setValidated] = useState(false);

  const loading = useSelector((state) => state.ui.loading[Request.AUTH_LOGIN]);
  const error = useSelector((state) => state.ui.error[Request.AUTH_LOGIN]);
  const dispatch = useDispatch();

  const changeHandler = ({ target }) => {
    const { name, value } = target;
    setData({ ...data, [name]: value });
  };

  const submit = (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      dispatch(AuthAction.login(data));
    }
  };

  return (
    <div className={classes.Login}>
      <div className={classes.FormContainer}>
        <Card>
          <Card.Header>
            <FontAwesomeIcon icon="user-lock" />
            <span className="ml-3">LOGIN</span>
          </Card.Header>
          <Card.Body>
            <Form noValidate onSubmit={submit} validated={validated}>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text className="input-group-text">
                    <FontAwesomeIcon icon="user" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type="text" placeholder="Username" name="username" value={data.username} required onChange={changeHandler} />
                <Form.Control.Feedback type="invalid">Please enter username</Form.Control.Feedback>
              </InputGroup>
              <InputGroup className="mb-3">
                <InputGroup.Prepend>
                  <InputGroup.Text className="input-group-text">
                    <FontAwesomeIcon icon="lock" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl type="password" placeholder="Password" name="password" required value={data.password} onChange={changeHandler} />
                <Form.Control.Feedback type="invalid">Please enter password</Form.Control.Feedback>
              </InputGroup>
              <button type="submit" className="btn btn-outline-primary btn-block" disabled={loading}>
                Login
              </button>
              <div className="text-right my-3">
                <Link to="/forgot-password">Forgot your password?</Link>
              </div>
              <div className="text-right my-3">
                <Link to="/signup">Signup</Link>
              </div>
              {error && (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              )}
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default Login;
