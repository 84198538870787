import React from "react";
import { Badge } from "react-bootstrap";

const BADGES_STATES = {
  done: { variant: "success", text: "Done" },
  error: { variant: "danger", text: "Error" },
  pending: { variant: "warning", text: "Pending" },
  cancelled: { variant: "warning", text: "Cancelled" },
  processing: { variant: "info", text: "Processing" },
};

const StateBadge = ({ state }) => {
  state = state.split("_")[0];
  return (
    <Badge variant={BADGES_STATES[state].variant}>
      {BADGES_STATES[state].text}
    </Badge>
  );
};

export default StateBadge;
