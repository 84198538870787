import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ChangePassword from ".";

const ChangePasswordModal = ({ variant, className, label }) => {

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant={variant} onClick={() => setOpen(true)} className={className}>
        {label}
      </Button>

      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Change password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ChangePassword />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default ChangePasswordModal;
