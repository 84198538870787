import React from "react";
import classes from "./limit.module.css";

const TMAPDescriptorLimit = ({ descriptor, type }) => {
  const limit = descriptor[`${type}_limit`];
  const value = descriptor[`${type}_value`];
  if (limit !== undefined && limit !== null && limit !== "") {
    if (descriptor.type === "color") {
      return (
        <div className="d-flex align-items-center">
          {type === "max" && <span className="ml-2">···</span>}
          <div className={classes.ColorLimit} style={{ backgroundColor: `${value || "#FF0000"}` }}></div>
          {type === "max" && <span>for values &gt; {limit} </span>}
          {type === "min" && <span className="mr-2">for values &lt; {limit} ···</span>}
        </div>
      );
    }

    return (
      <>
        {type === "max" && <span className="mx-2">···</span>}
          {value}
          &nbsp;
          {type === "max" && <span>for values &gt; {limit} </span>}
          {type === "min" && <span className="mr-2">for values &lt; {limit} ···</span>}
      </>
    );
  }
  return null;
};

export default TMAPDescriptorLimit;
