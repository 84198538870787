import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Action as FaerunAction } from "../../../store/faerun";
import { Action as CartAction } from "../../../store/cart";
import classes from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SeriesTitle = ({ data }) => {

  const seriesState = useSelector(state => state.faerun.seriesState);
  const filterSearchData = useSelector(state => state.filterSearch.data);
  const nnSearchData = useSelector(state => state.nnSearch);
  const scrollSelection = useSelector(state => state.scrollSelection);
  const dispatch = useDispatch();

  const getSeriesStateIndex = name => (name === "descriptors" ? seriesState[name].color : seriesState[name]);

  const getFilterName = () =>  {
    if (filterSearchData) return "Filter (" + filterSearchData.length + " Results)";
    return "Filter";
  }

  const getNNSearchName = () => {
    if (nnSearchData) return "NN Search (" + Object.keys(nnSearchData).length + " Results)"
     return "NN Search";
  }

  const getScrollSelection = () => {
    if (scrollSelection.selection.length) return "Scroll selection (" + scrollSelection.selection.length + " Results)"
     return "Scroll selection";
  }

  const getSectionTitle = s => {
    if(s.name === "filter") return getFilterName();
    if(s.name === "nn_search") return getNNSearchName();
    if(s.name === "scroll_selection") return getScrollSelection();
    return s.legend_title[getSeriesStateIndex(s.name)]
  }

  const toggleLegendSection = ({ currentTarget }) => {
    const name = currentTarget.name;

    const isVisible = dispatch(FaerunAction.toggleLegendSectionVisibility(name));

    if (isVisible) {
      currentTarget.style.opacity = 0.5;
    } else {
      currentTarget.style.opacity = 1.0;
    }
  }

  const addToCart = ({ currentTarget }) => {
    if(currentTarget.name === "filter") dispatch(CartAction.addFilterToCart());
    else if(currentTarget.name === "nn_search") dispatch(CartAction.addNNToCart());
    else if(currentTarget.name === "scroll_selection") dispatch(CartAction.addScrollSelectionToCart());
  }

  const showCartControl = data.name !== "descriptors"

  return (
    <div className={classes.SeriesTitle}>
      <h3>{getSectionTitle(data)}</h3>
      <div className={classes.Controls}>
        <button name={data.name} className={classes.AddToCart} onClick={toggleLegendSection}>
          <FontAwesomeIcon icon="eye" />
        </button>
        {showCartControl && (
          <button name={data.name} onClick={addToCart} className={classes.AddToCart}>
          <FontAwesomeIcon icon="plus" />
          <FontAwesomeIcon icon="shopping-cart" />
        </button>)}
      </div>
    </div>
  );
};

export default SeriesTitle;
