import React, { useState } from "react";
import classes from "./index.module.css";
import Button from "../Button";
import Select from "../Select";
import SmilesInput from "../SmilesInput";

const methods = {
  NN_ECfp4: {
    scoringmethod: "TANIMOTO",
    fp: "ECfp4",
    method: "Sim",
  },

  NN_Xfp: {
    scoringmethod: "CBD",
    fp: "Xfp",
    method: "Sim",
  },
  NN_MQN: {
    scoringmethod: "CBD",
    fp: "MQN",
    method: "Sim",
  },
  NNML_ECfp4: {
    scoringmethod: "TANIMOTO",
    fp: "ECfp4",
    method: "SimPlusNaiveBayes",
  },
  NNML_Xfp: {
    scoringmethod: "CBD",
    fp: "Xfp",
    method: "SimPlusNaiveBayes",
  },
  NNML_MQN: {
    scoringmethod: "CBD",
    fp: "MQN",
    method: "SimPlusNaiveBayes",
  },
  ML_ECfp4: {
    scoringmethod: "TANIMOTO",
    fp: "ECfp4",
    method: "NaiveBayes",
  },
  DNN_ECfp4: {
    scoringmethod: "TANIMOTO",
    fp: "ECfp4",
    method: "DNN",
  },
};

const Ppb2Search = () => {
  const [data, setData] = useState({ smi: "", selected: "NN_ECfp4", scoringmethod: "TANIMOTO", fp: "ECfp4", method: "Sim" });

  const onChangeHandler = ({ target }) => {
    const { name, value } = target;
    if (name === "method") {
      setData({ ...data, selected: value, ...methods[value] });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const submit = (e) => {
    if (!e.target.checkValidity()) {
      e.preventDefault();
    }
  };

  return (
    <div className={classes.Ppb2Search}>
      <div className={classes.Title}>PPB2 Search</div>
      <Select onChange={onChangeHandler} value={data.selected} name="method">
        <optgroup label="Nearest neighbor search">
          <option value="NN_ECfp4">Extended Connectivity fingerprint ECfp4</option>
          <option value="NN_Xfp">Shape and Pharmacophore fingerprint Xfp</option>
          <option value="NN_MQN">Molecular Quantum Numbers MQN</option>
        </optgroup>
        <optgroup label="ECfp4 Naive Bayes Machine Learning model produced on the fly with 2000 nearest neighbors">
          <option value="NNML_ECfp4">Extended Connectivity fingerprint ECfp4</option>
          <option value="NNML_Xfp">Shape and Pharmacophore fingerprint Xfp</option>
          <option value="NNML_MQN">Molecular Quantum Numbers MQN</option>
        </optgroup>
        <optgroup label="Naive Bayes machine learning model with entire dataset">
          <option value="ML_ECfp4">Extended Connectivity fingerprint ECfp4</option>
        </optgroup>
        <optgroup label="Deep Neural Network model with entire dataset">
          <option value="DNN_ECfp4">Extended Connectivity fingerprint ECfp4</option>
        </optgroup>
      </Select>
      <form name="theform" target="_blank" noValidate onSubmit={submit} action={process.env.REACT_APP_PPB2_URL} method="POST">
        <SmilesInput name="smi" className="w-100 my-1" required value={data.smi} onChange={onChangeHandler} placeholder="Smiles string" position="Left" />
        <input type="hidden" name="scoringmethod" value={data.scoringmethod} />
        <input type="hidden" name="method" value={data.method} />
        <input type="hidden" name="fp" value={data.fp} />
        <Button block>Search</Button>
      </form>
    </div>
  );
};

export default Ppb2Search;
