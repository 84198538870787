export const isAdmin = user => user && user.role === "ADMIN";

export const Permissions = {
  VIEW_DATASETS: "VIEW_DATASETS",
  EDIT_DATASETS: "EDIT_DATASETS",
  VIEW_TMAPS: "VIEW_TMAPS",
  EDIT_TMAPS: "EDIT_TMAPS",
}

export const mapPermissions = user => {
  const permissions = Object.keys(Permissions).reduce((acc, current) => ({...acc, [current]: false}), {});
  if(user) user.permissions.forEach(p => permissions[p] = true);
  return permissions;
}

export const canViewDatasets = user => hasPermission(user, Permissions.VIEW_DATASETS);
export const canEditDatasets = user => hasPermission(user, Permissions.EDIT_DATASETS);
export const canViewTmaps = user => hasPermission(user, Permissions.VIEW_TMAPS);
export const canEditTmaps = user => hasPermission(user, Permissions.EDIT_TMAPS);

const hasPermission = (user, permission) => {
  if(isAdmin(user)) return true;

  return user && user.permissions && user.permissions[permission];
};