import React from "react";
import classnames from "classnames";
import classes from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavigationControls = ({ prev, next, remove, label }) => {
  return (
    <div className={classes.NavigationControls}>
      <button className={classnames(classes.Navigation, classes.Prev)} onClick={prev}>
        <FontAwesomeIcon icon="angle-left" />
      </button>
      {label && <span className={classes.Label}>{label}</span>}
      <button className={classnames(classes.Navigation, classes.Next)} onClick={next}>
        <FontAwesomeIcon icon="angle-right" />
      </button>
      <button className={classnames(classes.Navigation, classes.Delete)} onClick={remove}>
        <FontAwesomeIcon icon="times" />
      </button>
    </div>
  );
};

export default NavigationControls;
