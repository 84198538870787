import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Action as UsersAction, Helpers as UserHelpers } from "../../store/users";
import UserForm from "./form";

const CreateUser = ({ variant, className, label }) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});

  const close = () => setOpen(false);

  const openModal = () => {
    setUser({ username: "", name: "", role: "USER", permissions: UserHelpers.mapPermissions() });
    setOpen(true);
  }

  const onChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };

  const dispatch = useDispatch();

  const submit = async (e) => {
    const response = await dispatch(UsersAction.createUser(user));
    if (response) close();
  };

  return (
    <>
      <Button
        variant={variant}
        onClick={openModal}
        className={className}
      >
        {label}
      </Button>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Create a new user</Modal.Title>
        </Modal.Header>
        <UserForm user={user} onChange={onChange} onSubmit={submit} onCancel={close} />
      </Modal>
    </>
  );
};

export default CreateUser;
