import React, { useState } from "react";
import classnames from "classnames";
import { Jsme } from "jsme-react";
import "./index.css";
import { useDispatch, useSelector } from "react-redux";
import { Action as JsmeAction } from "../../store/jsme";
import Button from "../Button";

const JsmeEditor = () => {
  const { open, onConfirm, smiles, position } = useSelector(state => state.jsme);
  const [data, setData] = useState();
  const dispatch = useDispatch()

  const confirm = () => {
    onConfirm(data);
    dispatch(JsmeAction.closeEditor());
  }

  const close = () => dispatch(JsmeAction.closeEditor());

  if(!open) return null;

  return (
    <div className={classnames("JsmeEditor", position)}>
      <div className="JsmeEditorContainer">
       {smiles && <Jsme height="312px" smiles={smiles} width="338px" options="oldlook,star" src="/jsme/jsme.nocache.js" onChange={setData} />}
       {!smiles && <Jsme height="312px" width="338px" options="oldlook" src="/jsme/jsme.nocache.js" onChange={setData} />}
      </div>
      <div className="text-right mt-1">
        <Button variant="Danger" onClick={close}>Cancel</Button>
        <Button className="ml-2" variant="Success" onClick={confirm}>Ok</Button>
      </div>
    </div>
  );
};

export default JsmeEditor;
