import { useDispatch } from "react-redux";
import { Action as FaerunAction } from "../../store/faerun";
import { Action as CartAction } from "../../store/cart";
import { Action as ScrollSelectionAction } from "../../store/scrollSelection";
import React, { useEffect, useRef } from "react";
import { useParams, useLocation } from "react-router";
import classes from "./index.module.css";
import HoverIndicator from "../HoverIndicator";
import CartIndicators from "../CartIndicators";

const Faerun = ({ isPublic }) => {

  const lore = useRef(null);

  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(FaerunAction.stopRendering());
    }
  }, [dispatch]);

  let params= useParams();
  let query = new URLSearchParams(location.search);  
  let data = isPublic ? query.get("token") : params.id;

  useEffect(() => { dispatch(FaerunAction.fetchData(data, isPublic)); }, [dispatch, data, isPublic]);

  const mouseUpHandler = () => {
    if(!dragging) dispatch(FaerunAction.updateSelection());
  }

  const addSelectionToCart = () => dispatch(CartAction.addSelectionToCart());

  const mouseWheelHandler = e => {
    if(e.ctrlKey) {
      dispatch(ScrollSelectionAction.updateScrollSelection(e.deltaY));  
    }
  }
  
  return (
    <>
      <HoverIndicator />
      <canvas
        className={classes.Lore}
        ref={lore} id="lore"
        onMouseDown={clearDragging}
        onMouseUp={mouseUpHandler}
        onMouseMove={setDragging}
        onDoubleClick={addSelectionToCart}
        onWheel={mouseWheelHandler}
      ></canvas>
      <CartIndicators />
    </>
  );
};

let dragging = false;

const clearDragging = () => {dragging = false;}
  
const setDragging = () => { dragging = true; }

export default Faerun;
