import React, { useState } from "react";
import { Col, Row, Form, Modal, Button as RbButton } from "react-bootstrap";
import { Action as FaerunAction } from "../../store/faerun";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";

const TakeSnapshot = () => {

  const lore = useSelector(state => state.faerun.lore);
  const scatterMeta = useSelector(state => state.faerun.scatterMeta);
  const scatterState = useSelector(state => state.faerun.seriesState);
  const treeMeta = useSelector(state => (state.faerun.data || {}).tree_meta);
  const backgroundColor = useSelector(state => (state.faerun.clear_color));
  const [open, setOpen] = useState(false);
  const [working, setWorking] = useState(false);
  const [status, setStatus] = useState("");
  const [legend, setLegend] = useState(true);
  const [descriptors, setDescriptors] = useState([]);
  const dispatch = useDispatch();

  const onClickHandler = async () => {
    const selectedDescriptors = descriptors.filter(d => d.selected).sort((a,b) => a.current-b.current);
    if(!selectedDescriptors.length) return;
    setWorking(true);
    setStatus("Processing...");

    let canvas = lore.canvas;
    let zoom = lore.controls.getZoom();
    let height = canvas.height * 2;
    let width = canvas.width * 2;

    canvas.style.width = width + "px";
    canvas.style.height = height + "px";
    lore.controls.setZoom(zoom * 2);

    let current = scatterState.descriptors.color;
    for (const [i, d] of selectedDescriptors.entries()) {
      let descriptorName = scatterMeta[3].series_title[d.index].title;
      if(i===0 && d.current) {
        setStatus(`Generating image for ${descriptorName} (${i+1} / ${selectedDescriptors.length})`);
        await takeSnapshot(lore.canvas, scatterMeta[3], d.index, treeMeta[0].name.replace("_", " "), legend, backgroundColor);
      } else {
        await takeDescriptorSnapshot(d, descriptorName, selectedDescriptors, i);
      }
    }
  
    setStatus("Restoring tree");
    if(!selectedDescriptors[selectedDescriptors.length-1].current) {
      await dispatch(FaerunAction.changeSeries("color", current));
    }

    canvas.style.width = "100%";
    canvas.style.height = "100%";
    lore.controls.setZoom(zoom);

    setStatus("");
    setWorking(false);
    closeModal();
  }

  const takeDescriptorSnapshot = async (d, descriptorName, selectedDescriptors, i) =>{
    setStatus(`Fetching descriptor data for ${descriptorName} (${i+1} / ${selectedDescriptors.length})`);
    await dispatch(FaerunAction.changeSeries("color", d.index));
    setStatus(`Generating image for ${descriptorName} (${i+1} / ${selectedDescriptors.length})`);
    await takeSnapshot(lore.canvas, scatterMeta[3], d.index, treeMeta[0].name.replace("_", " "), legend, backgroundColor);
  }

  const openModal = () => {
    setDescriptors(scatterMeta[3].series_title.map((d, i) => ({
      ...d,
      selected: i === scatterState.descriptors.color,
      current:  i === scatterState.descriptors.color ? 1 : 0,
      index: i,
    })).filter(s => s.type === "color"));
    setOpen(true);
  }

  const toggleLegend = () => setLegend(!legend);

  const toggleDescriptor = e => {
    const id = parseInt(e.target.name);
    setDescriptors(descriptors.map(d => (d.id === id ? {...d, selected: !d.selected } : d)));
  }

  const closeModal = () => setOpen(false);
  
  return (
    <>
    <RbButton variant="link" onClick={openModal}>
      <FontAwesomeIcon icon="camera" />
    </RbButton>
    <Modal show={open} onHide={closeModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Take snapshot</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <legend className="mt-1">Legend</legend>
            <Form.Group>
              <Row>
                <Col sm={4}>
                  <label className="my-2">
                    <input type="checkbox" checked={legend} onChange={toggleLegend} />
                    <span className="ml-2">Include legend</span>
                  </label>
                </Col>
              </Row>
            </Form.Group>

            <legend className="mt-1">Descriptors</legend>
            <Form.Group>
              <Row>
                {descriptors.map((descriptor) => (
                  <Col sm={4} key={descriptor.id}>
                    <label className="my-2">
                      <input type="checkbox" name={descriptor.id} checked={descriptor.selected} onChange={toggleDescriptor} />
                      <span className="ml-2">{descriptor.title}</span>
                    </label>
                  </Col>
                ))}
              </Row>
            </Form.Group>
            <span>{status && `Status: ${status}`}</span>
          </Modal.Body>
          <Modal.Footer>
          <RbButton variant="warning" type="button" onClick={closeModal}>
            Cancel
          </RbButton>
          <RbButton variant="primary" type="button" onClick={onClickHandler} disabled={working}>
            OK
          </RbButton>
        </Modal.Footer>
        </Modal>
    </>
  );
};

export default TakeSnapshot;

const takeSnapshot = async (canvas, descriptors, selectedDescriptorIndex, tmapName, legend, backgroundColor) => {

  return new Promise((resolve) => {
    setTimeout(async () => {
      let descriptorName = descriptors.series_title[selectedDescriptorIndex].title;
      if(!legend) {
        await saveImage(canvas, `${tmapName} - ${descriptorName}.png`);
        resolve();
        return;
      }
      let resultCanvas = document.createElement("canvas");
      const legendCanvas = getLegend(descriptors, selectedDescriptorIndex, tmapName, canvas.width);
      resultCanvas.width = canvas.width;
      resultCanvas.height = canvas.height + legendCanvas.height;
      let ctx = resultCanvas.getContext("2d");
      ctx.fillStyle = backgroundColor;
      ctx.fillRect(0, 0, resultCanvas.width, resultCanvas.height);
      ctx.drawImage(canvas, 0, 0);
      ctx.drawImage(legendCanvas, 0, canvas.height);

      await saveImage(resultCanvas, `${tmapName} - ${descriptorName}.png`);
      resolve();
    }, 2000);
  });
}

const saveImage = (canvas, name) => {
  return new Promise((resolve) => {
    canvas.toBlob(blob => {
      let a = document.createElement("a");
      a.href = URL.createObjectURL(blob);
      a.setAttribute("download", name);
      a.click();
      resolve();
    });
  });
}

const getLegend = (descriptors, selectedDescriptorIndex, tmapName, width) => {
  let isRange = descriptors.is_range[selectedDescriptorIndex];
  let colors = descriptors.legend[selectedDescriptorIndex];
  let min = descriptors.min_legend_label[selectedDescriptorIndex];
  let max = descriptors.max_legend_label[selectedDescriptorIndex];
  let descriptorName = descriptors.series_title[selectedDescriptorIndex].title;

  let canvas = document.createElement("canvas");
  canvas.height = (isRange ? colors.length : colors.length*40) + 110;
  canvas.width = width;
  let ctx = canvas.getContext("2d");

  ctx.fillStyle = "white";
  ctx.font = "35px Arial";
  ctx.fillText(`TMAP: ${tmapName}`, 10, 40);
  ctx.font = "30px Arial";
  ctx.fillText(`Descriptor: ${descriptorName}`, 10, 80);

  let current = 100;

  if(isRange) {
    colors.forEach(c => {
      ctx.fillStyle = `rgb(${c[0][0]*255}, ${c[0][1]*255}, ${c[0][2]*255})`;
      ctx.fillRect(10, current++, 30, 1);
    });

    ctx.fillStyle = "white";
    ctx.font = "25px Arial";
    ctx.fillText(max, 50, 120);
    ctx.fillText(min, 50, current-1);
  } else {
    colors.forEach(c => {
      ctx.fillStyle = `rgb(${c[0][0]*255}, ${c[0][1]*255}, ${c[0][2]*255})`;
      ctx.fillRect(10, current,25, 25);
      ctx.fillStyle = "white";
      ctx.font = "25px Arial";
      ctx.fillText(c[1], 45, current+20);
      current+=40;
    });
  }
  return canvas;
}