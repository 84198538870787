import React from "react";
import { useSelector } from "react-redux";
import classes from "./index.module.css";

const HoverIndicator = () => {

  const data = useSelector(state => state.hover.indicator);
  if(!data) return null;
  
  return <div className={classes.HoverIndicator} style={data.style} />;
}

export default HoverIndicator;
