import { toast } from "react-toastify";
import { Request, Action as UIAction } from "../ui";
import * as Api from "./api"; 

export const Type = {
  SET_USERS: "USERS_SET_USERS",
  ADD_USER: "USERS_ADD_USER",
  UPDATE_USER: "USERS_UPDATE_USER",
  REMOVE_USER: "USERS_REMOVE_USER",
};

export const filterUsers = () => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.USERS_FETCH));
  dispatch(UIAction.clearError(Request.USERS_FETCH));

  try {
    const response = await Api.filter();
    dispatch(set(response));
  } catch (error) {
    dispatch(UIAction.setError(Request.USERS_FETCH, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.USERS_FETCH));
  }
};

export const updateUser = user => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.USER_SAVE));
    dispatch(UIAction.clearError(Request.USER_SAVE));

    let response = await Api.update(user);
    toast.success("User updated");
    dispatch(update(response));
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.USER_SAVE, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.USER_SAVE));
  }
}

export const createUser = data => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.USER_SAVE));
    dispatch(UIAction.clearError(Request.USER_SAVE));

    let response = await Api.create(data);
    toast.success("User created");
    dispatch(add(response));    
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.USER_SAVE, error.message));
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.USER_SAVE));
  }
}

export const removeUser = user => async dispatch => {
  try {
    dispatch(UIAction.setLoading(Request.USER_REMOVE));
    dispatch(UIAction.clearError(Request.USER_REMOVE));
    await Api.remove(user.id);
    toast.success("User removed");
    dispatch(remove(user));
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.USER_REMOVE, error.message));
    toast.error(error.message);
  } finally {
    dispatch(UIAction.clearLoading(Request.USER_REMOVE));
  }
}

export const enableDisableUser = user => async dispatch => {
  try {
    dispatch(UIAction.setLoading(Request.USER_ENABLE_DISABLE));
    dispatch(UIAction.clearError(Request.USER_ENABLE_DISABLE));
    const response = await Api.enableDisable(user.id);
    toast.success(`User ${response.enabled ? "enabled" : "disabled"}`);
    dispatch(update(response));
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.USER_ENABLE_DISABLE, error.message));
    toast.error(error.message);
  } finally {
    dispatch(UIAction.clearLoading(Request.USER_ENABLE_DISABLE));
  }
}

const remove = data => ({ type: Type.REMOVE_USER, data });

const set = data => ({ type: Type.SET_USERS, data });

const add = data => ({ type: Type.ADD_USER, data });

const update = data => ({ type: Type.UPDATE_USER, data });

