import React from "react";
import classnames from "classnames";

import classes from "./index.module.css";

const RangeSeriesLegend = ({ data, max, min, limits }) => {
  return (
    <div className={classes.LegendElementRange}>
      {limits && <span className={classes.Limit} style={{backgroundColor: limits.max_value}}></span>}
      {data.map((value, index) => (
        <div
          className={classes.ColorStripe}
          key={index}
          alt={value[1]}
          style={{
            backgroundColor: `rgba(${value[0][0] * 255}, ${value[0][1] * 255}, ${value[0][2] * 255}, ${value[0][3]}`,
            borderColor: `rgba(${value[0][0] * 255}, ${value[0][1] * 255}, ${value[0][2] * 255}, ${value[0][3]}`,
          }}
        />
      ))}
      {limits && <span className={classes.Limit} style={{backgroundColor: limits.min_value}}></span>}

      <div className={classnames(classes.LegendLabel, classes.Min)}>{limits ? <>{limits.min_limit}<br/>{min}</> : min}</div>
      <div className={classnames(classes.LegendLabel, classes.Max)}>{limits ? <>{max}<br/>{limits.max_limit}</> : max}</div>
    </div>
  );
};

export default RangeSeriesLegend;
