import React, { useState, useEffect, useCallback } from "react";
import { Form, Button, Alert, Row, Col, Modal } from "react-bootstrap";
import { Request } from "../../store/ui";
import { useSelector, useDispatch } from "react-redux";
import { Action as DatasetsAction } from "../../store/datasets";
import TMAPDescriptors from "./Descriptors";

const TMAPForm = ({ tmap, onChange, onSubmit, onCancel, loading }) => {

  const submitting = useSelector(state => state.ui.loading[Request.TMAP_SAVE]);
  const [datasets, setDatasets] = useState([]);
  const dispatch = useDispatch();

  const [validated, setValidated] = useState(false);

  const fetchDatasets = useCallback(async () => {
    if(!tmap.id) {
      const response = await dispatch(DatasetsAction.fetchDoneDatasets());
      setDatasets(response);
    }
  }, [dispatch, setDatasets, tmap.id]);

  useEffect(() => { fetchDatasets() }, [fetchDatasets]);

  const submit = (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      onSubmit();
    }
  };

  const change = ({ target }) => {
    const { name, value } = target;
    onChange(name, value);
  };

  return (
    <Form noValidate validated={validated} onSubmit={submit}>
      <legend>Basic data</legend>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control
          type="text"
          name="name"
          required
          value={tmap.name}
          onChange={change}
        />
        <Form.Control.Feedback type="invalid">
          Please enter valid name
        </Form.Control.Feedback>
      </Form.Group>
      {!tmap.id && !onCancel && (
        <>
          <legend>Dataset information</legend>
          <Form.Group>
            <Form.Label>Dataset</Form.Label>
            <Form.Control
              as="select"
              name="dataset_id"
              required
              onChange={change}
              value={tmap.dataset_id}
            >
              <option disabled value="">Select dataset</option>
              {datasets.map((dataset) => (
                <option key={dataset.id} value={dataset.id}>
                  {dataset.name}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">
              Please select dataset
            </Form.Control.Feedback>
          </Form.Group>
        </>
      )}

      <legend className="mt-5">TMAP creation options</legend>

      {tmap.id && (
        <Alert variant="warning">
          <strong>Caution: </strong>Modifying these fields require the
          recalculation of the entire TMAP.
        </Alert>
      )}

      <Row>
        <Col sm={4}>
          <Form.Group>
            <Form.Label>K</Form.Label>
            <Form.Control
              type="number"
              name="k"
              required
              value={tmap.k}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid k
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group>
            <Form.Label>KC</Form.Label>
            <Form.Control
              type="number"
              name="kc"
              required
              value={tmap.kc}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid kc
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group>
            <Form.Label>Node size</Form.Label>
            <Form.Control
              type="number"
              name="node_size"
              required
              value={tmap.node_size}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid node size
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <legend className="mt-5">Rendering options</legend>

      {tmap.id && (
        <Alert variant="warning">
          <strong>Caution: </strong>Modifying any of these fields requires the
          recalculation of the cache.
        </Alert>
      )}

      <Row>
        <Col sm={4}>
          <Form.Group>
            <Form.Label>Point size</Form.Label>
            <Form.Control
              type="number"
              name="point_size"
              required
              value={tmap.point_size}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid point size
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group>
            <Form.Label>Filter point size</Form.Label>
            <Form.Control
              type="number"
              name="filter_point_size"
              required
              value={tmap.filter_point_size}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid point size
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={4}>
          <Form.Group>
            <Form.Label>Scroll selection point size</Form.Label>
            <Form.Control
              type="number"
              name="scroll_selection_point_size"
              required
              value={tmap.scroll_selection_point_size}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please enter valid point size
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col sm={3}>
          <Form.Group>
            <Form.Label>Filter color</Form.Label>
            <Form.Control
              type="color"
              name="filter_color"
              required
              value={tmap.filter_color}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please select valid color
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Label>NN search color</Form.Label>
            <Form.Control
              type="color"
              name="nn_search_color"
              required
              value={tmap.nn_search_color}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please select valid color
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Label>Scroll selection color</Form.Label>
            <Form.Control
              type="color"
              name="scroll_selection_color"
              required
              value={tmap.scroll_selection_color}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please select valid color
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={3}>
          <Form.Group>
            <Form.Label>Background color</Form.Label>
            <Form.Control
              type="color"
              name="background_color"
              required
              value={tmap.background_color}
              onChange={change}
            />
            <Form.Control.Feedback type="invalid">
              Please select valid color
            </Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      <TMAPDescriptors tmap={tmap} onChange={onChange} />
      {!onCancel && (
      <Form.Group className="form-group mt-5">
        <Button type="submit" variant="primary" disabled={submitting}>
          Submit
        </Button>
      </Form.Group>
      )}
      {onCancel && (
        <Modal.Footer>
          <Button variant="warning" onClick={onCancel}>
            Back
          </Button>
          <Button variant="primary" type="submit" disabled={loading}>
            {loading ? 'Creating...' : 'Create'}
          </Button>
        </Modal.Footer>
      )}
    </Form>
  );
};

export default TMAPForm;
