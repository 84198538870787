import React, { useState } from "react";
import { useParams, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Action as FilterSearchAction } from "../../store/filterSearch";
import classes from "./index.module.css";
import CategoricalFilter from "./CategoricalFilter";
import NumericalFilter from "./NumericalFilter";
import SubstructureFilter from "./SubstructureFilter";
import SimilarityFilter from "./SimilarityFilter";
import { Request } from "../../store/ui/actions";
import LoadinSpinner from "../LoadingSpinner";
import Button from "../Button";
import Select from "../Select";

const FilterSearch = ({ isPublic }) => {

  const filters = useSelector(state => state.filterSearch.filters);
  const filtering = useSelector(state =>  state.ui.loading[Request.FILTER_DATA]);
  const dispatch = useDispatch();
  const location = useLocation();

  let params= useParams();
  let query = new URLSearchParams(location.search);  
  let data = isPublic ? query.get("token") : params.id;


  const [selectedFilter, setSelectedFilter] = useState("");
  const [selectedFilters, setFilters] = useState([]);
  const [filterValues, setFilterValues] = useState({});
  
  const filterChangeHandler = ({ target }) => setSelectedFilter(target.value);

  const isFilterAdded = id => selectedFilters.some(filter => filter.id === id);

  const addFilter = () => {
    if(selectedFilter && !isFilterAdded(selectedFilter)) {
      setFilters([...selectedFilters, filters[selectedFilter]]);
    }
  }

  const updateFilterValue = (filterId, value) => {
    setFilterValues({...filterValues, [filterId]: value});
  }

  const handleFilterClick = () => dispatch(FilterSearchAction.filterData(data, filterValues, isPublic));

  const removeFilter = ({ currentTarget }) =>  {
    const filterId = currentTarget.name;
    const newFilterValues = { ...filterValues };
    delete newFilterValues[filterId];
    setFilterValues(newFilterValues);
    setFilters(selectedFilters.filter(filter => filter.id !== filterId));
  }

  const clearFiltersHandler = () => {
    setFilterValues({});
    setFilters([]);
    dispatch(FilterSearchAction.clearFilterSearch());
  }

  return (
    <div className={classes.FilterSearch}>
      {filtering && <LoadinSpinner />}
      <div className={classes.FilterSearchWrapper}>
        <h2>Filter Search</h2>
        <Select onChange={filterChangeHandler} value={selectedFilter}>
          <option value="" hidden  />
          {filters && Object.keys(filters).map(key => (
            <option key={filters[key].id} className={classes.FilterSearchSelectorOption} value={filters[key].id} disabled={isFilterAdded(filters[key].id)} >
              {filters[key].title}
            </option>
          ))}
        </Select>
        <div className={classes.FilterSearchControls}>
          <Button variant="Success" className="my-1" block onClick={addFilter}>Add</Button>
          <Button variant="Danger" className="my-1 ml-1" block onClick={clearFiltersHandler}>Clear Filters</Button>
        </div>
        <div className={classes.SelectedFilterContainer}>
          
            {selectedFilters.map(filter => (
              <div className={classes.FilterContainer} key={filter.id}>
                {filter.category === "categorical" && <CategoricalFilter filter={filter} value={filterValues[filter.id]} onChange={updateFilterValue} onRemove={removeFilter} />}
                {filter.category === "numerical" && <NumericalFilter filter={filter} value={filterValues[filter.id]} onChange={updateFilterValue} onRemove={removeFilter} />}
                {filter.category === "substructure" && <SubstructureFilter filter={filter} value={filterValues[filter.category]} onChange={updateFilterValue} onRemove={removeFilter} />}
                {filter.category === "similarity" && <SimilarityFilter filter={filter} value={filterValues[filter.category]} onChange={updateFilterValue} onRemove={removeFilter} />}
              </div>
            ))}
        </div>
        <div>
          <Button className="my-1" block onClick={handleFilterClick} disabled={!selectedFilters.length || filtering}>Filter</Button>
        </div>
      </div>
    </div>
  );
};

export default FilterSearch;
