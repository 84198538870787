import {
  createStore, combineReducers, applyMiddleware, compose,
} from "redux";
import thunk from "redux-thunk";

// Reducers
import ui from "./ui";
import datasets from "./datasets";
import tmaps from "./tmaps";
import faerun from "./faerun";
import cart from "./cart";
import filterSearch from "./filterSearch";
import nnSearch from "./nnSearch";
import hover from "./hover";
import selection from "./selection";
import auth from "./auth";
import users from "./users";
import molecule from "./molecule";
import jsme from "./jsme";
import scrollSelection from "./scrollSelection";
import mqnSearch from "./mqnSearch";

const reducer = combineReducers({
  auth,
  datasets,
  tmaps,
  faerun,
  cart,
  filterSearch,
  jsme,
  molecule,
  mqnSearch,
  nnSearch,
  hover,
  scrollSelection,
  selection,
  ui,
  users
});

let composeEnhancers = inp => inp;
if (process.env.NODE_ENV === "development") {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

const store = createStore(reducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
