export const getColumnLogData = (log) => {
  let data = [];
  log.forEach((line) => {
    switch (line.type) {
      case "dbl_click":
        data.push("Added manually");
        break;
      case "scroll_selection":
        data.push(`Scroll selection`);
        break;
      case "nn_search":
        data.push(`NN search`);
        break;
      case "filter_search":
        data.push(`Filter search`);
        break;
      case "remove":
        data.push("Removed");
        break;
      default:
        data.push("Unknown action");
        break;
    }
  });
  return data;
};

export const getFileLogData = (log, filters, molecules, count) => {
  let data = [];
  log.forEach((line) => {
    switch (line.type) {
      case "dbl_click":
        data.push("Manually added " + molecules[line.item].smiles);
        break;
      case "scroll_selection":
        data.push(`Added ${line.addedItemsCount} items from scroll selection with base ${molecules[line.item].smiles}`);
        break;
      case "nn_search":
        data.push(`Added ${line.addedItemsCount} items from nn search with following filters:`);
        data.push("\tSmiles:");
        line.filter.data.forEach((fd) => data.push(`\t\t${fd}`));
        data.push(`\tMin similarity: ${line.filter.similarity}`);
        break;
      case "filter_search":
        data.push(`Added ${line.addedItemsCount} items from filter search with following filters:`);
        Object.keys(line.filter).forEach((key) => {
          let filter = line.filter[key];
          data.push(`\t${filters[key].title}:`);
          switch (filter.type) {
            case "substructure":
              data.push(`\t\tSmarts: ${filter.smarts}`);
              break;
            case "similarity":
              data.push(`\t\tSmiles: ${filter.smiles}`);
              data.push(`\t\tThreshold: ${filter.threshold}`);
              break;
            case "range":
              data.push(`\t\tMin: ${filter.min}`);
              data.push(`\t\tMax: ${filter.max}`);
              data.push(`\t\tInvert: ${!!filter.invert}`);
              break;
            default:
              if (filter.vals) {
                data.push(`\t\tSelected values: ${filter.vals.join(", ")}`);
              } else {
                data.push(`Unknown filter`);
              }
              break;
          }
        });
        break;
      case "remove":
        data.push("Removed item at position " + line.position);
        break;
      default:
        data.push("Unknown action " + line.type);
        break;
    }
  });
  data.push(`\nCart contains ${count} items.`);
  return data;
};

const getSmilesArray = (smiles, headers = false) => {
  if (smiles && smiles.length) {
    return [(headers ? "SMILES\n" : "") + smiles.join("\n")];
  }
  return [];
};


export const getSmilesArrayForExport = (data, log) => {
  if(!data.descriptors && !log) {
    return getSmilesArray(data.smiles, data.headers && data.headers.length);
  } else {
    if (data.smiles && data.smiles.length) {
      let fileData = [];
      if(data.headers) {
        fileData.push(data.headers.join(",") + (log ? ",Log" : ""));
      }
      for(let i = 0; i < data.smiles.length; i++) {
        let line = data.smiles[i] + (data.descriptors ? ("," + data.descriptors.map(d => d[i]).join(",")) : "") + (log ? ("," + log.log[log.items[i]]) : "");
        fileData.push(line);
      }
      return fileData;
    }
  }
  return [];
};