import { toast } from "react-toastify";
import { Request, Action as UIAction } from "../ui";
import * as Api from "./api"; 

export const Type = {
  SET_DATASETS: "DATASETS_SET_DATASETS",
  ADD_DATASET: "DATASETS_ADD_DATASET",
  UPDATE_DATASET: "DATASETS_UPDATE_DATASET",
  REMOVE_DATASET: "DATASETS_REMOVE_DATASET",
  UPDATE_PROGRESS: "DATASETS_UPDATE_PROGRESS",
};

export const filterDatasets = () => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.DATASETS_FETCH));
  dispatch(UIAction.clearError(Request.DATASETS_FETCH));

  try {
    const response = await Api.filter();
    dispatch(set(response));
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASETS_FETCH, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASETS_FETCH));
  }
};

export const fetchDoneDatasets = () => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.DATASETS_DONE));
  dispatch(UIAction.clearError(Request.DATASETS_DONE));

  try {
    return Api.fetchDone();
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASETS_DONE, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASETS_DONE));
  }
};

export const fetchDataset = id => async (dispatch, getState) => {
 
  dispatch(UIAction.clearError(Request.DATASET_FETCH));

  const { datasets } = getState();
  id = parseInt(id);
  if(datasets.find(dataset => dataset.id === id)) {
    return;
  }
  dispatch(UIAction.setLoading(Request.DATASET_FETCH));

  try {
    const response = await Api.get(id);
    dispatch(add(response));
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_FETCH, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_FETCH));
  }
};

export const updateDataset = dataset => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.DATASET_SAVE));
    dispatch(UIAction.clearError(Request.DATASET_SAVE));

    let response = await Api.update(dataset);
    dispatch(update(response));
    toast.success("Dataset updated");
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_SAVE, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_SAVE));
  }
}

export const createDataset = data => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.DATASET_SAVE));
    dispatch(UIAction.clearError(Request.DATASET_SAVE));

    let response = await Api.create(data);
    dispatch(add(response));
    toast.success("Dataset saved");
    
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_SAVE, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_SAVE));
  }
}

export const removeDataset = dataset => async dispatch => {
  try {
    dispatch(UIAction.setLoading(Request.DATASET_REMOVE));
    dispatch(UIAction.clearError(Request.DATASET_REMOVE));
    await Api.remove(dataset.id);
    dispatch(remove(dataset));
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_REMOVE, error.message));
    toast.error(error.message);
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_REMOVE));
  }
}

export const getPermissions = (id) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.DATASET_PERMISSIONS));
  dispatch(UIAction.clearError(Request.DATASET_PERMISSIONS));

  try {
    const response = await Api.permissions(id);
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_PERMISSIONS, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_PERMISSIONS));
  }
};

export const togglePermission = (dataset_id, user_id) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.DATASET_PERMISSION));
  dispatch(UIAction.clearError(Request.DATASET_PERMISSION));

  try {
    await Api.togglePermission(dataset_id, user_id);
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_PERMISSION, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_PERMISSION));
  }
};

export const stopProcessing = id => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.DATASET_PROCESSING));
    dispatch(UIAction.clearError(Request.DATASET_PROCESSING));

    let response = await Api.stopProcessing(id);
    dispatch(update(response));
    toast.success("Processing cancelled");
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_PROCESSING, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_PROCESSING));
  }
};

export const startProcessing = id => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.DATASET_PROCESSING));
    dispatch(UIAction.clearError(Request.DATASET_PROCESSING));

    let response = await Api.startProcessing(id);
    dispatch(update(response));
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_PROCESSING, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_PROCESSING));
  }
};

export const fetchProcessingLog = (id) => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.DATASET_PROCESSING_LOG));
    dispatch(UIAction.clearError(Request.DATASET_PROCESSING_LOG));
    let response =  await Api.fetchProcessingLog(id);
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_PROCESSING_LOG, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_PROCESSING_LOG));
  }
};

export const fetchDescriptors = (id) => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.DATASET_DESCRIPTORS));
    dispatch(UIAction.clearError(Request.DATASET_DESCRIPTORS));
    let response =  await Api.fetchDescriptors(id);
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_DESCRIPTORS, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_DESCRIPTORS));
  }
};

export const fetchDescriptorHistogram = (datasetId, descriptorId) => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.DATASET_DESCRIPTOR_HISTOGRAM));
    dispatch(UIAction.clearError(Request.DATASET_DESCRIPTOR_HISTOGRAM));
    let response = await Api.getDescriptorHistogram(datasetId, descriptorId);
    const img = await response.blob();
    return URL.createObjectURL(img);
  } catch (error) {
    dispatch(UIAction.setError(Request.DATASET_DESCRIPTOR_HISTOGRAM, error.message));
    toast.error(error.message);
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.DATASET_DESCRIPTOR_HISTOGRAM));
  }
};

const remove = data => ({ type: Type.REMOVE_DATASET, data });

const set = data => ({ type: Type.SET_DATASETS, data });

export const add = data => ({ type: Type.ADD_DATASET, data });

export const update = data => ({ type: Type.UPDATE_DATASET, data });

export const updateProgress = data => ({ type: Type.UPDATE_PROGRESS, data });

