import React from "react";
import classes from "./descriptorColormap.module.css";

const TmapDescriptorColormap = ({ descriptor, customValues }) => {
  if (!descriptor.colormap) return null;

  if (descriptor.descriptor.category === "numerical") {
    return (
      <div className={classes.ColormapContainer}>
        {descriptor.colormap !== "custom" && (
          <img alt={descriptor.colormap} className={classes.ColormapImage} src={`/colors/${descriptor.colormap}.jpg`} />
        )}
        {descriptor.colormap === "custom" && <div className={classes.ColormapImage} style={getNumericalColormap(customValues)}></div>}
      </div>
    );
  }

  if (descriptor.descriptor.category === "categorical") {
    return (
      <div className={classes.ColormapContainer}>
        <div
          className={classes.ColormapImage}
          style={getCategoricalColormap(
            descriptor.colormap === "custom"
              ? customValues
              : descriptor.descriptor.labels.map((l, i) => colors[descriptor.colormap][Math.min(i, colors[descriptor.colormap].length - 1)])
          )}
        ></div>
      </div>
    );
  }
};

export default TmapDescriptorColormap;

const getNumericalColormap = (customValues) => {
  const step = 100 / (customValues.length - 1);
  const colors = customValues.map((c, i) => `${c} ${(i * step).toFixed(2)}%`);
  const style = { background: `linear-gradient(90deg, ${colors.join(", ")})` };
  return style;
};

const getCategoricalColormap = (customValues) => {
  const step = 100 / customValues.length;
  const colors = customValues.map((c, i) => `${c} ${(i * step).toFixed(2)}%, ${c} ${((i + 1) * step).toFixed(2)}%`);
  const style = { background: `linear-gradient(90deg, ${colors.join(", ")})` };
  return style;
};

const colors = {
  Pastel1: ["#fbb4ae", "#b3cde3", "#ccebc5", "#decbe4", "#fed9a6", "#ffffcc", "#e5d8bd", "#fddaec", "#f2f2f2"],
  Dark2: ["#1b9e77", "#d95f02", "#7570b3", "#e7298a", "#66a61e", "#e6ab02", "#a6761d", "#666666"],
  Set1: ["#e41a1c", "#377eb8", "#4daf4a", "#984ea3", "#ff7f00", "#ffff33", "#a65628", "#f781bf", "#999999"],
  tab10: ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"],
};
