import React, { useEffect, useRef } from "react";
import classes from "./index.module.css";

const HistogramRangeSelector = ({ histogram, descriptor, onChange, data }) => {
  const mouseHandlerRef = useRef(null);

  useEffect(() => {
    if (descriptor) {
      const factor = (descriptor.max - descriptor.min) / 400;
      let newData = {};
      newData.min = ((descriptor.value.min || descriptor.min) - descriptor.min) / factor;
      newData.max = ((descriptor.value.max || descriptor.max) - descriptor.min) / factor;
      onChange(newData);
    } else {
      onChange({});
    }
  }, [descriptor, onChange]);

  const handleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    let type = e.target.dataset.type;
    if (type === "SELECTION") {
      let x = e.pageX - mouseHandlerRef.current.getBoundingClientRect().x;
      onChange({ type: e.target.dataset.type, min: x, max: x });
    } else {
      onChange({ ...data, type: e.target.dataset.type });
    }
  };

  const handleMouseUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onChange({ ...data, type: null });
  };

  const handleMouseMove = (e) => {
    if (data.type) {
      let rect = mouseHandlerRef.current.getBoundingClientRect();
      let x = Math.max(0, Math.min(e.pageX - rect.x, rect.width));
      if (data.type === "MIN" && x <= data.max) {
        onChange({ ...data, min: x });
      } else if (data.type === "MAX" && x >= data.min) {
        onChange({ ...data, max: x });
      } else if (data.type === "SELECTION") {
        if (x > data.max) onChange({ ...data, max: x, moving: "max" });
        else if (x < data.min) onChange({ ...data, min: x, moving: "min" });
        else onChange({ ...data, [data.moving]: x });
      }
    }
  };

  const values = calculateValues(descriptor, data);

  return (
    <div className={classes.HistogramContainer}>
      <div className={classes.ImageContainer}>
        <img alt="histogram" src={histogram} />
        <div
          className={classes.MouseHandler}
          ref={mouseHandlerRef}
          data-type="SELECTION"
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          <div className={classes.MinShade} style={{ width: data.min }}></div>

          <div className={classes.MinLimit} style={{ left: data.min }} data-type="MIN" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}></div>
          <div className={classes.Selection} style={{ left: data.min || 0, width: (data.max || 0) - (data.min || 0) }}></div>
          <div className={classes.MaxLimit} style={{ left: data.max }} data-type="MAX" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}></div>
          <div className={classes.MaxShade} style={{ left: data.max }}></div>
        </div>
      </div>
      <div className="small text-center my-1">
        {values.min} - {values.max}
      </div>
    </div>
  );
};

export default HistogramRangeSelector;

export const calculateValues = (descriptor, data) => {
  const factor = (descriptor.max - descriptor.min) / 400;
  const min = data.min ? descriptor.min + data.min * factor : descriptor.min;
  const max = data.max ? descriptor.min + data.max * factor : descriptor.max;
  return { min: descriptor.type === "int" ? Math.round(min) : min.toFixed(3), max: descriptor.type === "int" ? Math.round(max) : max.toFixed(3) };
};
