import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import classes from "./index.module.css";
import { Action as MqnSearchAction } from "../../store/mqnSearch";
import { Helpers as UserHelpers } from "../../store/users";
import Smiles from "../Selection/Smiles";
import TmapFromData from "../TmapFromData";
import NavigationControls from "../NavigationControls";
import Button from "../Button";
import Select from "../Select";
import LoadinSpinner from "../LoadingSpinner";
import { Request } from "../../store/ui/actions";
import SmilesInput from "../SmilesInput";
import ExportMqnSmilesModal from "../ExportMqnSmilesModal";

const types = [
  { name: "DISTANCE", label: "Distance" },
  { name: "NUM", label: "Number of results" },
];

const sort = [
  { name: "MQN", label: "MQN distance" },
  { name: "MHFP6", label: "MHFP6 Distance" },
]

const MqnSearch = () => {
  const [data, setData] = useState({ smiles: "", database: "", type: "", value: "", sort: "" });

  const mqnSearch = useSelector((state) => state.mqnSearch);
  const current = mqnSearch.current != null ? mqnSearch.items[mqnSearch.current] : undefined;
  const searching = useSelector((state) => state.ui.loading[Request.MQN_SEARCH] || state.ui.loading[Request.MQN_DATABASES]);
  const hasTmapFromResultsButton = useSelector((state) => !state.faerun.isPublic && UserHelpers.canEditTmaps(state.auth));
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(MqnSearchAction.databases());
  }, [dispatch]);

  const selectedPrev = () => dispatch(MqnSearchAction.prev());
  const selectedNext = () => dispatch(MqnSearchAction.next());
  const removeCurrent = () => dispatch(MqnSearchAction.removeCurrent());
  const removeAll = () => dispatch(MqnSearchAction.clearResults());

  const onChangeHandler = ({ target }) => {
    const { name, value } = target;
    setData({ ...data, [name]: value });
  };

  const submit = () => {
    if (data.type && data.database && data.smiles && data.value && data.sort) {
      dispatch(MqnSearchAction.search(data));
    }
  };

  const placeholder = types.find(t => t.name === data.type)?.label;

  return (
    <div className={classnames(classes.MqnSearch, { [classes.WithTmapFromResultsButton]: hasTmapFromResultsButton })}>
      {searching && <LoadinSpinner />}
      <div className="mb-1">
        <label className={classes.Title}>MQN search</label>
        <div className="d-flex w-100">
          <Select name="type" onChange={onChangeHandler} value={data.type} className="mr-1">
            <option disabled value="">
              Type
            </option>
            {types.map((m) => (
              <option key={m.name} value={m.name}>
                {m.label}
              </option>
            ))}
          </Select>
          <Select name="database" onChange={onChangeHandler} value={data.database}>
            <option disabled value="">
              Database
            </option>
            {mqnSearch?.databases?.map((m) => (
              <option key={m} value={m}>
                {m}
              </option>
            ))}
          </Select>
        </div>

        <SmilesInput className="w-100" name="smiles" placeholder="Smiles string" value={data.smiles} onChange={onChangeHandler} position="Left" />
        <input
          className={classnames("w-100 my-1", classes.Input)}
          type="number"
          name="value"
          placeholder={placeholder}
          value={data.value}
          onChange={onChangeHandler}
        />

        <Select name="sort" onChange={onChangeHandler} value={data.sort}>
            <option disabled value="">
              Sort by
            </option>
            {sort.map((m) => (
              <option key={m.name} value={m.name}>
                {m.label}
              </option>
            ))}
          </Select>

        <Button block size="Small" onClick={submit}>
          Search
        </Button>
      </div>
      <NavigationControls
        label={current ? `${mqnSearch.current + 1} / ${mqnSearch.items.length}` : ""}
        prev={selectedPrev}
        next={selectedNext}
        remove={removeCurrent}
      />
      <div className={classes.DistanceLabel}><span>MQN distance:</span><span>{current ? current.mqn : "-"}</span></div>
      <div className={classes.DistanceLabel}><span>MHFP6 distance:</span><span>{current ? current.mhfp6 : "-"}</span></div>
      <Smiles type="Mqn" data={current ? { smiles: current.smiles } : undefined} enableCopySmiles={!!current} />
      <div className={classes.ControlsBottom}>
        <ExportMqnSmilesModal size="Small" className={classes.MqnButton} label="Export smiles" />
        <Button size="Small" variant="Danger" className={classes.MqnButton} onClick={removeAll}>
          Remove All
        </Button>
        {hasTmapFromResultsButton && <TmapFromData data={{ smiles: mqnSearch.items.map(i => i.smiles) }} label="Create TMAP from results" />}
      </div>
    </div>
  );
};

export default MqnSearch;
