import { Request, Action as UIAction } from "../ui";
import { saveTextFileFromArray } from "../../utils/file";
import * as Helpers from "./helpers";

import * as Api from "./api";

export const Type = {
  SET_ITEMS: "MQN_SET_ITEMS",
  UPDATE_CURRENT: "MQN_UPDATE_CURRENT",
  REMOVE: "MQN_REMOVE",
  CLEAR: "MQN_CLEAR",
  SET_DATABASES: "MQN_SET_DATABASES"
};

export const search = data => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.MQN_SEARCH));
  dispatch(UIAction.clearError(Request.MQN_SEARCH));

  try {
    const response = await Api.search(data);
    dispatch(setItems(response));
  } catch (error) {
    dispatch(UIAction.setError(Request.MQN_SEARCH, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.MQN_SEARCH));
  }
};

export const databases = () => async (dispatch, getState) => {

  const databases = getState().mqnSearch.databases;
  const loading = getState().ui.loading[Request.MQN_DATABASES];

  if(loading || databases) return;

  dispatch(UIAction.setLoading(Request.MQN_DATABASES));
  dispatch(UIAction.clearError(Request.MQN_DATABASES));

  try {
    const response = await Api.databases();
    dispatch(setDatabases(response));
  } catch (error) {
    dispatch(UIAction.setError(Request.MQN_DATABASES, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.MQN_DATABASES));
  }
};

export const removeCurrent = () =>  (dispatch, getState) => {
  const { items, current } = getState().mqnSearch;
  if(current !== null) {
    // eslint-disable-next-line eqeqeq
    const newItems = items.filter((_, index) => index != current);
    const index = current >= newItems.length - 1 ? newItems.length - 1 : current;
    const newCurrent = index > -1 ? index : null;
    dispatch(remove(newItems, newCurrent));
  }
}


export const next = () => (dispatch, getState) => {
  const { mqnSearch } = getState();

  const current = mqnSearch.current || 0;
  const index = current >= mqnSearch.items.length - 1 ? 0 : current + 1;
  dispatch(updateCurrent(index));
};

export const prev = () => (dispatch, getState) => {
  const { mqnSearch } = getState();

  const current = mqnSearch.current || 0;
  const index = current <= 0 ? mqnSearch.items.length - 1 : current - 1;
  dispatch(updateCurrent(index));
};

export const clearResults = () => (dispatch, getState) => {
  dispatch(clear());
};

export const exportToFile = (headers, mqnDistance, mhfp6Distance) => (dispatch, getState) => {
  const { items } = getState().mqnSearch;
  const fileData = Helpers.getMqnSearchFileData(items, headers, mqnDistance, mhfp6Distance);
  saveTextFileFromArray(fileData, "smiles_export.csv");
}

const setItems = items => ({ type: Type.SET_ITEMS, data: items });

const setDatabases = items => ({ type: Type.SET_DATABASES, data: items });

const updateCurrent = current => ({ type: Type.UPDATE_CURRENT, data: current });

const remove = (items, current) => ({ type: Type.REMOVE, data: { items, current } });

const clear = () => ({ type: Type.CLEAR });