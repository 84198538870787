import React from "react";
import LoadinSpinner from "../LoadingSpinner";
import Controls from "../Controls";
import Faerun from "../Faerun";
import Legend from "../Legend";
import Selection from "../Selection";
import { useSelector } from "react-redux";
import { Request } from "../../store/ui/actions";
import classes from "./index.module.css";
import classnames from "classnames";
import JsmeEditor from "../JsmeEditor";
import HistogramRangeSelectorPanel from "../HistogramRangeSelector/panel";

const TMAP = ({ isPublic }) => {

  const loading = useSelector(state => state.ui.loading[Request.FAERUN_FETCH] || state.ui.loading[Request.TMAP_DESCRIPTOR]);

  return (
    <div className={classnames(classes.Tmap, { [classes.Public]: isPublic })}>
      {loading && <LoadinSpinner />}
      <Controls.Left isPublic={isPublic} />
      <Controls.Right isPublic={isPublic} />
      <JsmeEditor />
      <HistogramRangeSelectorPanel />
      <Faerun isPublic={isPublic} />
      <Legend />
      <Selection />
    </div>
  );
}

export default TMAP;
