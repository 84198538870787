import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Permissions from "./permissions";

const Share = ({ variant, className, label, dataset }) => {

  const [open, setOpen] = useState(false);

  return (
    <>
      <Button variant={variant} onClick={() => setOpen(true)} className={className}>
        {label}
      </Button>

      <Modal show={open} onHide={() => setOpen(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Users with access</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Permissions data={dataset.id} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Share;
