import React, { useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Action as TMAPsAction } from "../../store/tmaps";
import TMAPForm from "./form";

const CreateTMAP = () => {

  const dispatch = useDispatch();
  const history = useHistory();

  const [tmap, setTMAP] = useState({
    name: "",
    k: 50,
    kc: 50,
    node_size:50,
    point_size: 5,
    filter_point_size: 8,
    filter_color: "#FFFFFF",
    nn_search_color: "#FF0000",
    background_color: "#000000",
    dataset_id: "",
    scroll_selection_point_size: 8,
    scroll_selection_color: "#00FF00",
  });

  const updateTMAPField = useCallback((name, value) => {
    setTMAP({...tmap, [name]: value })
  }, [tmap]);

  const submitTMAP = useCallback(async () => {
    const response = await dispatch(TMAPsAction.createTMAP(tmap));
    if (response && response.id) {
      history.push(`/tmaps`);
    }
  }, [tmap, dispatch, history]);

  return (
    <Container>
      <h1 className="mb-5">Create a new TMAP</h1>
      <TMAPForm onChange={updateTMAPField} onSubmit={submitTMAP} tmap={tmap} />
    </Container>
  );
};

export default CreateTMAP;
