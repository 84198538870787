import React, { useState } from "react";
import { Col, Row, Form, Modal, Button as RbButton } from "react-bootstrap";
import { Action as CartAction } from "../../store/cart";
import { useDispatch, useSelector } from "react-redux";
import Button from "../Button";
import { toast } from "react-toastify";
import { Request } from "../../store/ui/actions";

const ExportCartSmilesModal = ({ variant, size, className, label }) => {
  const [open, setOpen] = useState(false);
  const [descriptors, setDescriptors] = useState([]);
  const [options, setOptions] = useState({ headers: false, logFile: false, logColumn: false });
  const dispatch = useDispatch();

  const scatterMeta = useSelector((state) => state.faerun.scatterMeta);
  const items = useSelector((state) => state.cart.items);
  const loading = useSelector(state => state.ui.loading[Request.TMAP_SMILES]);


  const openModal = () => {
    if(!items || !items.length) {
      toast.warning("Cart is empty");
      return;
    }
    const descriptorData = scatterMeta.find((m) => m.name === "descriptors");
    let desc = [];
    if (descriptorData) {
      desc = descriptorData.series_title.filter(st => st.type === "color").map((m) => ({ ...m, selected: false }));
    }
    setDescriptors(desc);
    setOptions({ headers: false, logFile: false, logColumn: false });
    setOpen(true);
  };

  const closeModal = () => setOpen(false);

  const toggleDescriptor = ({ target }) => {
    const id = parseInt(target.name);
    setDescriptors(descriptors.map((d) => (d.id === id ? { ...d, selected: !d.selected } : d)));
  };

  const toggleOption = ({ target }) => setOptions({...options, [target.name]: !options[target.name]});

  const exportSmiles = async () => {
    const selected = descriptors.filter(d => d.selected).map(d => d.id);
    await dispatch(CartAction.exportCartSmilesToFile(selected, options));
    closeModal();
  }

  const exportLog = () => {
    dispatch(CartAction.exportCartLogToFile());
  }

  return (
    <>
      <Button variant={variant} size={size} onClick={openModal} className={className}>
        {label}
      </Button>

        <Modal show={open} onHide={() => setOpen(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Export smiles</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <legend className="mt-1">Headers</legend>
            <Form.Group>
              <Row>
                <Col sm={4}>
                  <label className="my-2">
                    <input type="checkbox" checked={options.headers} name="headers" onChange={toggleOption} />
                    <span className="ml-2">Include headers</span>
                  </label>
                </Col>
              </Row>
            </Form.Group>

            <legend className="mt-1">Descriptors</legend>
            <Form.Group>
              <Row>
                {descriptors.map((descriptor) => (
                  <Col sm={4} key={descriptor.id}>
                    <label className="my-2">
                      <input type="checkbox" name={descriptor.id} checked={descriptor.selected} onChange={toggleDescriptor} />
                      <span className="ml-2">{descriptor.title}</span>
                    </label>
                  </Col>
                ))}
              </Row>
            </Form.Group>

            <legend className="mt-1">Log</legend>
            <Form.Group>
              <Row>
                <Col sm={4}>
                  <label className="my-2">
                    <input type="checkbox" checked={options.logFile} name="logFile" onChange={toggleOption} />
                    <span className="ml-2">Separated file</span>
                  </label>
                </Col>
                <Col sm={4}>
                  <label className="my-2">
                    <input type="checkbox" checked={options.logColumn} name="logColumn" onChange={toggleOption} />
                    <span className="ml-2">Log column</span>
                  </label>
                </Col>
                <Col sm={4}>
                  <RbButton variant="info" onClick={exportLog}>Export log</RbButton>
                </Col>
              </Row>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
          <RbButton variant="warning" type="button" onClick={closeModal}>
            Cancel
          </RbButton>
          <RbButton variant="primary" type="button" onClick={exportSmiles} disabled={loading}>
            Export
          </RbButton>
        </Modal.Footer>
        </Modal>
    </>
  );
};

export default ExportCartSmilesModal;
