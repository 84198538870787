import { toast } from "react-toastify";
import Lore from "lore-engine";
import { Request, Action as UIAction } from "../ui";
import { Action as FilterSearchAction } from "../filterSearch";
import { Action as CartAction } from "../cart";
import { Action as HoverAction } from "../hover";
import { Action as TMAPsAction } from "../tmaps";
import { Action as MoleculeAction } from "../molecule";
import { Action as SelectionAction } from "../selection";
import { Action as ScrollSelectionAction } from "../scrollSelection";
import { Action as CommonAction } from "../common";
import { Api } from "../tmaps";
import pako from "pako";
import * as Helpers from "./helpers";
import { getArray } from "../../utils/array";

export const Type = {
  SET_DATA: "FAERUN_SET_DATA",
  SET_SERIES_STATE: "FAERUN_SET_SERIES_STATE",
  SET_DESCRIPTOR_DATA: "FAERUN_SET_DESCRIPTOR_DATA",
};

const responseType = process.env.REACT_APP_TMAP_RESPONSE;

export const fetchData = (id, isPublic) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.FAERUN_FETCH));
  dispatch(UIAction.clearError(Request.FAERUN_FETCH));

  try {
    let response = await (isPublic ? Api.detailsPublic(id, responseType) : Api.details(id, responseType));
    if (responseType === "gz") {
      const arrayBuffer = await response.arrayBuffer();
      const inflated = JSON.parse(pako.inflate(arrayBuffer, { to: "string" }));
      response = { data: inflated };
    }
    const index = response.data.scatter_meta.findIndex((d) => d.name === "descriptors");
    const dIndex = response.data.scatter_meta[index].series_title.findIndex((s) => s.type === "color");
    const descriptorData = await dispatch(TMAPsAction.fetchDescriptorData(id, response.data.scatter_meta[index].series_title[dIndex].id, isPublic));
    const tmapInfo = await dispatch(TMAPsAction.fetchTMAPInfo(id, isPublic));

    Helpers.addZAxis(response);
    const lore = Helpers.initLore(response.data.faerun.clear_color);
    lore.devicePixelRatio = 1;
    const clearColor = Lore.Core.Color.fromHex(response.data.faerun.clear_color);
    const treeHelpers = Helpers.initTreeHelpers(lore, response.data.data, response.data.tree_meta, clearColor);
    let seriesState = response.data.scatter_meta.reduce((obj, val) => ({ ...obj, [val.name]: 0 }), {});
    seriesState.descriptors = { color: dIndex };
    const data = {
      clear_color: response.data.faerun.clear_color,
      tmapId: id,
      tmapInfo,
      isPublic,
      data: response.data,
      scatterMeta: response.data.scatter_meta,
      descriptorData: { color: descriptorData },
      seriesState: seriesState,
      visiblePoints: [],
      clearColor,
      lore,
      treeHelpers,
      pointHelpers: [],
      octreeHelpers: [],
      ohIndexToPhName: [],
      ohIndexToPhIndex: [],
      phIndexMap: {},
      ohIndexMap: {},
      min: [Number.MAX_VALUE, Number.MAX_VALUE, Number.MAX_VALUE],
      max: [-Number.MAX_VALUE, -Number.MAX_VALUE, -Number.MAX_VALUE],
      maxRadius: -Number.MAX_VALUE,
    };
    dispatch(setData(data));
    dispatch(FilterSearchAction.fetchSearchData(id, isPublic));
    Helpers.initPointHelpers(data, descriptorData);
    Helpers.initView(data);
    initEvents(getState, dispatch);
  } catch (error) {
    dispatch(UIAction.setError(Request.FAERUN_FETCH, error.message));
    toast.error(error.message);
    throw error;
  } finally {
    dispatch(UIAction.clearLoading(Request.FAERUN_FETCH));
  }
};

export const updateSelection = () => (dispatch, getState) => {
  const { faerun } = getState();
  const hoveredHelperIndex = faerun.octreeHelpers.findIndex((helper) => helper.hovered);

  if (hoveredHelperIndex > -1) {
    const hoveredHelper = faerun.octreeHelpers[hoveredHelperIndex].hovered;
    const currentPoint = { index: hoveredHelper.index };
    const hoverIndicator = Helpers.getHoverIndicator(faerun, hoveredHelper, hoveredHelperIndex);
    dispatch(SelectionAction.setCurrentPoint(currentPoint));
    dispatch(ScrollSelectionAction.clearScrollSelection());
    dispatch(HoverAction.setHoverData(hoverIndicator, currentPoint));
    dispatch(MoleculeAction.fetchMoleculeData(currentPoint.index));
  } else {
    dispatch(SelectionAction.setCurrentPoint(null));
    dispatch(HoverAction.setHoverData(null, null));
    dispatch(ScrollSelectionAction.clearScrollSelection());
  }
};

export const changeSeries = (type, value) => async (dispatch, getState) => {
  const { tmapId, isPublic, pointHelpers, data, phIndexMap, descriptorData } = getState().faerun;
  dispatch(setSeriesState("descriptors", { [type]: value }));
  let newDescriptorData;
  const index = data.scatter_meta.findIndex((d) => d.name === "descriptors");
  if (value !== undefined) {
    const response = await dispatch(TMAPsAction.fetchDescriptorData(tmapId, data.scatter_meta[index].series_title[value].id, isPublic));
    newDescriptorData = { ...descriptorData, [type]: response };
  } else {
    newDescriptorData = { ...descriptorData, [type]: undefined };
  }
  dispatch(setDescriptorData(newDescriptorData));

  if (type === "color") {
    pointHelpers[phIndexMap.descriptors].setRGBFromArrays(newDescriptorData.color.r, newDescriptorData.color.g, newDescriptorData.color.b);
  } else if (type === "transparency") {
    if (newDescriptorData.transparency) {
      pointHelpers[phIndexMap.descriptors].geometry.addAttribute("transparency", new Float32Array(newDescriptorData.transparency), 1);
    } else {
      pointHelpers[phIndexMap.descriptors].geometry.addAttribute("transparency", new Float32Array(getArray(newDescriptorData.color.r.length, 1.0)), 1);
    }
  } else if (type === "size") {
    if (newDescriptorData.size) {
      pointHelpers[phIndexMap.descriptors].setSize(newDescriptorData.size);
    } else { 
      pointHelpers[phIndexMap.descriptors].setSize(1);
    }

    dispatch(CommonAction.setFilterVisibility("filter", getState().filterSearch.data || []));
    dispatch(CommonAction.setFilterVisibility("nn_search", Object.keys(getState().nnSearch || [])));
    dispatch(CommonAction.setFilterVisibility("scroll_selection", getState().scrollSelection.selection || []));
  }
};

export const toggleLegendSectionVisibility = (name) => (dispatch, getState) => {
  const { pointHelpers, phIndexMap } = getState().faerun;

  let geometry = pointHelpers[phIndexMap[name]].geometry;
  let isVisible = geometry.isVisible;

  if (isVisible) {
    geometry.hide();
  } else {
    geometry.show();
  }
  return isVisible;
};

const setData = (data) => ({ type: Type.SET_DATA, data });

export const stopRendering = () => (dispatch, getState) => {
  // disable main rendering loop
  if (getState().faerun.lore) getState().faerun.lore.animate = () => undefined;
};

const setSeriesState = (name, value) => ({ type: Type.SET_SERIES_STATE, data: { name, value } });

const setDescriptorData = (data) => ({ type: Type.SET_DESCRIPTOR_DATA, data });

let indicatorTimeout = null;
let hoverTimeout = null;
let moleculeTimeout = null;
const initEvents = (getState, dispatch) => {
  getState().faerun.lore.controls.addEventListener("updated", () => {
    const { octreeHelpers } = getState().faerun;
    const { indicator: hoverIndicator, point: currentHoveredPoint } = getState().hover;
    if (hoverIndicator) {
      let indicator = Helpers.getHoverIndicator(
        getState().faerun,
        {
          screenPosition: octreeHelpers[hoverIndicator.ohIndex].getScreenPosition(hoverIndicator.index),
          index: hoverIndicator.index,
        },
        hoverIndicator.ohIndex
      );
      clearTimeout(hoverTimeout);
      hoverTimeout = setTimeout(() => dispatch(HoverAction.setHoverData(indicator, currentHoveredPoint)));
    }
    clearTimeout(indicatorTimeout);
    indicatorTimeout = setTimeout(() => dispatch(CartAction.updateIndicatorPositions()));
  });

  Lore.Helpers.OctreeHelper.joinHoveredChanged(getState().faerun.octreeHelpers, (e) => {
    const data = getState().faerun;
    if (getState().selection !== null) return;

    if (e.e) {
      const currentHoveredPoint = { index: e.e.index };
      const hoverIndicator = Helpers.getHoverIndicator(data, e.e, e.source);
      dispatch(HoverAction.setHoverData(hoverIndicator, currentHoveredPoint));
      clearTimeout(moleculeTimeout);
      moleculeTimeout = setTimeout(() => dispatch(MoleculeAction.fetchMoleculeData(currentHoveredPoint.index)), 200);
    } else {
      clearTimeout(moleculeTimeout);
      dispatch(HoverAction.setHoverData(null, null));
    }
  });
};
