import * as Api from "../../utils/api";

const LOGIN = "auth/login";
const ME = "auth/me";
const CHANGE_PASSWORD = "auth/change-password";
const RESET_PASSWORD = "auth/reset-password";
const FORGOT_PASSWORD = "auth/forgot-password";
const SIGNUP = "auth/signup";

export const login = async data => Api.post(LOGIN, data);

export const changePassword = async data => Api.post(CHANGE_PASSWORD, data);

export const resetPassword = async data => Api.post(RESET_PASSWORD, data);

export const forgotPassword = async email => Api.post(FORGOT_PASSWORD, { email });

export const me = async () => Api.get(ME);

export const signup = async data => Api.post(SIGNUP, data);
