import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import TmapColorDescriptor from "./colorDescriptor";
import { isEmpty } from "../../../utils";
import HistogramRangeSelectorModal from "../../HistogramRangeSelector/modal";

const TmapDescriptorForm = ({ descriptor, descriptors, edit, onChange, datasetId }) => {
  const [type, setType] = useState(descriptor.id ? (descriptor.custom ? "custom" : "range") : "range");

  const typeChangeHadler = (e) => {
    const value = e.target.value;
    if (value === "range") {
      onChange({ target: { name: "custom", value: null } });
    } else {
      onChange({ target: { name: "min_value", value: null } });
      onChange({ target: { name: "max_value", value: null } });
      onChange({ target: { name: "custom", value: new Array(descriptor.descriptor.labels.length).fill("") } });
    }
    setType(value);
  };

  const customChangeHandler = (e) => {
    const index = parseInt(e.target.dataset.index);
    const value = e.target.value;
    onChange({ target: { name: "custom", value: descriptor.custom.map((v,i) => i === index ? value : v) } });
  }

  return (
    <>
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Descriptor</Form.Label>
            <Form.Control as="select" name="descriptor_id" required onChange={onChange} value={descriptor.descriptor_id} disabled={edit}>
              <option disabled value="">
                Select descriptor
              </option>
              {descriptors.map((d) => (
                <option key={d.id} value={d.id}>
                  {d.title}
                </option>
              ))}
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select descriptor</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Descriptor type</Form.Label>
            <Form.Control as="select" name="type" required onChange={onChange} value={descriptor.type || ""} disabled={edit}>
              <option disabled value="">
                Select descriptor type
              </option>
              <option value="color">Color</option>
              <option value="size">Size</option>
              <option value="transparency">Transparency</option>
            </Form.Control>
            <Form.Control.Feedback type="invalid">Please select descriptor type</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {descriptor.type === "color" && <TmapColorDescriptor descriptor={descriptor} onChange={onChange} />}
      {(descriptor.type === "size" || descriptor.type === "transparency") && (
        <>
          {descriptor.descriptor.category === "categorical" && (
            <Row>
              <Col sm={6} className="mb-2">
                <Form.Label>Type</Form.Label>
                <Form.Control as="select" name="type" required onChange={typeChangeHadler} value={type}>
                  <option value="range">Range</option>
                  <option value="custom">Custom</option>
                </Form.Control>
              </Col>
            </Row>
          )}
          {(descriptor.descriptor.category === "numerical" || (descriptor.descriptor.category === "categorical" && type === "range")) && (
            <Row>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Min {descriptor.type}</Form.Label>
                  <Form.Control type="number" name="min_value" required onChange={onChange} value={descriptor.min_value || ""} />
                  <Form.Control.Feedback type="invalid">Please enter min value</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col sm={6}>
                <Form.Group>
                  <Form.Label>Max {descriptor.type}</Form.Label>
                  <Form.Control type="number" name="max_value" required onChange={onChange} value={descriptor.max_value || ""} />
                  <Form.Control.Feedback type="invalid">Please enter max value</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          )}
          {descriptor.descriptor.category === "categorical" && type === "custom" && (
            <Row>
              {descriptor.descriptor.labels.map(l => (
                <Col sm={4} className="my-1">
                  <Form.Control
                    type="text"
                    data-index={l[0]}
                    placeholder={l[1].toString()}
                    required
                    value={descriptor.custom ? descriptor.custom[l[0]] : ""}
                    onChange={customChangeHandler}
                  />
                </Col>
              ))}
            </Row>
          )}
        </>
      )}
      {descriptor.descriptor && descriptor.descriptor.category === "numerical" && (
        <>
          <Row>
            <Col sm={5}>
              <Form.Group>
                <Form.Label>Min limit</Form.Label>
                <Form.Control
                  type="text"
                  name="min_limit"
                  onChange={onChange}
                  value={isEmpty(descriptor.min_limit) ? "" : descriptor.min_limit}
                  placeholder={descriptor.descriptor.range[0]}
                />
              </Form.Group>
            </Col>
            <Col sm={5}>
              <Form.Group>
                <Form.Label>Max limit</Form.Label>
                <Form.Control
                  type="text"
                  name="max_limit"
                  onChange={onChange}
                  value={isEmpty(descriptor.max_limit) ? "" : descriptor.max_limit}
                  placeholder={descriptor.descriptor.range[1]}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Label className="d-block">&nbsp;</Form.Label>
              <HistogramRangeSelectorModal descriptor={descriptor} onConfirm={onChange} datasetId={datasetId} />
            </Col>
          </Row>
        </>
      )}
      {descriptor.descriptor && descriptor.descriptor.category === "numerical" && descriptor.type === "color" && (
        <>
          <Row>
            <Col sm={6}>
              {!isEmpty(descriptor.min_limit) && (
                <Form.Group>
                  <Form.Label>Min value</Form.Label>
                  <Form.Control type="color" name="min_value" value={descriptor.min_value || "#FF0000"} onChange={onChange} />
                </Form.Group>
              )}
            </Col>
            <Col sm={6}>
              {!isEmpty(descriptor.max_limit) && (
                <Form.Group>
                  <Form.Label>Max value</Form.Label>
                  <Form.Control type="color" name="max_value" required value={descriptor.max_value || "#FF0000"} onChange={onChange} />
                </Form.Group>
              )}
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

export default TmapDescriptorForm;
