import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import classnames from "classnames";
import classes from "./index.module.css";
import { Action as CartAction } from "../../../store/cart";
import { Helpers as UserHelpers } from "../../../store/users";
import Smiles from "../Smiles";
import Descriptors from "../Descriptors";
import NearestNeighbors from "../NearestNeighbors";
import TabLinks from "../TabLinks";
import TmapFromData from "../../TmapFromData";
import Button from "../../Button";
import NavigationControls from "../../NavigationControls";
import ExportCartSmilesModal from "../../ExportCartSmilesModal";
import CartReport from "../../CartReport";

const Cart = () => {
  const cart = useSelector((state) => state.cart);
  const data = useSelector((state) => (state.cart.current ? state.molecule[state.cart.current.e.index] : undefined));
  const nnHits = useSelector((state) => (state.cart.current ? (state.nnSearch || {})[state.cart.current.e.index] : undefined));
  const hasTmapFromCartButton = useSelector((state) => !state.faerun.isPublic && UserHelpers.canEditTmaps(state.auth));
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState("smiles");

  const activateTab = useCallback((tab) => setActiveTab(tab), []);
  const selectedPrev = () => dispatch(CartAction.prev());
  const selectedNext = () => dispatch(CartAction.next());
  const removeCurrent = () => dispatch(CartAction.removeCurrent());
  const removeAllFromCart = () => dispatch(CartAction.clearCart());

  const nums = cart.items.map((i) => i.e.index);

  return (
    <div className={classes.Cart}>
      <TabLinks active={activeTab} activateTab={activateTab} />
      <NavigationControls prev={selectedPrev} next={selectedNext} remove={removeCurrent} />
      {activeTab === "smiles" && <Smiles data={data} enableCopySmiles={!!data} />}
      {activeTab === "descriptors" && <Descriptors data={data} />}
      {activeTab === "nearest-neighbors" && <NearestNeighbors hits={nnHits} />}
      <div className={classes.ControlsBottom}>
        <ExportCartSmilesModal size="Small" className={classes.CartButton} label="Export smiles" />
        <Button size="Small" variant="Danger" className={classes.CartButton} onClick={removeAllFromCart}>
          Remove All
        </Button>
      </div>
      <div className={classnames(classes.ControlsBottom, "mt-1")}>
        <CartReport label="Cart Report" className={classes.CartButton} />
        {hasTmapFromCartButton && <TmapFromData hasCustomDescriptors data={{ nums }} label="TMAP from Cart" className={classes.CartButton} />}
      </div>
    </div>
  );
};

export default Cart;
