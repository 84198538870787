import React from "react";
import classes from "./index.module.css";

const LoadinSpinner = props => (
  <div className={classes.LoadingSpinner}>
  <div className={classes.LdsDualRing}></div>
</div>
);

export default LoadinSpinner;
