export const Request = {
  DATASETS_FETCH: "DATASETS_FETCH",
  DATASET_FETCH: "DATASET_FETCH",
  DATASETS_DONE: "DATASETS_DONE",
  DATASET_SAVE: "DATASET_SAVE",
  DATASET_REMOVE: "DATASET_REMOVE",
  DATASET_PERMISSIONS: "DATASET_PERMISSIONS",
  DATASET_PERMISSION: "DATASET_PERMISSION",
  DATASET_PROCESSING: "DATASET_PROCESSING",
  DATASET_PROCESSING_LOG: "DATASET_PROCESSING_LOG",
  DATASET_DESCRIPTORS: "DATASET_DESCRIPTORS",
  DATASET_DESCRIPTOR_HISTOGRAM: "DATASET_DESCRIPTOR_HISTOGRAM",
  TMAPS_FETCH: "TMAPS_FETCH",
  TMAP_FETCH: "TMAP_FETCH",
  TMAP_SAVE: "TMAP_SAVE",
  TMAP_REMOVE: "TMAP_REMOVE",
  TMAP_PROCESSING: "TMAP_PROCESSING",
  TMAP_PROCESSING_LOG:"TMAP_PROCESSING_LOG",
  TMAP_DESCRIPTOR: "TMAP_DESCRIPTOR",
  TMAP_DESCRIPTOR_HISTOGRAM: "TMAP_DESCRIPTOR_HISTOGRAM",
  TMAP_MOLECULES: "TMAP_MOLECULES",
  FAERUN_FETCH: "FAERUN_FETCH",
  TMAP_PERMISSIONS: "TMAP_PERMISSIONS",
  TMAP_PERMISSION: "TMAP_PERMISSION",
  TMAP_TOKEN: "TMAP_TOKEN",
  TMAP_SMILES: "TMAP_SMILES",
  TMAP_COPY_SUBSET: "TMAP_COPY_SUBSET",
  TMAP_FETCH_INFO: "TMAP_FETCH_INFO",
  TMAP_DOWNLOAD: "TMAP_DOWNLOAD",
  NN_SEARCH: "FAERUN_NN_SEARCH",
  SEARCH_FILTER: "FAERUN_SEARCH_FILTER",
  FILTER_DATA: "FAERUN_FILTER_DATA",
  AUTH_LOGIN: "AUTH_LOGIN",
  AUTH_ME: "AUTH_ME",
  AUTH_CHANGE_PASSWORD: "AUTH_CHANGE_PASSWORD",
  AUTH_RESET_PASSWORD: "AUTH_RESET_PASSWORD",
  AUTH_FORGOT_PASSWORD: "AUTH_FORGOT_PASSWORD",
  AUTH_SIGNUP: "AUTH_SIGNUP",
  USERS_FETCH:"USERS_FETCH",
  USER_SAVE:"USER_SAVE",
  USER_REMOVE:"USER_REMOVE",
  USER_ENABLE_DISABLE: "USER_ENABLE_DISABLE",
  MQN_SEARCH: "MQN_SEARCH",
  MQN_DATABASES: "MQN_DATABASES"
}

export const Type = {
  SET_ERROR: "UI_SET_ERROR",
  SET_LOADING: "UI_SET_LOADING",
  SET_HISTOGRAM_SELECTOR: "UI_SET_HISTOGRAM_SELECTOR",
};

export const setError = (request, value) => ({ type: Type.SET_ERROR, request, value });

export const clearError = (request) => ({ type: Type.SET_ERROR, request, value: null });

export const setLoading = (request) => ({ type: Type.SET_LOADING, request, value: true });

export const clearLoading = (request) => ({ type: Type.SET_LOADING, request, value: false });

export const closeHistogramSelector = () => ({ type: Type.SET_HISTOGRAM_SELECTOR, data: { open: false } });

export const openHistogramSelector = (descriptor, onConfirm) => ({ type: Type.SET_HISTOGRAM_SELECTOR, data: { open: true, descriptor, onConfirm } });