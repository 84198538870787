import React, { useRef, useState } from "react";
import { useParams, useLocation } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import { Action as NNSearchAction } from "../../store/nnSearch";
import classes from "./index.module.css";
import TextReader from "../TextReader";
import { Request } from "../../store/ui/actions";
import LoadinSpinner from "../LoadingSpinner";
import Button from "../Button";
import { Action as JsmeAction } from "../../store/jsme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NearestNeighborSearch = ({isPublic}) => {

  const searching = useSelector(state => state.ui.loading[Request.NN_SEARCH]);
  const textAreaRef = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();
  let params= useParams();
  let queryParams = new URLSearchParams(location.search);  
  let data = isPublic ? queryParams.get("token") : params.id;
 
  const [query, setQuery] = useState("");
  const [similarity, setSimilarity] = useState(0.5);

  const onQueryChange = ({ target }) => setQuery(target.value);

  const onSimilarityChange = ({ target }) => setSimilarity(target.value);

  const searchHandler = () => {
    if(query) {
      const search = query.split("\n");
      dispatch(NNSearchAction.fetchNNSearch(data, search, similarity, isPublic))
    }
  }

  const clearHandler = () => dispatch(NNSearchAction.clearNNSearch());

  const openJsmeEditor = () => {
    if(query.split("\n").length > 1) {
      let selection = textAreaRef.current.selectionStart || 0;
      let start = query.substr(0, selection).lastIndexOf("\n")+1;
      let end = query.indexOf("\n", selection);
      if(end === -1) end = query.length;
      let selectedSmiles = query.substring(start, end);
      if(selectedSmiles) {
        dispatch(JsmeAction.openEditor(selectedSmiles, s => setQuery(query.split("\n").map(q => q === selectedSmiles ? s : q).join("\n")), "Left"));
      }
    } else {
      dispatch(JsmeAction.openEditor(query, s => setQuery(s), "Left"));
    }
  }

  return (
    <div className={classes.NearestNeighborSearch}>
      {searching && <LoadinSpinner />}
      <h2 className={classes.Title}>Nearest Neighbor Search</h2>
      <textarea className={classes.Textarea} value={query} onChange={onQueryChange} ref={textAreaRef}></textarea>
      <Button className="mt-1" onClick={openJsmeEditor}>
        <FontAwesomeIcon icon="pen" />
      </Button>
      <label className={classes.SimilarityLabel}>Min Similarity: { similarity }</label>
      <input name="similarity" type="range" min="0" max="1" step="0.01" value={similarity} onChange={onSimilarityChange} />
      <TextReader loadTextFromFile={setQuery} />
      <div className={classes.Controls}>
        <Button variant="Success" block disabled={searching} onClick={searchHandler}>Search</Button>
        <Button variant="Danger" block className="ml-1" onClick={clearHandler}>Clear</Button>
      </div>
    </div>
  );
}

export default NearestNeighborSearch;
