import React from "react";
import classes from "./index.module.css";
import SeriesTitle from "../SeriesTitle";
import SeriesSelector from "../SeriesSelector";
import SeriesLegend from "../SeriesLegend";

const Series = ({ data }) => {

  const hasSelector = data.series_title.length && data.series_title[0];

  return (
    <div className={classes.SeriesLegend}>
      <SeriesTitle data={data} />
      {hasSelector && <SeriesSelector data={data} />}
      <SeriesLegend data={data} />
    </div>
  );
};

export default Series;
