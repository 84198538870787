import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router";
import { Request } from "../../store/ui";
import { Action as DatasetsAction } from "../../store/datasets";

const DeleteDataset = () => {
  const loading = useSelector((state) => state.ui.loading[Request.DATASET_FETCH]);
  const error = useSelector((state) => state.ui.error[Request.DATASET_FETCH]);
  const id = parseInt(useParams().id);
  const dataset = useSelector((state) => state.datasets.find((dataset) => dataset.id === id));
  const deleting = useSelector((state) => state.ui.loading[Request.DATASET_REMOVE]);
  const dispatch = useDispatch();
  const history = useHistory();
  const [confirmed, setConfirmed] = useState(false); 

  useEffect(() => {
    dispatch(DatasetsAction.fetchDataset(id));
  }, [dispatch, id]);

  const onSubmit = async () => {
    const status = await dispatch(DatasetsAction.removeDataset(dataset));
    if (status) {
      history.push("/datasets");
    }
  };

  if (error) {
    return error;
  }

  if (loading || !dataset) {
    return "Loading...";
  }

  return (
    <Container>
      <h1 className="mb-5">Delete dataset '{dataset.name}'</h1>
      <p>Do you want to delete Dataset '{dataset.name}'?</p>
      <p>
        <label>
          <input type="checkbox" checked={confirmed} onChange={() => setConfirmed(!confirmed)}  /> <b>I understand that by deleting this dataset, I will also delete all TMAPS generated from this dataset</b>
        </label>
      </p>
      <Button disabled={deleting || !confirmed} variant="danger" onClick={onSubmit}>
        {deleting ? "Deleting..." : "Delete"}
      </Button>
    </Container>
  );
};

export default DeleteDataset;
