import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Permissions from "./permissions";
import Link from "./link";
import classes from "./index.module.css";
import { useSelector } from "react-redux";

const Share = ({ variant, className, label, tmap }) => {
  const [open, setOpen] = useState(false);
  const disable_user_share = useSelector((state) => state.auth.disable_user_share);

  return (
    <>
      <Button variant={variant} onClick={() => setOpen(true)} className={className}>
        {label}
      </Button>

      <Modal show={open} onHide={() => setOpen(false)} className={classes.Modal}>
        {!disable_user_share && (
          <>
            <Modal.Header closeButton className={classes.Header}>
              <Modal.Title>Users with access</Modal.Title>
            </Modal.Header>
            <Modal.Body className={classes.Body}>
              <Permissions data={tmap.id} />
            </Modal.Body>
          </>
        )}

        <Modal.Header className={classes.Header}>
          <Modal.Title>Share link</Modal.Title>
        </Modal.Header>
        <Modal.Body className={classes.Body}>
          <Link data={tmap} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Share;
