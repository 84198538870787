import React from "react";
import classnames from "classnames";
import classes from "./index.module.css";

const Select = ({ className, children, ...props }) => {

  return (
    <select className={classnames(classes.Select, className)} {...props} >
      {children}
    </select>
  );
};

export default Select;
