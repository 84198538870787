import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Action as DatasetAction } from "../../../store/datasets";
import { Request } from "../../../store/ui/actions";
import TmapDescriptorColormap from "./descriptorColormap";
import TMAPDescriptorLimit from "./limit";
import TMAPDescriptorModal from "./modal";

const TMAPDescriptors = ({ tmap, onChange }) => {
  const dispatch = useDispatch();
  const [descriptors, setDescriptors] = useState(null);

  const hasDescriptors = !!tmap.descriptors && !!tmap.descriptors.length;
  const oldDataset = useRef(tmap.dataset_id);
  const datasetId = tmap.dataset_id;

  // create TMAP -  update descriptors on each dataset change
  useEffect(() => {
    if (datasetId !== oldDataset.current) {
      oldDataset.current = datasetId;
      const fetchDescriptors = async () => {
        const response = await dispatch(DatasetAction.fetchDescriptors(datasetId));
        setDescriptors(response);
        onChange("descriptors", createDefaultDescriptors(response));
      };
      fetchDescriptors();
    }
  }, [datasetId, dispatch, onChange, oldDataset]);

  // update TMAP -  update descriptors only on load
  useEffect(() => {
    if(hasDescriptors) {
      const fetchDescriptors = async () => {
        const response = await dispatch(DatasetAction.fetchDescriptors(datasetId));
        setDescriptors(response);
      };
      fetchDescriptors();
    }
  }, [hasDescriptors, datasetId, dispatch]);

  const loading = useSelector((state) => state.ui.loading[Request.DATASET_DESCRIPTORS]);

  const deleteDescriptor = (e) => {
    const id = e.currentTarget.dataset.id;
    onChange(
      "descriptors",
      // eslint-disable-next-line eqeqeq
      tmap.descriptors.filter((d) => (d.id ? d.id != id : d._id != id))
    );
  };

  const editDescriptor = (descriptor) => {
    const getId = d => d.id || d._id;
    onChange(
      "descriptors",
      tmap.descriptors.map((d) => (getId(d) === getId(descriptor)  ? descriptor : d))
    );
  };

  const addDescriptor = (descriptor) => {
    if(!descriptor.id && !descriptor._id) {
      descriptor._id = `${descriptor.descriptor.id}_${descriptor.type}_${new Date().getTime()}`
    }
    onChange("descriptors", [...tmap.descriptors, descriptor]);
  };

  return (
    <div>
      <legend className="mt-5">Descriptor options</legend>
      {loading && <span>Loading...</span>}
      {!loading && (
        <div>
          {!tmap.dataset_id &&  (!tmap.descriptors || !tmap.descriptors.length) && <span>Please select dataset</span>}
          {!!tmap.dataset_id && !!tmap.descriptors && tmap.descriptors.length && (
            <TMAPDescriptorModal
              onSave={addDescriptor}
              className="mb-2"
              label="Add descriptor"
              size="sm"
              variant="primary"
              descriptor={{ type: "", descriptor_id: "" }}
              descriptors={descriptors}
              datasetId={datasetId}
            />
          )}
          {tmap.descriptors && (
            <table className="table table-sm">
              <tbody>
                {tmap.descriptors.map((d,i) => (
                  <tr key={d.id || d._id}>
                    <td>{d.descriptor.title}</td>
                    <td>{d.type}</td>
                    <td className="d-flex align-items-center justify-content-center">
                      <TMAPDescriptorLimit descriptor={d} type="min" />
                      {d.type === "color" && <TmapDescriptorColormap descriptor={d} customValues={d.custom} />}
                      {d.type !== "color" && d.descriptor.category === "numerical" && d.min_value + " - " + d.max_value}
                      {d.type !== "color" && d.descriptor.category === "categorical" && d.custom && d.custom.join(", ")}
                      {d.type !== "color" && d.descriptor.category === "categorical" && !d.custom && d.min_value + " - " + d.max_value}
                      <TMAPDescriptorLimit descriptor={d} type="max" />
                    </td>
                    <td>
                      <ButtonGroup>
                        <TMAPDescriptorModal
                          label={<FontAwesomeIcon icon="edit" />}
                          size="sm"
                          variant="primary"
                          descriptor={d}
                          descriptors={descriptors}
                          onSave={editDescriptor}
                          datasetId={datasetId}
                          edit
                        />
                        <Button size="sm" variant="danger" data-id={d.id || d._id} onClick={deleteDescriptor}>
                          <FontAwesomeIcon icon="times" />
                        </Button>
                      </ButtonGroup>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      )}
    </div>
  );
};

export default TMAPDescriptors;

const createDefaultDescriptors = (descriptors) => {
  return descriptors.map((d) => ({
    descriptor: d,
    descriptor_id: d.id,
    type: "color",
    colormap: d.category === "numerical" ? "default" : "tab10",
    _id: `${d.id}_color_${new Date().getTime()}`
  }));
};
