import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

const ConfirmationModal = ({ variant, className, label, onConfirm, title, content, submitting }) => {

  const [open, setOpen] = useState(false);

  const close = () => setOpen(false);

  const confirm = async () => {
    const response = await onConfirm();
    if(response) close();
  }

  return (
    <>
      <Button variant={variant} onClick={() => setOpen(true)} className={className}>
        {label}
      </Button>

      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5>{content}</h5>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={close}>Cancel</Button>
          <Button variant="primary" onClick={confirm} disabled={submitting}>Yes</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ConfirmationModal;
