import React, { useState, useEffect } from "react";
import { Request } from "../../../store/ui/actions";
import { useDispatch, useSelector } from "react-redux";

import { Action as DatasetAction } from "../../../store/datasets";
import LoadinSpinner from "../../LoadingSpinner";
import { Alert, Table, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import classes from "./index.module.css";

const Permissions = ({ data }) => {
  const [permissions, setPermissions] = useState(null);
  const dispatch = useDispatch();

  const loading = useSelector((state) => state.ui.loading[Request.DATASET_PERMISSIONS]);
  const toggling = useSelector((state) => state.ui.loading[Request.DATASET_PERMISSION]);
  const error = useSelector((state) => state.ui.error[Request.DATASET_PERMISSIONS]);

  useEffect(() => {
    async function fetchPermissions() {
      const response = await dispatch(DatasetAction.getPermissions(data));
      if (response) setPermissions(response);
    }
    fetchPermissions();
  }, [data, dispatch]);

  if (loading) return <LoadinSpinner />;

  if (error) return <Alert variant="danger">{error}</Alert>;

  const toggle = async ({ currentTarget }) => {
    const user = parseInt(currentTarget.dataset.user);
    const success = await dispatch(DatasetAction.togglePermission(data, user));
    if (success) {
      setPermissions(permissions.map(p => p.id === user ? { ...p, hasPermission: !p.hasPermission } : p));
    }
  };

  return (
    <div className={classes.Permissions}>
      <Table size="sm" borderless>
        <tbody>
          {permissions?.map((p) => (
            <tr key={p.id}>
              <td>{p.name}</td>
              <td className="text-center">
                <Button
                  data-user={p.id}
                  className={classes.Button}
                  size="sm"
                  variant={p.hasPermission ? "success" : "danger"}
                  onClick={toggle}
                  disabled={toggling}
                >
                  <FontAwesomeIcon icon={p.hasPermission ? "check" : "times"} />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Permissions;
