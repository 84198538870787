import Lore from "lore-engine";

const transparentSmoothCircle = new Lore.Core.Shader(
  "transparentSmoothCircle",
  2,
  {
    size: new Lore.Core.Uniform("size", 5.0, "float"),
    cutoff: new Lore.Core.Uniform("cutoff", 0.0, "float"),
    clearColor: new Lore.Core.Uniform("clearColor", [0.0, 0.0, 0.0, 1.0], "float_vec4"),
    fogDensity: new Lore.Core.Uniform("fogDensity", 6.0, "float"),
  },
  [
    "uniform float size;",
    "uniform float cutoff;",
    "in vec3 position;",
    "in vec3 color;",
    "in float transparency;",
    "out vec3 vColor;",
    "out float vDiscard;",
    "out float vTransparency;",
    "vec3 floatToRgb(float n) {",
    "float b = floor(n / 65536.0);",
    "float g = floor((n - b * 65536.0) / 256.0);",
    "float r = floor(n - b * 65536.0 - g * 256.0);",
    "return vec3(r / 255.0, g / 255.0, b / 255.0);",
    "}",
    "void main() {",
    "float point_size = color.b;",
    "gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);",
    "vec4 mv_pos = modelViewMatrix * vec4(position, 1.0);",
    "vDiscard = 0.0;",
    "if(-mv_pos.z < cutoff || point_size <= 0.0 || mv_pos.z > 0.0) {",
    "vDiscard = 1.0;",
    "return;",
    "}",
    "gl_PointSize = point_size * size;",
    "vColor = floatToRgb(color.r);",
    "vTransparency = transparency;",
    "}",
  ],
  [
    "uniform vec4 clearColor;",
    "uniform float fogDensity;",
    "in vec3 vColor;",
    "in float vDiscard;",
    "in float vTransparency;",
    "out vec4 fragColor;",
    "void main() {",
    "if(vDiscard > 0.5) discard;",
    "float dist = distance(gl_PointCoord, vec2(0.5)) * 1.25;",
    "float delta = fwidth(dist);",
    "float a = vTransparency - smoothstep(0.5 - delta, 0.5 + delta, dist);",
    "fragColor = vec4(vColor, a);",
    "if (fogDensity > 0.0) {",
    "float z = gl_FragCoord.z / gl_FragCoord.w;",
    "float fog_factor = clamp(exp2(-fogDensity * fogDensity * z * z * 1.442695), 0.025, 1.0);",
    "fragColor = mix(vec4(clearColor.rgb, a), fragColor, fog_factor);",
    "}",
    "}",
  ]
);


export default transparentSmoothCircle;