import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Action as DatasetAction } from "../../store/datasets";
import { Action as TMAPAction } from "../../store/tmaps";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Request } from "../../store/ui/actions";
import moment from "moment";
import classes from "./index.module.css";

const ProcessingLog = ({ tmapId, datasetId }) => {
  const [open, setOpen] = useState(false);
  const [log, setLog] = useState([]);
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.ui.loading[Request.DATASET_PROCESSING_LOG] || state.ui.loading[Request.TMAP_PROCESSING_LOG]);

  const openModal = async () => {
    setOpen(true);
    if (tmapId) {
      let response = await dispatch(TMAPAction.fetchProcessingLog(tmapId));
      setLog(response);
    } else if (datasetId) {
      let response = await dispatch(DatasetAction.fetchProcessingLog(datasetId));
      setLog(response);
    }
  };

  const closeModal = () => setOpen(false);

  return (
    <>
      <Button variant="primary" onClick={openModal}>
        <FontAwesomeIcon icon="list" />
      </Button>
      <Modal show={open} onHide={closeModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Log</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {open && loading && <span>Loading...</span>}
          {open && !loading && (
            <div className={classes.LogContainer}>
              <table className="table table-sm">
                <thead>
                  <tr>
                    <th>Time</th>
                    <th>User</th>
                    <th>Log</th>
                  </tr>
                </thead>
                <tbody>
                  {log.map((l) => (
                    <tr key={l.id}>
                      <td>{moment(l.created_at).format("DD.MM.YYYY. HH:mm:ss")}</td>
                      <td>{l.created_by}</td>
                      <td>{l.log}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={closeModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProcessingLog;
