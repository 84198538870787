import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Action as UsersAction, Helpers as UserHelpers } from "../../store/users";
import UserForm from "./form";

const EditUser = ({ variant, className, label, data }) => {
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});

  const close = () => setOpen(false);

  const openModal = () => {
    setUser({
      id: data.id,
      name: data.name,
      role: data.role,
      permissions: UserHelpers.mapPermissions(data),
      tmap_limit: data.tmap_limit,
      disable_user_share: data.disable_user_share,
    });
    setOpen(true);
  };

  const onChange = (name, value) => {
    setUser({ ...user, [name]: value });
  };

  const dispatch = useDispatch();

  const submit = async (e) => {
    const response = await dispatch(UsersAction.updateUser(user));
    if (response) close();
  };

  return (
    <>
      <Button variant={variant} onClick={openModal} className={className}>
        {label}
      </Button>
      <Modal show={open} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>Update user</Modal.Title>
        </Modal.Header>
        <UserForm user={user} onChange={onChange} onSubmit={submit} onCancel={close} />
      </Modal>
    </>
  );
};

export default EditUser;
