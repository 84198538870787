import { setFilterVisibility } from "../common/actions";

export const Type = {
  SET_SCROLL_SELECTION: "SCROLL_SELECTION_SET",
  CLEAR_SCROLL_SELECTION: "SCROLL_SELECTION_CLEAR",
};

export const updateScrollSelection = (direction) => async (dispatch, getState) => {
  const { last, selection } = getState().scrollSelection;
  const selectedPoint = getState().selection;

  if (selectedPoint) {
    if (direction < 0) {
      dispatch(addNext(selection, last));
      getState().faerun.lore.controls.zoomOut()
    } else {
      getState().faerun.lore.controls.zoomIn()
      if (selection.length) {
        dispatch(removeLast(selection, last));
      }
    }
  }
};

const removeLast = (selection, last) => async (dispatch, getState) => {
  const lastCnt = last.pop();
  const newSel = selection.slice(0, -lastCnt);
  dispatch(setScrollSelection(newSel, last));
  dispatch(setFilterVisibility("scroll_selection", newSel));
};

const addNext = (selection, last) => async (dispatch, getState) => {
  if (!selection.length) {
    selection = [];
    const current = getState().selection;
    selection.push(current.index);
    last = [1];
  }

  const name = getState().faerun.data.tree_meta[0].name;
  const tree = getState().faerun.data.data[name];
  const descriptors = getState().faerun.data.data.descriptors;

  let lastCnt = last[last.length - 1];
  let startIndex = selection.length - lastCnt;
  let coordinates = [];
  for (let i = startIndex; i < selection.length; i++) {
    let point = { x: descriptors.x[selection[i]], y: descriptors.y[selection[i]] };
    for (let j = 0; j < tree.x.length; j++) {
      if ((tree.x[j] === point.x && tree.y[j] === point.y)) {
        if(j%2 === 0) {
          coordinates.push({x: tree.x[j+1], y: tree.y[j+1] });
        } else {
          coordinates.push({x: tree.x[j-1], y: tree.y[j-1] });
        }
      }
    }
  }
  if (coordinates.length) {
    const selSet = new Set([...selection]);
    let sizeBefore = selSet.size;
    

    coordinates.forEach(coord => {
      for (let j = 0; j < descriptors.x.length; j++) {
        if(descriptors.x[j] === coord.x && descriptors.y[j] === coord.y) {
          selSet.add(j);
          break;
        }
      }
    });

    if (selSet.size > sizeBefore) {
      dispatch(setScrollSelection([...selSet], [...last, selSet.size - sizeBefore]));
      dispatch(setFilterVisibility("scroll_selection", [...selSet]));
    }
  }
};

export const clearScrollSelection = () => async (dispatch, getState) => {
  dispatch({ type: Type.CLEAR_SCROLL_SELECTION });
  dispatch(setFilterVisibility("scroll_selection", []));
}

const setScrollSelection = (selection, last) => ({ type: Type.SET_SCROLL_SELECTION, selection, last });
