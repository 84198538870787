import { Request, Action as UIAction } from "../ui";
import * as Api from "./api";
import * as Storage from "../../utils/storage";
import { toast } from "react-toastify";
import { Helpers as UserHelpers } from "../users";
import { connect as connectToWs } from "../../utils/socketio";

export const Type = {
  SET_USER: "AUTH_SET_USER",
  CLEAR_USER: "AUTH_CLEAR_USER",
};

export const login = data => async dispatch => {
  try {
    dispatch(UIAction.setLoading(Request.AUTH_LOGIN));
    dispatch(UIAction.clearError(Request.AUTH_LOGIN));
    const response = await Api.login(data);
    Storage.setToken(response.token);
    response.user.permissions = UserHelpers.mapPermissions(response.user);
    dispatch(setCurrent(response.user));
    connectToWs();
  } catch (error) {
    dispatch(UIAction.setError(Request.AUTH_LOGIN, error.message));
  } finally {
    dispatch(UIAction.clearLoading(Request.AUTH_LOGIN));
  }
};

export const logout = () => async dispatch => {
  Storage.clearToken();
  dispatch(clearCurrent());
};

export const me = () => async dispatch => {
  const token = Storage.getToken();
  if(token) {
    try{
      dispatch(UIAction.setLoading(Request.AUTH_ME));
      dispatch(UIAction.clearError(Request.AUTH_ME));
      const response = await Api.me();
      response.permissions = UserHelpers.mapPermissions(response);
      dispatch(setCurrent(response));
      connectToWs();
    } catch (error) {
      dispatch(UIAction.setError(Request.AUTH_ME, error.message));
    } finally {
      dispatch(UIAction.clearLoading(Request.AUTH_ME));
    }
  } else {
    dispatch(UIAction.clearLoading(Request.AUTH_ME));
  }
};

export const changePassword = data => async dispatch => {
    try{
      dispatch(UIAction.setLoading(Request.AUTH_CHANGE_PASSWORD));
      dispatch(UIAction.clearError(Request.AUTH_CHANGE_PASSWORD));
      await Api.changePassword(data);
      dispatch(clearCurrent());
      toast.success("Pasword changed! Please login again with the new password");
    } catch (error) {
      dispatch(UIAction.setError(Request.AUTH_CHANGE_PASSWORD, error.message));
    } finally {
      dispatch(UIAction.clearLoading(Request.AUTH_CHANGE_PASSWORD));
    }
};

export const resetPassword = (data) => async dispatch => {
  try{
    dispatch(UIAction.setLoading(Request.AUTH_RESET_PASSWORD));
    dispatch(UIAction.clearError(Request.AUTH_RESET_PASSWORD));
    await Api.resetPassword(data);
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.AUTH_RESET_PASSWORD, error.message));
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.AUTH_RESET_PASSWORD));
  }
};

export const forgotPassword = (email) => async dispatch => {
  try{
    dispatch(UIAction.setLoading(Request.AUTH_FORGOT_PASSWORD));
    dispatch(UIAction.clearError(Request.AUTH_FORGOT_PASSWORD));
    await Api.forgotPassword(email);
    return true;
  } catch (error) {
    dispatch(UIAction.setError(Request.AUTH_FORGOT_PASSWORD, error.message));
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.AUTH_FORGOT_PASSWORD));
  }
};

export const signup = data => async (dispatch, getState) => {
  try {
    dispatch(UIAction.setLoading(Request.AUTH_SIGNUP));
    dispatch(UIAction.clearError(Request.AUTH_SIGNUP));

    let response = await Api.signup(data);
    return response;
  } catch (error) {
    dispatch(UIAction.setError(Request.AUTH_SIGNUP, error.message));
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.AUTH_SIGNUP));
  }
}

const setCurrent = data => ({ type: Type.SET_USER, data });

const clearCurrent = () => ({ type: Type.CLEAR_USER });
