import React from "react";
import { Switch, Route } from "react-router-dom";
import FilterDatasets from "./filter";
import EditDataset from "./edit";
import CreateDataset from "./create";
import DeleteDataset from "./delete";

const Datasets = () => (
  <Switch>
    <Route path="/datasets/new">
      <CreateDataset />
    </Route>
    <Route path="/datasets/:id/delete">
      <DeleteDataset />
    </Route>
    <Route path="/datasets/:id">
      <EditDataset />
    </Route>
    <Route path="/datasets">
      <FilterDatasets />
    </Route>
  </Switch>
);

export default Datasets;
