import { toast } from "react-toastify";
import { Request, Action as UIAction } from "../ui";
import { Action as CommonAction } from "../common";
import { Action as CartAction } from "../cart";
import { Api } from "../tmaps"; 

export const Type = {
  SET_SEARCH_FILTERS: "FAERUN_SET_SEARCH_FILTERS",
  SET_FILTER_DATA: "FAERUN_SET_FILTER_DATA",
};


export const fetchSearchData = (id, isPublic) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.SEARCH_FILTER));
  dispatch(UIAction.clearError(Request.SEARCH_FILTER));

  try {
    const response = await (isPublic ? Api.filterSearchFiltersPublic(id) : Api.filterSearchFilters(id));
    dispatch(setSearchFilters(mapFilters(response.descriptors)));
  } catch (error) {
    dispatch(UIAction.setError(Request.SEARCH_FILTER, error.message));
    toast.error(error.message);
  } finally {
    dispatch(UIAction.clearLoading(Request.SEARCH_FILTER));
  }
};

export const filterData = (id, data, isPublic) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.FILTER_DATA));
  dispatch(UIAction.clearError(Request.FILTER_DATA));

  try {
    const response = await (isPublic ? Api.filterSearchPublic(id, data) : Api.filterSearch(id, data));
    dispatch(setFilterData(response.result));
    dispatch(CartAction.setFiterSearch(data));
    dispatch(CommonAction.setFilterVisibility("filter", response.result));
  } catch (error) {
    dispatch(UIAction.setError(Request.FILTER_DATA, error.message));
    toast.error(error.message);
  } finally {
    dispatch(UIAction.clearLoading(Request.FILTER_DATA));
  }
};

export const clearFilterSearch = () => async (dispatch, getState) => {
  dispatch(CommonAction.setFilterVisibility("filter", []));
  dispatch(setFilterData(null));
};

const mapFilters = descriptors => {
  let filters = {
    substructure: {
      id: "substructure",
      title: "Substructure Search",
      category: "substructure"
    },
    similarity: {
      id: "similarity",
      title: "Similarity Search",
      category: "similarity"
    }
  };

  for (let descriptorName in descriptors) {
    let filterData = descriptors[descriptorName];

    if (filterData.category === "numerical") {
      filters[descriptorName] = {
        id: descriptorName,
        descriptorId: filterData.id,
        title: filterData.title,
        range: filterData.range,
        category: "numerical",
        type: filterData.type,
      };
    } else if (filterData.category === "categorical") {
      let labels = filterData.labels;

      filters[descriptorName] = {
        id: descriptorName,
        descriptorId: filterData.id,
        title: filterData.title,
        labels,
        category: "categorical",
        type: filterData.type,
      };
    }
  }
  return filters;
}

const setSearchFilters = data => ({ type: Type.SET_SEARCH_FILTERS, data });

const setFilterData = data => ({ type: Type.SET_FILTER_DATA, data });
