import io from "socket.io-client";
import { getToken } from "./storage";
import store from "../store";
import { Action as DatasetsAction } from "../store/datasets";
import { Action as TMAPsAction } from "../store/tmaps";
import { Action as CartAction } from "../store/cart";
import { toast } from "react-toastify";

let socket;

export const connect = () => {
  console.log("WS CONNECTING, ", process.env.REACT_APP_WS_BASE);
  socket = io(process.env.REACT_APP_WS_BASE, {
    query: { token: getToken() },
  });

  socket.on("connect", () => {
    console.log("WS CONNECTED");
  });

  socket.on("error", (err) => {
    console.log("WS ERROR", err);
  });

  socket.on("event", (data) => {
    console.log("WS EVENT", data);
    switch (data?.type) {
      case "PROCESS_DATASET_START":
        store.dispatch(DatasetsAction.update(data.dataset));
        break;
      case "PROCESS_DATASET_PROGRESS":
        store.dispatch(DatasetsAction.updateProgress(data.data));
        break;
      case "PROCESS_DATASET_DONE":
        store.dispatch(DatasetsAction.update(data.dataset));
        toast.info(`Processing of dataset ${data.dataset.name} is complete`);
        break;
      case "PROCESS_TMAP_START":
        store.dispatch(TMAPsAction.update(data.tmap));
        break;
      case "PROCESS_TMAP_PROGRESS":
          store.dispatch(TMAPsAction.updateProgress(data.data));
          break;
      case "PROCESS_TMAP_DONE":
        store.dispatch(TMAPsAction.update(data.tmap));
        toast.info(`Processing of TMAP ${data.tmap.name} is complete`);
        break;
      case "CART_REPORT":
        store.dispatch(CartAction.setReport(data.data));
        break;
      default:
        console.log("Unknown event", data);
    }
  });
};
