import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearch,
  faFilter,
  faBars,
  faCamera,
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faKey,
  faEye,
  faShoppingCart,
  faUserLock,
  faUser,
  faLock,
  faToggleOff,
  faToggleOn,
  faAngleLeft,
  faAngleRight,
  faCheck,
  faShareAlt,
  faCopy,
  faSync,
  faStop,
  faPlay,
  faPencilRuler,
  faSearchLocation,
  faCaretUp,
  faCaretDown,
  faPen,
  faChartBar,
  faList,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faInfoCircle,
  faDownload,
  faAt,
} from "@fortawesome/free-solid-svg-icons";

library.add(
  faSearch,
  faFilter,
  faBars,
  faCamera,
  faPlus,
  faEdit,
  faTrash,
  faTimes,
  faKey,
  faEye,
  faShoppingCart,
  faUserLock,
  faUser,
  faLock,
  faToggleOff,
  faToggleOn,
  faAngleLeft,
  faAngleRight,
  faCheck,
  faShareAlt,
  faCopy,
  faSync,
  faStop,
  faPlay,
  faPencilRuler,
  faSearchLocation,
  faCaretUp,
  faCaretDown,
  faPen,
  faChartBar,
  faList,
  faSort,
  faSortAlphaDown,
  faSortAlphaUp,
  faInfoCircle,
  faDownload,
  faAt,
);
