import * as Api from "../../utils/api";

const USERS = "users";

export const filter = async () => Api.get(USERS);

export const update = async dataset => Api.put(`${USERS}/${dataset.id}`, dataset);

export const remove = async id => Api.del(`${USERS}/${id}`);

export const create = async data => Api.post(USERS, data);

export const enableDisable = async id => Api.post(`${USERS}/${id}/toggle-enabled`);
