import React, { useEffect, useState } from "react";
import { Request } from "../../store/ui";
import { useSelector, useDispatch } from "react-redux";
import { Action as TMAPsAction } from "../../store/tmaps";
import { Table, Container, ButtonGroup, ProgressBar, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import StateBadge from "../StateBadge";
import { Helpers as UserHelpers } from "../../store/users";
import ConfirmationModal from "../ConfirmationModal";
import Share from "./Share";
import ProcessingLog from "../ProcessingLog";
import SortableHeader from "../SortableHeader";

const FilterTMAPs = () => {
  const loading = useSelector((state) => state.ui.loading[Request.TMAPS_FETCH]);
  const error = useSelector((state) => state.ui.error[Request.TMAPS_FETCH]);
  const processing = useSelector((state) => state.ui.loading[Request.TMAP_PROCESSING]);
  const downloading = useSelector((state) => state.ui.loading[Request.TMAP_DOWNLOAD]);
  const tmaps = useSelector((state) => state.tmaps);
  const user = useSelector((state) => state.auth);
  const [sorts, setSorts] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(TMAPsAction.filterTMAPs());
  }, [dispatch]);

  const canAdd = UserHelpers.canEditTmaps(user);

  const canEdit = (tmap) => UserHelpers.isAdmin(user) || tmap.created_by.id === user.id;

  const canStop = (tmap) => tmap.state.startsWith("processing") || tmap.state.startsWith("pending");

  const canStart = (tmap) => tmap.state.startsWith("cancelled") || tmap.state.startsWith("error");

  const stopProcessing = (tmap) => dispatch(TMAPsAction.stopProcessing(tmap.id));

  const processTMAP = (tmap) => dispatch(TMAPsAction.startProcessing(tmap.id));

  const updateSorts = (e) => {
    const name = e.currentTarget.dataset.name;
    let sort = sorts.find((s) => s[0] === name);
    if (!sort) {
      setSorts([...sorts, [name, "ASC"]]);
    } else if (sort[1] === "ASC") {
      setSorts(sorts.map((s) => (s[0] === name ? [name, "DESC"] : s)));
    } else {
      setSorts(sorts.filter((s) => s[0] !== name));
    }
  };

  const sortTMAPs = () => {
    if (!sorts || !sorts.length) return tmaps;
    const sorted = [...tmaps].sort((a, b) => {
      let sort = 0;
      sorts.forEach((s) => {
        let lowerA = a[s[0]].toLowerCase();
        let lowerB = b[s[0]].toLowerCase();
        if (lowerA !== lowerB && !sort) {
          if (s[1] === "ASC") sort = lowerA > lowerB ? 1 : -1;
          else sort = lowerA < lowerB ? 1 : -1;
        }
      });
      return sort;
    });
    return sorted;
  };

  const download = ({ currentTarget }) => {
    const id = parseInt(currentTarget.dataset.tmap);
    const tmap = tmaps.find((t) => t.id === id);
    if (tmap) dispatch(TMAPsAction.downloadTmap(tmap));
  };

  return (
    <Container>
      <h1 className="mb-3">TMAPs</h1>

      {canAdd && (
        <Link to="/tmaps/new" type="button" className="btn btn-success mb-3">
          <FontAwesomeIcon icon="plus" /> Create new TMAP
        </Link>
      )}

      <Table hover>
        <thead className="thead-light">
          <tr>
            <th>#</th>
            <SortableHeader name="name" label="Name" sorts={sorts} onChange={updateSorts} />
            <SortableHeader name="dataset" label="Dataset" sorts={sorts} onChange={updateSorts} />
            <th>Created by</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="6">Loading...</td>
            </tr>
          )}
          {!loading && error && (
            <tr>
              <td colSpan="6">{error}</td>
            </tr>
          )}
          {!loading && !error && tmaps && !tmaps.length && (
            <tr>
              <td colSpan="6">No TMAPs</td>
            </tr>
          )}
          {!loading &&
            !error &&
            tmaps &&
            tmaps.length > 0 &&
            sortTMAPs().map((tmap) => (
              <tr key={tmap.id}>
                <td>{tmap.id}</td>
                <td>{tmap.name}</td>
                <td>{tmap.dataset}</td>
                <td>{tmap.created_by.name}</td>
                <td>
                  <StateBadge state={tmap.state} />
                  {tmap.progress && <ProgressBar now={tmap.progress} label={`${tmap.progress}%`} />}
                </td>
                <td>
                  <ButtonGroup aria-label="TMAP Actions">
                    {tmap.state === "done" && (
                      <Link to={`/tmaps/${tmap.id}/view`} className="btn btn-light">
                        <FontAwesomeIcon icon="eye" />
                      </Link>
                    )}
                    {canEdit(tmap) && (
                      <>
                        <Link to={`/tmaps/${tmap.id}/edit`} className="btn btn-primary">
                          <FontAwesomeIcon icon="edit" />
                        </Link>
                        <Link to={`/tmaps/${tmap.id}/delete`} className="btn btn-danger">
                          {" "}
                          <FontAwesomeIcon icon="trash" />
                        </Link>
                        {canStop(tmap) && (
                          <ConfirmationModal
                            variant="warning"
                            label={<FontAwesomeIcon icon="stop" />}
                            onConfirm={() => stopProcessing(tmap)}
                            title="Stop processing"
                            content={`Are you sure you want to stop processing ${tmap.name}?`}
                            submitting={processing}
                          />
                        )}
                        {canStart(tmap) && (
                          <ConfirmationModal
                            variant="warning"
                            label={<FontAwesomeIcon icon="play" />}
                            onConfirm={() => processTMAP(tmap)}
                            title="Process tmap"
                            content={`Are you sure you want to process ${tmap.name}?`}
                            submitting={processing}
                          />
                        )}
                        <Share variant="info" label={<FontAwesomeIcon icon="share-alt" />} tmap={tmap} />
                        <ProcessingLog tmapId={tmap.id} />
                        {tmap.state === "done" && (
                          <Button variant="info" title="Download for offline use" data-tmap={tmap.id} onClick={download} disabled={downloading}>
                            <FontAwesomeIcon icon="download" />
                          </Button>
                        )}
                      </>
                    )}
                  </ButtonGroup>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default FilterTMAPs;
