import React, { useState } from "react";
import { Form, Button, Alert, Row, Col, Modal } from "react-bootstrap";
import { Request } from "../../store/ui";
import { useSelector } from "react-redux";

const DatasetForm = ({ dataset, onChange, onSubmit, onCancel }) => {
  const submitting = useSelector((state) => state.ui.loading[Request.DATASET_SAVE]);

  const [validated, setValidated] = useState(false);
  const [descriptorsValid, setDescriptorsValid] = useState(true);

  const submit = (e) => {
    e.preventDefault();
    setValidated(true);
    if (!dataset.id && !dataset.descriptors?.some((d) => d.selected)) {
      setDescriptorsValid(false);
    }
    if (e.target.checkValidity() && descriptorsValid) {
      onSubmit();
    }
  };

  const change = ({ target }) => {
    const { name } = target;
    const value = name === "smiles_file" ? target.files[0] : target.value;
    onChange(name, value);
  };

  const toggleFromCsv = () => onChange("from_csv", !dataset.from_csv);

  const toggleDescriptor = ({ target }) => {
    const { name } = target;
    onChange(
      "descriptors",
      dataset.descriptors.map((d) => (d.id === name ? { ...d, selected: !d.selected } : d))
    );
  };

  return (
    <Form noValidate validated={validated} onSubmit={submit}>
      <legend>Basic data</legend>
      <Form.Group>
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" name="name" required value={dataset.name} onChange={change} />
        <Form.Control.Feedback type="invalid">Please enter valid name</Form.Control.Feedback>
      </Form.Group>
      {!dataset.id && (
        <>
          {!onCancel && (
            <>
              <legend>Dataset information</legend>
              <Form.Group>
                <Form.Label>SMILES file</Form.Label>
                <Form.Control type="file" name="smiles_file" required onChange={change} />
                <Form.Control.Feedback type="invalid">Please select file</Form.Control.Feedback>
              </Form.Group>
              <Form.Group>
                <label>
                  <input name="from_csv" type="checkbox" checked={dataset.from_csv} onChange={toggleFromCsv} />
                  <span className="ml-2">Is a CSV file</span>
                </label>
                <Form.Text className="text-muted">
                  Molecules will be checked during processing and give error if there are no valid SMILES. Also notice that invalid molecules will be ignored.
                  <br />
                  <strong>When left unchecked:</strong> A list with the SMILES of molecules, one each line. <br />
                  <strong>When checked:</strong> A comma-separated file (CSV), whose first column <strong>must</strong> be the SMILES, and the following columns are going to be added as custom
                  descriptors, the type of which is going to be determined by Pandas. The first row must be the field names, which will appear in the TMAP.
                </Form.Text>
              </Form.Group>
              {dataset.from_csv && (
                <Form.Group>
                  <Form.Label>CSV Separator</Form.Label>
                  <Form.Control type="text" name="csv_separator" onChange={change} placeholder="," />
                </Form.Group>
              )}
            </>
          )}
          <legend className="mt-5">Descriptors</legend>
          <label>Calculated descriptors</label>
          {!descriptorsValid && <Alert variant="danger">Please select at least one descriptor</Alert>}
          <Form.Group>
            <Row>
              {dataset.descriptors.map((descriptor) => (
                <Col sm={3} key={descriptor.id}>
                  <label className="my-2">
                    <input type="checkbox" name={descriptor.id} checked={descriptor.selected} onChange={toggleDescriptor} />
                    <span className="ml-2">{descriptor.title}</span>
                  </label>
                </Col>
              ))}
            </Row>
            <Form.Text className="text-muted mt-3">
              Descriptors to be calculated for all molecules. Notice that the more descriptors are selected, the creation and render time will be larger.
            </Form.Text>
          </Form.Group>
        </>
      )}

      <legend className="mt-5">LSH and MinHash options</legend>

      {dataset.id && (
        <Alert variant="warning">
          <strong>Caution: </strong>Modifying these fields require the complete recalculation of the dataset and any TMAPs associated.
        </Alert>
      )}
      <Row>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>MinHash dimensions</Form.Label>
            <Form.Control type="number" name="minhash_dimensions" required value={dataset.minhash_dimensions} onChange={change} />
            <Form.Control.Feedback type="invalid">Please enter valid min hash dimensions</Form.Control.Feedback>
          </Form.Group>
        </Col>
        <Col sm={6}>
          <Form.Group>
            <Form.Label>Num. LSH forest trees</Form.Label>
            <Form.Control type="number" name="lsh_forest_trees" required value={dataset.lsh_forest_trees} onChange={change} />
            <Form.Control.Feedback type="invalid">Please enter valid lsh forest trees</Form.Control.Feedback>
          </Form.Group>
        </Col>
      </Row>
      {!onCancel && (
        <Form.Group className="form-group mt-5">
          <Button type="submit" variant="primary" disabled={submitting}>
            Submit
          </Button>
        </Form.Group>
      )}
      {onCancel && (
        <Modal.Footer>
          <Button variant="warning" onClick={onCancel}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Next
          </Button>
        </Modal.Footer>
      )}
    </Form>
  );
};

export default DatasetForm;
