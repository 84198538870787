import React from "react";
import classes from "./index.module.css";
import RemoveFilterButton from "../RemoveFilterButton";
import Button from "../../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";
import { Action as UIAction } from "../../../store/ui";

const NumericalFilter = ({ filter, onChange, value, onRemove }) => {
  const dispatch = useDispatch();

  const defaultMin = Math.round(filter.range[0] * 1000) / 1000;

  const defaultMax = Math.round(filter.range[1] * 1000) / 1000;

  const onChangeHandler = ({ target }) => {
    const newValue = value ? { ...value } : { type: "range" };
    if (target.name === "invert") {
      newValue[target.name] = target.checked;
    } else {
      newValue[target.name] = target.value;
    }
    onChange(filter.id, newValue);
  };

  const openHistogramEditor = () =>
    dispatch(
      UIAction.openHistogramSelector(
        { id: filter.descriptorId, min: defaultMin, max: defaultMax, value: { min: value?.min, max: value?.max }, type: filter.type },
        onConfirm
      )
    );

  const onConfirm = (data) => {
    const newValue = value ? { ...value, ...data } : { type: "range", ...data };
    onChange(filter.id, newValue);
  };

  return (
    <div className={classes.NumericalFilter}>
      <RemoveFilterButton name={filter.id} onClick={onRemove} />
      <label className={classes.Label}>{filter.title} filter</label>
      <div className={classes.InputContainer}>
        <input type="number" name="min" placeholder={defaultMin} className={classes.Input} onChange={onChangeHandler} value={value?.min || ""} />
        <label className={classes.FilterDivider}>-</label>
        <input type="number" name="max" placeholder={defaultMax} className={classes.Input} onChange={onChangeHandler} value={value?.max || ""} />
      </div>
      <div className={classes.InvertHistogramContainer}>
        <label className="small mt-1 mb-0 d-flex align-items-center">
          <input type="checkbox" className="mr-1" value={value?.invert || ""} name="invert" onChange={onChangeHandler} />
          Invert filter
        </label>
        <Button size="Small" className="mt-1" onClick={openHistogramEditor}>
          <FontAwesomeIcon icon="chart-bar" />
        </Button>
      </div>
    </div>
  );
};

export default NumericalFilter;
