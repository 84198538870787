import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Button } from "react-bootstrap";
import { useParams, useHistory } from "react-router";
import { Request } from "../../store/ui";
import { Action as TMAPsAction } from "../../store/tmaps";

const DeleteTMAP = () => {

  const id = parseInt(useParams().id);
  const loading = useSelector(state => state.ui.loading[Request.TMAP_FETCH]);
  const error = useSelector(state => state.ui.error[Request.TMAP_FETCH]);
  const tmap = useSelector(state => state.tmaps.find(tmap => tmap.id === id));
  const deleting = useSelector(state => state.ui.loading[Request.TMAP_REMOVE]);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => { dispatch(TMAPsAction.fetchTMAP(id)); }, [dispatch, id]);

  const onSubmit = async () => {
    const status = await dispatch(TMAPsAction.removeTMAP(tmap));
    if(status) {
      history.push("/tmaps");
    }
  }

  if(error) {
    return error;
  }

  if(loading || !tmap) {
    return "Loading...";
  }

  return (
    <Container>
      <h1 className="mb-5">Delete tmap '{tmap.name}'</h1>
      <p>Do you want to delete TMAP '{tmap.name}'?</p>
     <Button disabled={deleting} variant="danger" onClick={onSubmit}>Delete</Button>
    </Container>
  );
};

export default DeleteTMAP;
