import React, { useState } from "react";
import classes from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import { Card, Alert, FormControl, Form } from "react-bootstrap";
import { Request } from "../../store/ui";
import { Action as AuthAction } from "../../store/auth";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

const ForgotPassword = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [validated, setValidated] = useState(false);

  const loading = useSelector((state) => state.ui.loading[Request.AUTH_FORGOT_PASSWORD]);
  const error = useSelector((state) => state.ui.error[Request.AUTH_FORGOT_PASSWORD]);

  const changeHandler = (e) => setEmail(e.target.value);

  const submit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      const success = await dispatch(AuthAction.forgotPassword(email));
      if (success) {
        toast.success("E-mail send successfully");
      }
    }
  };

  return (
    <div className={classes.ResetPassword}>
      <div className={classes.FormContainer}>
        <Card>
          <Card.Header>
            <FontAwesomeIcon icon="key" />
            <span className="ml-3">Forgotten password</span>
          </Card.Header>
          <Card.Body>
            <Form noValidate onSubmit={submit} validated={validated}>
              <Form.Group>
                <FormControl type="email" placeholder="E-mail" name="email" required value={email} onChange={changeHandler} />
                <Form.Control.Feedback type="invalid">Please enter valid email</Form.Control.Feedback>
              </Form.Group>

              <button type="submit" className="btn btn-outline-primary btn-block" disabled={loading}>
                Request new password
              </button>
              <div className="text-right my-3">
                <Link to="/login">Login</Link>
              </div>
              {error && (
                <Alert variant="danger" className="mt-3">
                  {error}
                </Alert>
              )}
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
};

export default ForgotPassword;
