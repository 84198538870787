import React from "react";
import classes from "./index.module.css";
import RemoveFilterButton from "../RemoveFilterButton";

const CategoricalFilter = ({ filter, onChange, value, onRemove }) => {
  const changeHandler = ({ target }) => {
    const { name } = target;
    let vals = [];
    if (value && value.vals) {
      if (value.vals.some((value) => value === name)) {
        vals = value.vals.filter((value) => value !== name);
      } else {
        vals = [...value.vals, name];
      }
    } else {
      vals = [name];
    }
    onChange(filter.id, { type: filter.id, vals });
  };
  return (
    <div className={classes.CategoricalFilter}>
      <RemoveFilterButton name={filter.id} onClick={onRemove} />
      <label className={classes.Label}>{filter.title} filter</label>
      {filter.labels.map((label) => (
        <div className={classes.Option} key={label[0]}>
          <label className={classes.OptionLabel}>
            <input type="checkbox" className={classes.Checkbox} name={label[1] + ""} onChange={changeHandler} />
            {label[1] + ""}
          </label>
        </div>
      ))}
    </div>
  );
};

export default CategoricalFilter;
