import React, { useState } from "react";
import { Modal, Button, Form, Alert, Table, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { Request } from "../../store/ui/actions";
import { Permissions } from "../../store/users/helpers";
import { Helpers as UserHelpers } from "../../store/users";

import classes from "./index.module.css";

const UserForm = ({ user, onChange, onSubmit, onCancel }) => {
  const [validated, setValidated] = useState(false);

  const submitting = useSelector((state) => state.ui.loading[Request.USER_SAVE]);
  const error = useSelector((state) => state.ui.error[Request.USER_SAVE]);

  const submit = async (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      onSubmit();
    }
  };

  const changeHandler = ({ target }) => {
    const { name, value } = target;
    if(target.type === "checkbox") {
      onChange(name, !user[name])
    } else {
      onChange(name, value);
    }
  };

  const onTogglePermission = ({ target }) => {
    if (UserHelpers.isAdmin(user)) return;
    const { name } = target;
    const permissions = { ...user.permissions };
    permissions[name] = !permissions[name];
    if (name === Permissions.VIEW_DATASETS && !permissions[name]) permissions[Permissions.EDIT_DATASETS] = false;
    if (name === Permissions.EDIT_DATASETS && permissions[name]) permissions[Permissions.VIEW_DATASETS] = true;
    if (name === Permissions.VIEW_TMAPS && !permissions[name]) permissions[Permissions.EDIT_TMAPS] = false;
    if (name === Permissions.EDIT_TMAPS && permissions[name]) permissions[Permissions.VIEW_TMAPS] = true;
    onChange("permissions", permissions);
  };

  const getVariant = (permission) => {
    if (UserHelpers.isAdmin(user) || user.permissions[permission]) return "success";
    return "danger";
  };

  const getIcon = (permission) => {
    if (UserHelpers.isAdmin(user) || user.permissions[permission]) return "check";
    return "times";
  };

  const renderPermissionButton = (permission) => (
    <Button name={permission} variant={getVariant(permission)} size="sm" onClick={onTogglePermission}>
      <FontAwesomeIcon icon={getIcon(permission)} className={classes.Icon} />
    </Button>
  );

  return (
    <Form noValidate onSubmit={submit} validated={validated}>
      <Modal.Body>
        {!user.id && (
          <Form.Group>
            <Form.Label>Username</Form.Label>
            <Form.Control type="email" name="username" required onChange={changeHandler} value={user.username} />
            <Form.Control.Feedback type="invalid">Please enter valid username</Form.Control.Feedback>
          </Form.Group>
        )}
        <Form.Group>
          <Form.Label>Name</Form.Label>
          <Form.Control type="text" name="name" required onChange={changeHandler} value={user.name} />
          <Form.Control.Feedback type="invalid">Please enter name</Form.Control.Feedback>
        </Form.Group>
        <Form.Group>
          <Form.Label>Role</Form.Label>
          <Form.Control as="select" name="role" required onChange={changeHandler} value={user.role}>
            <option value="ADMIN">Administrator</option>
            <option value="USER">User</option>
          </Form.Control>
          <Form.Control.Feedback type="invalid">Please enter name</Form.Control.Feedback>
        </Form.Group>
        <Row>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>TMAP limit</Form.Label>
              <Form.Control type="text" name="tmap_limit" onChange={changeHandler} value={user.tmap_limit} />
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group>
              <Form.Label>Disable user share</Form.Label>
              <Form.Control type="checkbox" name="disable_user_share" onChange={changeHandler} checked={user.disable_user_share} />
            </Form.Group>
          </Col>
        </Row>

        <Table className="mt-4">
          <thead className="thead-dark">
            <tr>
              <th>Permissions</th>
              <th>View</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Datasets</td>
              <td>{renderPermissionButton(Permissions.VIEW_DATASETS)}</td>
              <td>{renderPermissionButton(Permissions.EDIT_DATASETS)}</td>
            </tr>
            <tr>
              <td>TMAPS</td>
              <td>{renderPermissionButton(Permissions.VIEW_TMAPS)}</td>
              <td>{renderPermissionButton(Permissions.EDIT_TMAPS)}</td>
            </tr>
          </tbody>
        </Table>
        {error && <Alert variant="danger">{error}</Alert>}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="warning" type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button variant="primary" type="submit" disabled={submitting}>
          Save
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default UserForm;
