export const saveTextFileFromArray = (array, filename) => {
  let textBlob = new Blob([array.join("\n")], { type: "text/plain;charset=utf-8" });
  var a = document.createElement("a");
  a.download = filename;
  a.href = window.URL.createObjectURL(textBlob);
  a.click();
}

export const saveBinaryFile = (data, filename) => {
  let textBlob = new Blob(data, { type: "application/octet-stream" });
  var a = document.createElement("a");
  a.download = filename;
  a.href = window.URL.createObjectURL(textBlob);
  a.click();
}