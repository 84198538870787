import React, { useEffect } from "react";
import { Request } from "../../store/ui";
import { useDispatch, useSelector } from "react-redux";
import { Action as UsersAction } from "../../store/users";
import { Table, Container, ButtonGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from "../ConfirmationModal";
import CreateUser from "./create";
import EditUser from "./edit";

const UserManagement = () => {
  const loading = useSelector((state) => state.ui.loading[Request.USERS_FETCH]);
  const disabling = useSelector((state) => state.ui.loading[Request.USER_ENABLE_DISABLE]);
  const removing = useSelector((state) => state.ui.loading[Request.USER_REMOVE]);
  const error = useSelector((state) => state.ui.error[Request.USERS_FETCH]);

  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(UsersAction.filterUsers());
  }, [dispatch]);

  const remove = (user) => dispatch(UsersAction.removeUser(user));

  const toggleEnabled = (user) => dispatch(UsersAction.enableDisableUser(user));

  return (
    <Container>
      <h1 className="mb-3">User management</h1>

      <CreateUser
        className="mb-3"
        label={
          <>
            <FontAwesomeIcon icon="plus" /> Create new user
          </>
        }
        variant="success"
      />

      <Table hover>
        <thead className="thead-light">
          <tr>
            <th>#</th>
            <th>Username</th>
            <th>Name</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan="5">Loading...</td>
            </tr>
          )}
          {!loading && error && (
            <tr>
              <td colSpan="5">{error}</td>
            </tr>
          )}
          {!loading && !error && users && !users.length && (
            <tr>
              <td colSpan="4">No users</td>
            </tr>
          )}
          {!loading &&
            !error &&
            users &&
            users.length > 0 &&
            users.map((user) => (
              <tr key={user.id}>
                <td>{user.id}</td>
                <td>{user.username}</td>
                <td>{user.name}</td>
                <td>{user.role}</td>
                <td>
                  <ButtonGroup aria-label="User actions">
                    <EditUser label={<FontAwesomeIcon icon="edit" />} variant="primary" data={user} />
                    <ConfirmationModal
                      label={<FontAwesomeIcon icon="user-lock" />}
                      variant={user.enabled ? "success" : "danger"}
                      title={user.enabled ? "Disable user" : "Enable user"}
                      content={`Are you sure you want to ${user.enabled ? "disable":"enable"} ${user.name}`}
                      onConfirm={() => toggleEnabled(user)}
                      submitting={disabling}
                    />
                    <ConfirmationModal
                      label={<FontAwesomeIcon icon="trash" />}
                      variant="danger"
                      title="Delete user"
                      content={`Are you sure you want to delete ${user.name}`}
                      onConfirm={() => remove(user)}
                      submitting={removing}
                    />
                  </ButtonGroup>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default UserManagement;
