import React from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Action as FaerunAction } from "../../../store/faerun";
import Select from "../../Select";

const SeriesSelector = ({ data }) => {
  const dispatch = useDispatch();

  const changeSeriesHandler = ({ target }) => {
    let name = target.name;
    let value = target.value === "" ? undefined : parseInt(target.value);
    dispatch(FaerunAction.changeSeries(name, value));
  };

  let values = useSelector(state => state.faerun.seriesState.descriptors);

  let allSeries = data.series_title.map((s,i) => ({ ...s, index: i }));
  let colorSeries = allSeries.filter((t) => t.type === "color");
  let sizeSeries = allSeries.filter((t) => t.type === "size");
  let transparencySeries = allSeries.filter((t) => t.type === "transparency");

  return (
    <Row>
      {!!colorSeries.length && (
        <Col sm={12}>
          <div className="small">Color</div>
          <Select onChange={changeSeriesHandler} name="color" value={values.color}>
            {colorSeries.map((option) => (
                <option key={option.id} value={option.index}>
                  {option.title}
                </option>
              ))}
          </Select>
        </Col>
      )}
      {!!sizeSeries.length && (
        <Col sm={6}>
          <div className="small">Size</div>
          <Select onChange={changeSeriesHandler} value={values.size || ""} name="size">
            <option value="">
              None
            </option>
            {sizeSeries.map((option) => (
                <option key={option.id} value={option.index}>
                  {option.title}
                </option>
              ))}
          </Select>
        </Col>
      )}
      {!!transparencySeries.length && (
        <Col sm={6}>
          <div className="small">Transparency</div>
          <Select onChange={changeSeriesHandler} value={values.transparency || ""} name="transparency">
            <option value="">
              None
            </option>
            {transparencySeries.map((option) => (
                <option key={option.id} value={option.index}>
                  {option.title}
                </option>
              ))}
          </Select>
        </Col>
      )}
    </Row>
  );
};

export default SeriesSelector;
