import React from "react";
import { useSelector } from "react-redux";
import CategorySeriesLegend from "./category";
import RangeSeriesLegend from "./range";

const SeriesLegend = ({ data }) => {

  const seriesState = useSelector(state => state.faerun.seriesState);
    
  const getSeriesStateIndex = name => (name === "descriptors" ? seriesState[name].color : seriesState[name]);

  const hasColorBox = data.name !== 'filter' && data.name !== 'nn_search' && data.name !== 'scroll_selection';
  const isCategory = hasColorBox && !data.is_range[getSeriesStateIndex(data.name)] 
  const hasRangeData = data.legend[getSeriesStateIndex(data.name)].length > 1;

  if (isCategory) return <CategorySeriesLegend data={data.legend[getSeriesStateIndex(data.name)]} />
   else if(hasRangeData) return (
    <RangeSeriesLegend
      data={data.legend[getSeriesStateIndex(data.name)]}
      min={data.min_legend_label[getSeriesStateIndex(data.name)]}
      max={data.max_legend_label[getSeriesStateIndex(data.name)]}
      limits={data.series_title[getSeriesStateIndex(data.name)].limits}
    />);

   return null;
};

export default SeriesLegend;
