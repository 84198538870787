import { toast } from "react-toastify";
import { Request, Action as UIAction } from "../ui";
import { Action as CommonAction } from "../common";
import { Action as CartAction } from "../cart";
import { Api } from "../tmaps"; 

export const Type = {
  SET_NN_SEARCH_RESULT: "FAERUN_SET_NN_SEARCH_RESULT",
};

export const fetchNNSearch = (id, data, similarity, isPublic) => async (dispatch, getState) => {
  dispatch(UIAction.setLoading(Request.NN_SEARCH));
  dispatch(UIAction.clearError(Request.NN_SEARCH));

  try {
    const response = await (isPublic ?  Api.nnSearchPublic(id, data, similarity) : Api.nnSearch(id, data, similarity));
    dispatch(setNNSearchResult(response.result));
    dispatch(CartAction.setNnSearch({ data, similarity }));
    dispatch(CommonAction.setFilterVisibility("nn_search", Object.keys(response.result)));
  } catch (error) {
    dispatch(UIAction.setError(Request.NN_SEARCH, error.message));
    toast.error(error.message);
  } finally {
    dispatch(UIAction.clearLoading(Request.NN_SEARCH));
  }
};

export const clearNNSearch = () => async (dispatch, getState) => {
  dispatch(CommonAction.setFilterVisibility("nn_search", []));
  dispatch(setNNSearchResult(null));
};

const setNNSearchResult = data => ({ type: Type.SET_NN_SEARCH_RESULT, data });
