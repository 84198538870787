import { toast } from "react-toastify";
import { Request, Action as UIAction } from "../ui";
import * as Helpers from "../faerun/helpers";
import * as Api from "../tmaps/api"; 

export const Type = {
  SET_DATA: "MOLECULE_SET_DATA",
};

export const fetchMoleculeData = num => async (dispatch, getState) => {
  const { tmapId, isPublic } = getState().faerun;
  if(getState().molecule[num]) return;
  dispatch(setData({ index: num, loading: true }));
  try {
    dispatch(UIAction.setLoading(Request.TMAP_MOLECULES));
    dispatch(UIAction.clearError(Request.TMAP_MOLECULES));

    let response = await (isPublic ? Api.getMoleculesDataPublic(tmapId, num) : Api.getMoleculesData(tmapId, num));
    const data = {
      smiles: response.smiles,
      index: response.num,
      descriptors: Helpers.getDescriptorsFromApi(response.descriptors, getState().filterSearch.filters),
    }
    dispatch(setData(data))
  } catch (error) {
    dispatch(UIAction.setError(Request.TMAP_MOLECULES, error.message));
    toast.error(error.message);
    dispatch(setData({ index: num, error: error.message }));
    return false;
  } finally {
    dispatch(UIAction.clearLoading(Request.TMAP_MOLECULES));
  }
};

export const setData = data => ({ type: Type.SET_DATA, data });
