const DESCRIPTORS = [
   { id: "molwt", title: "Molecular weight", selected: true},
   { id: "hac", title: "Heavy atom count", selected: false},
   { id: "clogp", title: "cLogP", selected: true},
   { id: "hbd", title: "Hydrogen bond donors", selected: false},
   { id: "hba", title: "Hydrogen bond acceptors", selected: false},
   { id: "num_rings", title: "Number of rings", selected: false},
   { id: "num_aromatic_rings", title: "Number of aromatic rings", selected: false},
   { id: "num_aliphatic_rings", title: "Number of aliphatic rings", selected: false},
   { id: "num_rot_bonds", title: "Rotatable bonds", selected: false},
   { id: "num_val_electrons", title: "Number of valence electrons", selected: false},
   { id: "num_heteroatoms", title: "Heteroatom count", selected: false},
   { id: "fraction_sp3", title: "Fraction of sp3 carbon atoms", selected: false},
   { id: "tpsa", title: "TPSA", selected: true},
   { id: "num_c_atoms", title: "Carbon atom count", selected: false},
   { id: "ring_aromaticity", title: "Ring aromaticity", selected: false},
   { id: "similarity", title: "Similarity", selected: false},
];

export const getDescriptors = () => DESCRIPTORS.map(d => ({...d }));
