import React from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Action as AuthAction } from "../../store/auth";
import { Helpers as UserHelpers } from "../../store/users";

import ChangePasswordModal from "../ChangePassword/modal";

const Navigation = () => {
  const user = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  const logout = () => dispatch(AuthAction.logout());

  return (
    <Navbar bg="dark" variant="dark" expand="lg">
      <Navbar.Brand as={Link} to="/datasets">
        MolMap
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="molmap-nav" />
      <Navbar.Collapse id="molmap-nav">
        <Nav className="mr-auto w-100">
          {UserHelpers.canViewDatasets(user) && (
            <Nav.Link as={NavLink} to="/datasets">
              Datasets
            </Nav.Link>
          )}
          {UserHelpers.canViewTmaps(user) && (
            <Nav.Link as={NavLink} to="/tmaps">
              TMAPs
            </Nav.Link>
          )}
          {UserHelpers.isAdmin(user) && (
            <Nav.Link as={NavLink} to="/users">
              User management
            </Nav.Link>
          )}
          <a className="nav-link" href={process.env.REACT_APP_API_BASE} target="_blank" rel="noopener noreferrer">
            API docs
          </a>
          <NavDropdown title={user.name} className="ml-auto mr-sm-2" alignRight>
            <NavDropdown.Item as={ChangePasswordModal} label="Change Password"></NavDropdown.Item>
            <NavDropdown.Item as="button" onClick={logout}>
              Logout
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
