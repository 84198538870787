import React, { useState } from "react";
import classes from "./index.module.css";
import classnames from "classnames";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NearestNeighborSearch from "../NearestNeighborSearch";
import MqnSearch from "../MqnSearch";
import Ppb2Search from "../Ppb2Search";

const hasMqnSearch = !!process.env.REACT_APP_MQN_BASE;
const hasPpb2Search = !!process.env.REACT_APP_PPB2_URL;

const LeftControls = ({ isPublic }) => {
  const [showControls, setShowControls] = useState(true);
  const [filter, setFilter] = useState({ nn: false, mqn: false, ppb2: false });

  const toggleControls = () => setShowControls(!showControls);

  const toggleNNSearch = () => setFilter({ nn: !filter.nn, mqn: false, ppb2: false });

  const toggleMqn = () => setFilter({ nn: false, mqn: !filter.mqn, ppb2: false });

  const togglePpb2 = () => setFilter({ nn: false, mqn: false, ppb2: !filter.ppb2 });

  return (
    <>
      <div className={classnames(classes.Controls, classes.Left)}>
        <Button variant="link" onClick={toggleControls}>
          <FontAwesomeIcon icon="bars" />
        </Button>
        {showControls && (
          <div id={classes.MoreControls}>
            {hasPpb2Search && (
              <Button variant="link" className={classes.IconLabelButton} onClick={togglePpb2}>
                <span className={classes.Label}>PPB</span>
                <FontAwesomeIcon icon="search" />
              </Button>
            )}
            {hasMqnSearch && (
              <Button variant="link" className={classes.IconLabelButton} onClick={toggleMqn}>
                <span className={classes.Label}>MQN</span>
                <FontAwesomeIcon icon="search" />
              </Button>
            )}
            <Button variant="link" className={classes.IconLabelButton} onClick={toggleNNSearch}>
              <span className={classes.Label}>NN</span>
              <FontAwesomeIcon icon="search" />
            </Button>
          </div>
        )}
      </div>
      {filter.nn && <NearestNeighborSearch isPublic={isPublic} />}
      {filter.mqn && <MqnSearch />}
      {filter.ppb2 && <Ppb2Search />}
    </>
  );
};

export default LeftControls;
