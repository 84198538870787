import React, { useState } from "react";
import { useSelector } from "react-redux";
import classnames from "classnames";
import classes from "./index.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Selected from "./Selected";
import Cart from "./Cart";

const Selection = () => {

  const cart = useSelector(state => state.cart);

  const [visible, setVisible] = useState(true);
  const [activeTab, setActiveTab] = useState("selected");

  const toggleVisible = () => setVisible(!visible);
  const activateTab = ({ currentTarget }) => setActiveTab(currentTarget.dataset.tab);
  const activeTabClass = tab => tab === activeTab ? classes.Active : classes.Inactive;

  return (
    <div className={classes.Selection}>
    <div className={classes.Controls}>
      <div className={classes.TabToggle}>
        <button className={classes.Toggle} onClick={toggleVisible}>
          <FontAwesomeIcon icon={visible ? "toggle-on" : "toggle-off"} />
        </button>
      </div>
      <div role="button" className={classnames(classes.Tab, activeTabClass("selected"))} data-tab="selected" onClick={activateTab}>
        <label className={classes.TabLabel}>Selected</label>
      </div>
      <div role="button" className={classnames(classes.Tab, activeTabClass("cart"))} data-tab="cart" onClick={activateTab}>
        <label className={classes.TabLabel}>Cart</label>
        &nbsp;
        <span className={classes.Current}>{cart.current ? cart.current.index + 1 : 0}</span><span> / </span><span className={classes.Total}>{cart.items.length}</span>
      </div>
    </div>
    {visible && activeTab === "selected" && (<Selected />)}
    {visible && activeTab === "cart" && (<Cart />)}
  </div>
  );
};

export default Selection;
