import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Action as AuthAction } from "../../store/auth";
import { Request } from "../../store/ui/actions";
import { useSelector, useDispatch } from "react-redux";

const ChangePassword = () => {
  const [data, setData] = useState({ password: "", newPassword: "", confirmPassword: "" });
  const [validated, setValidated] = useState(false);

  const dispatch = useDispatch();

  const submitting = useSelector(state => state.ui.loading[Request.AUTH_CHANGE_PASSWORD]);
  const error = useSelector(state => state.ui.error[Request.AUTH_CHANGE_PASSWORD]);

  const submit = (e) => {
    e.preventDefault();
    setValidated(true);
    if (e.target.checkValidity()) {
      dispatch(AuthAction.changePassword(data));
    }
  };

  const changeHandler = ({ target }) => {
    const { name, value } = target;
    setData({...data, [name]: value });
  }

  return (
    <Form noValidate validated={validated} onSubmit={submit}>
      <Form.Group>
        <Form.Label>Current password</Form.Label>
        <Form.Control
          type="password"
          name="password"
          required
          onChange={changeHandler}
          value={data.password}
        />
        <Form.Control.Feedback type="invalid">
          Please enter password
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>New password</Form.Label>
        <Form.Control
          type="password"
          name="newPassword"
          required
          onChange={changeHandler}
          value={data.newPassword}
        />
        <Form.Control.Feedback type="invalid">
          Please enter new password
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group>
        <Form.Label>Confirm new password</Form.Label>
        <Form.Control
          type="password"
          name="confirmPassword"
          required
          onChange={changeHandler}
          value={data.confirmPassword}
        />
        <Form.Control.Feedback type="invalid">
          Please enter confirm password
        </Form.Control.Feedback>
      </Form.Group>
      {error && <Alert variant="danger" className="my-3">{error}</Alert>}
      <Form.Group className="form-group mt-1 text-right">
        <Button type="submit" variant="primary" disabled={submitting}>
          Change password
        </Button>
      </Form.Group>
    </Form>
  );
};

export default ChangePassword;
