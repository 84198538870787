import React, { useCallback, useState } from "react";
import { Container } from "react-bootstrap";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { Action as DatasetsAction } from "../../store/datasets";
import { getDescriptors } from "../../store/datasets/helpers";

import DatasetForm from "./form";

const CreateDataset = () => {
  const dispatch = useDispatch();

  const history = useHistory();

  const [dataset, setDataset] = useState({
    name: "",
    minhash_dimensions: 1024,
    lsh_forest_trees: 64,
    from_csv: false,
    descriptors: getDescriptors(),
  });

  const updateDatasetField = useCallback((name, value) => setDataset({ ...dataset, [name]: value }), [dataset]);

  const submitDataset = useCallback(async () => {
    const response = await dispatch(
      DatasetsAction.createDataset({
        ...dataset,
        descriptors: dataset.descriptors.filter((d) => d.selected).map((d) => d.id),
      })
    );
    if (response && response.id) {
      history.push(`/datasets`);
    }
  }, [dataset, history, dispatch]);

  return (
    <Container>
      <h1 className="mb-5">Create a new dataset</h1>
      <DatasetForm onChange={updateDatasetField} onSubmit={submitDataset} dataset={dataset} />
    </Container>
  );
};

export default CreateDataset;
