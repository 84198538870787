import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import { Container } from "react-bootstrap";
import { ToastContainer } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import Datasets from "../Datasets";
import TMaps from "../TMaps";
import Navigation from "../Navigation";
import Login from "../Login";
import { Request } from "../../store/ui";
import { Action as AuthAction } from "../../store/auth";
import { Helpers as UserHelpers } from "../../store/users";

import classes from "./index.module.css";
import UserManagement from "../UserManagement";
import ResetPassword from "../ResetPassword";
import ForgotPassword from "../ForgotPassword";
import TMAP from "../TMAP";
import Signup from "../Signup";

const Main = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(AuthAction.me());
  }, [dispatch]);

  const user = useSelector((state) => state.auth);
  const isLoggedIn = !!user;
  const loading = useSelector((state) => state.ui.loading[Request.AUTH_ME]);

  if (loading === undefined || loading) {
    return (
      <div className={classes.MainSpinnerContainer}>
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <ToastContainer />
      <Router>
        {isLoggedIn && <Navigation />}
        <Container className="my-5">
          {isLoggedIn && (
            <Switch>
              {UserHelpers.canViewDatasets(user) && (
                <Route path="/datasets">
                  <Datasets />
                </Route>
              )}
              {UserHelpers.canViewTmaps(user) && (
                <Route path="/tmaps">
                  <TMaps />
                </Route>
              )}
              {UserHelpers.isAdmin(user) && (
                <Route path="/users">
                  <UserManagement />
                </Route>
              )}
              {UserHelpers.canViewDatasets(user) && (
                <Route path="/">
                  <Redirect to="/datasets" />
                </Route>
              )}
              {UserHelpers.canViewTmaps(user) && (
                <Route path="/">
                  <Redirect to="/tmaps" />
                </Route>
              )}
            </Switch>
          )}
          {!isLoggedIn && (
            <Switch>
              <Route path="/set-password">
                <ResetPassword initial />
              </Route>
              <Route path="/reset-password">
                <ResetPassword />
              </Route>
              <Route path="/forgot-password">
                <ForgotPassword />
              </Route>
              <Route path="/login">
                <Login />
              </Route>
              <Route path="/tmaps/share">
                <TMAP isPublic />
              </Route>
              <Route path="/signup">
                <Signup />
              </Route>
              <Route path="*">
                <Redirect to="/login" />
              </Route>
            </Switch>
          )}
        </Container>
      </Router>
    </>
  );
};

export default Main;
