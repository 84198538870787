import React from "react";
import classes from "./index.module.css";
import classnames from "classnames";

const StickyDescriptors = ({ data }) => {

  return (
    <div className={classnames(classes.Descriptors, classes.Sticky)}>
      {data && data.map((descriptor) => (
          <div key={descriptor.key} className={classes.LabelContainer}>
            <label className={classnames(classes.LabelTitle, "ml-1 mb-0")} title={descriptor.title}>
              {descriptor.title}
            </label>
            <label className="mb-0">:</label>
            <label className={classnames(classes.LabelInfo, "mb-0")}>{descriptor.value}</label>
          </div>
        ))}
    </div>
  );
};

export default StickyDescriptors;
