import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import classes from "./index.module.css";

const SortableHeader = ({ label, name, sorts, onChange }) => {
  let sort = sorts.find((s) => s[0] === name);

  return (
    <th onClick={onChange} data-name={name} className={classes.Header}>
      {label}
      <span className="ml-2">
        {!sort && <FontAwesomeIcon icon="sort" />}
        {sort && sort[1] === "ASC" && <FontAwesomeIcon icon="sort-alpha-down" />}
        {sort && sort[1] === "DESC" && <FontAwesomeIcon icon="sort-alpha-up" />}
      </span>
    </th>
  );
};

export default SortableHeader;
