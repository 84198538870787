import React from "react";
import classes from "./index.module.css";

const TextReader = ({ loadTextFromFile }) => {
  const fileChangeHandler = ({ target }) => {
    const file = target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => loadTextFromFile(e.target.result);
      reader.readAsText(file);
    }
  };

  return (
    <div className={classes.TextReader}>
      <label className={classes.Title}>Upload a file</label>
      <input
        className={classes.Input}
        name="upload"
        type="file"
        onChange={fileChangeHandler}
      />
    </div>
  );
};

export default TextReader;
