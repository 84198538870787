import * as Api from "../../utils/api";

const DATASETS = "datasets";

export const filter = async () => Api.get(DATASETS);

export const fetchDone = async () => Api.get(`${DATASETS}/done`);

export const get = async id => Api.get(`${DATASETS}/${id}`);

export const update = async dataset => Api.put(`${DATASETS}/${dataset.id}`, dataset);

export const remove = async id => Api.del(`${DATASETS}/${id}`);

export const create = async data => Api.formPost(DATASETS, data);

export const permissions = async id => Api.get(`${DATASETS}/${id}/permissions`);

export const togglePermission = async (id, user_id) => Api.post(`${DATASETS}/${id}/permissions`, {user_id});

export const stopProcessing = async id => Api.post(`${DATASETS}/${id}/processing/stop`);

export const startProcessing = async id => Api.post(`${DATASETS}/${id}/processing/start`);

export const fetchProcessingLog = async (id) => Api.get(`${DATASETS}/${id}/processing/log`);

export const fetchDescriptors = async (id) => Api.get(`${DATASETS}/${id}/descriptors`);

export const getDescriptorHistogram = async (dataset_id, descriptor_id) => Api.rawGet(`${DATASETS}/${dataset_id}/descriptor/${descriptor_id}/histogram`);
