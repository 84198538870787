import React, { useEffect, useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container } from "react-bootstrap";
import { useParams } from "react-router";
import { Request } from "../../store/ui";
import { Action as TMAPsAction } from "../../store/tmaps";
import TMAPForm from "./form";


const EditTMAP = () => {

  const id = parseInt(useParams().id);
  const loading = useSelector(state => state.ui.loading[Request.TMAP_FETCH]);
  const error = useSelector(state => state.ui.error[Request.TMAP_FETCH]);
  const data = useSelector(state => state.tmaps.find(tmap => tmap.id === id));
  const dispatch = useDispatch();

  const [tmap, setTMAP] = useState(data);
  
  useEffect(() => { dispatch(TMAPsAction.fetchTMAP(id)); }, [dispatch, id]);
  useEffect(() => { setTMAP(data); }, [data])

  const onChange = useCallback((name, value) => setTMAP({...tmap, [name]: value }), [tmap]);
  const onSubmit = useCallback(async () => dispatch(TMAPsAction.updateTMAP(tmap)), [tmap, dispatch]);

  if(error) {
    return error;
  }

  if(loading || !data) {
    return "Loading...";
  }

  return (
    <Container>
      <h1 className="mb-5">Edit TMAP '{tmap.name}'</h1>
      <TMAPForm onChange={onChange} onSubmit={onSubmit} tmap={tmap} />
    </Container>
  );
};

export default EditTMAP;
